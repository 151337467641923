<template> 
<!--Navbar -->
<!-- As a link -->
   <div class= "comm_navbar">

<!-- <div class="banner" style="height: 75px; ">  -->
<div class="banner">
   <table style="width: 100%;">
      <tr>
         <td class="radio_station_details">
            <div class="container" style="float: left;">
               <div style="display:inline-block">
                  <span class="qaller_logo">
                     <div style="padding-left: 3px;padding-right: 3px;">
                     <img style="width: 100px;" src="@/assets/images/QallerLarge.png">
                     </div>
                  </span>
               </div>
               <div  style="display:inline-block">
                  <div class="radio_station_logo"  v-bind:class="[generic_radio_style ? 'generic_radio_style':radio_staion]" >
                     <img style="width:68px;height:63px;" :src="radioSRC" >
                  </div>
                  <div class="radio_station_name" v-if="!isMobile">
                     <div class="username">{{username}}</div>
                     <h5>{{radio_staion}}</h5>
                  </div>
               </div>
            </div>
         </td>
         <td style="width: 190px;" v-if="!isMobile">
            <div style="display: inline-block;float: right;">
               <div class="stopwatch_timer">
                  <div id="time" style="font-family:DSEG7-Classic;font-size:1.5rem;line-height: 42px;" ref="stopwatch_display" >
                     00:00:00
                  </div>
                  Stopwatch
               </div>
               <div v-if="user_role=='Presenter'"  class="btns-container stopwatch_button" style="float:left">
                  <span>
                     <div v-if="!stopwatch_interval" title="Click to start stopwatch" @click="stopwatch_start_pause" style="margin-bottom: 6px;">
                        <img src="@/assets/images/start_stopwatch.svg">
                     </div>
                     <div v-if="stopwatch_interval" title="Click to pause stopwatch" @click="stopwatch_start_pause" style="margin-bottom: 6px;">
                        <img src="@/assets/images/pause_stopwatch.svg">
                     </div>
                  </span>
                  <span @click="stopwatch_reset" title="Click to reset stopwatch">
                     <img src="@/assets/images/restart_stopwatch.svg">
                  </span>
               </div>
            </div>
         <td>

         <td style="padding-top: 10px;" :class="[user_role=='Presenter'?  'mic_width' : 'mic_']">
            <div style="float: left;">
               <div style="height: 40px; width: 150px;" >
                  <!-- <div id="mic_input"  ref="mic_input" style="height: 40px; width: 150px; margin:auto" :class="[guest_mute.mic || guest_mute.speaker? 'hide_mic_input' :'']"></div> -->
                  <div id="mic_input"  ref="mic_input" style="height: 40px; width: 150px; margin:auto" :class="[guest_mute.mic? 'hide_mic_input' :'']"></div>
               </div>
               <div class="range log_action" v-if="user_role=='Presenter'" data-action="presenter_set_own_mic_gain">
                  <input type="range" title="Slide the bar to change your own gain"  min="-10" max="10" v-model="sliderGain"  id="myRange" style="width: 130px;" class="slider"> 
                  <div class="sliderticks">
                     <p>-10</p>
                     <p>-5</p>
                     <p>0</p>
                     <p>5</p>
                     <p>10</p>
                  </div>
               </div>
            </div>
            <div style="line-height: 86px;" v-if="user_role=='Presenter'">
               dB
            </div>
         </td>
   
         <td style="width: 170px;" v-if="user_role=='Presenter'">
            <canvas class="osciCanvas" ref="osci" width="170" height="60" style="border: 1px solid #FFF; margin: 0px 3px 0px 11px;"></canvas>
         </td>
         <td class="toggle_ringtone" v-if="user_role=='Presenter'">
            <div>
               <span v-if="play_ringtone" @click="dont_play_ring_tone" title="Click to mute ringtone">
                  <img src="@/assets/images/mute_ringtone.svg">
               </span>
               <span v-if="!play_ringtone" @click="dont_play_ring_tone" title="Click to unmute ringtone">
                  <img src="@/assets/images/unmute_ringtone.svg">
               </span>
            </div>
         </td>
         <td class="user_mic" @click="toggle_mic_producer" v-if="user_role=='Presenter'">
            <div class="presenter">
               <img src="@/assets/images/mute_mic.svg"   class="d-block mx-auto phone_icon" v-if="!presenter_mic_mute" title="Click to mute your own microphone">
               <img src="@/assets/images/unmute_mic.svg" class=" d-block mx-auto phone_icon" v-if="presenter_mic_mute" title="Click to unmute your own microphone">
            </div>
         </td>
         <!-- <td class="user_mic guest"  :class="[show_guest_mic? 'visible' : 'invisible' ]" v-if="show_guest_mic && user_role !='Presenter'"> -->
         <td class="user_mic guest" v-if="show_guest_mic && user_role !='Presenter'">
            <!-- Invitee Microphone -->
            <div @click="toggle_mic_guest" v-if="!guest_mute.speaker">
               <!-- <img src="@/assets/images/mute_mic.svg" :class="[!isMobile? 'visible' : 'invisible' ]"  class="d-block mx-auto phone_icon" v-if="!guest_mute.mic" title="Click to mute your own microphone"> -->
               <img src="@/assets/images/mute_mic.svg"   class="d-block mx-auto phone_icon" v-if="!guest_mute.mic" title="Click to mute your own microphone">
               <img src="@/assets/images/unmute_mic.svg" class=" d-block mx-auto phone_icon" v-if="guest_mute.mic" title="Click to unmute your own microphone">
            </div>
            <!-- Invtee speaker -->
            <div>
               <img src="@/assets/images/mute_both_mic_speaker.svg" class="img-fluid d-block mx-auto unmute_mic" v-if="guest_mute.speaker" title="Your microphone and speaker has been muted by the inviter">
               <!-- <img src="@/assets/images/mute_both_mic_speaker.svg" class="d-block mx-auto unmute_mic" v-if="guest_mute.speaker" title="Your microphone and speaker has been muted by the inviter"> -->
            </div>
         </td>
         <td class="logout_container log_action"  data-action="logout">
            <div v-on:click="signout" class="logout" title="Click to logout" v-if="!isMobile">
            </div>
            <div v-else class="logout" :class="{tap_message:double_tap_message_outgoing}"  @click="double_tap_message" title="Double tap to logout" v-on:dblclick="signout"  >
            </div>
         </td>
      </tr>
      <tr class="name">
         <td colspan=100 >
            <div  v-if="isMobile"> 
               <h4>{{username}}</h4>  
            </div>
         </td>
      </tr>
      <tr class="mobile_stopwatch" v-if="isMobile">
         <div class="stopwatch_timer" style="font-size:inherit; display: -webkit-inline-box;">
            <!-- <span> -->
               <span>Stopwatch :</span>
               <span id="time" style="font-family:DSEG7-Classic;" ref="stopwatch_display" >
                  00:00:00
               </span>
            <!-- </span> -->
         </div>
      </tr>
   </table>

   <vue-modal-2 
      name="modal-1" 
      @on-close="closeDialogue"
      :headerOptions="{
         title:'Confirm Logout'
      }"
      :footerOptions="{
         btn2: 'Logout and lose recording',
         btn1: 'Return to save recording',
         btn2Style: {
            backgroundColor: 'red',
            margin: '10px',
            color: 'white',
            width:'193px'
         },
         btn1Style: {
            backgroundColor: 'green',
            color: 'white',
            width:'193px'
         },
         bodyStyle: {
            width: '200px'
         },
         btn1OnClick: () => {
           $vm2.close('modal-1');
         },
         btn2OnClick: () => {
            $vm2.close('modal-1');
            logoutFromDialouge = true;
            signout();
         }
      }"
   >
      A recording is in progress. Click the 'logout' button to logout without downloading your recording. Click the 'return' button to return to your 
      recording so that you can download it.
   </vue-modal-2>
</div>

   </div>
</template> 

<script>
   import EventBus from '../eventBus';
   import Oscilloscope from 'oscilloscope';
   export default {
      name: 'navr',
      props: {
         username: String,
         canvas: Object,
         studiocaller: Object,
         isMobile: Boolean,
         mic: GainNode,
         user_role: String
      },
      data() {
         return {
            double_tap_message_outgoing: false,
            presenter_mic_mute : false, 
            guest_mute: {mic:false,speaker:false }, // Muting speakers also means that the mic is muted. However the state in this variable does not change.
            radio_staion : this.studiocaller.radio_station,
            radioSRC: '',
            generic_radio_style: false,
            sliderGain: 0,
            logoutFromDialouge: false,
            show_guest_mic: false,
            scope: null,
            session_event_log_listener: null,
            stopwatch_time:0,
            stopwatch_interval: null,
            stopwatch_state: null,
            play_ringtone: true
         }
      },
      beforeMount:  function() {
      },
      mounted: function() {
         var _this = this; 
         document.getElementsByClassName("log_action").forEach(function(btn,index,arr) {
            btn.addEventListener("click", function() {
               var action = btn.getAttribute("data-action");
               _this.session_event_log({
                     session: _this.studiocaller.session,
                     action: action,
                     pool: _this.studiocaller.pool, 
                     uuid: _this.studiocaller.uuid 
               }); 
            });
         });

         try {
            this.radioSRC = require("../assets/images/"+this.radio_staion+"-logo.png");
            this.generic_radio_style = false;
         } catch (e) {
            // Use default radio station image.
            this.radioSRC = require("../assets/images/temp_radio_station.png"); 
         }
         
         this.canvas.create_canvas(this.$refs["mic_input"]);
         
         var osci = this.$refs['osci'];
         this.scope = null;
         
         //  Only show Oscilloscope for presenters.
         if (this.user_role=='Presenter') {
            this.scope = new Oscilloscope(this.mic);
            osci.strokeStyle = "#FF0000";
            this.scope.animate(osci.getContext("2d"));
         }

         EventBus.$on('LOGOUT', (payload) => {
            this.signout()
         }); 

         EventBus.$on('TOGGLE_GUEST_MICROPHONE_STATE', (payload) => {
            this.guest_mute.speaker = payload.mute_state;
            this.guest_mute.mic = payload.mute_mic_state;
         });

         EventBus.$on('SHOW_HIDE_MUTE_ICON', (payload) => {
            // Used to show and hide invitee mute icon. 
            this.show_guest_mic = payload.show; 
         });

         EventBus.$on('STOPWATCH_NEW_PEER', (payload) => {
            var _this = this;
            if (this.stopwatch_state == null)
               return;

            if (!payload.id) {
               console.log('STOPWATCH_NEW_PEER: No id provided');
               return;
            }

            var action = this.stopwatch_state;
            if (payload.from == 'calldown')
               action = 'reset';
               
            this.transmit_stop_watch(action,payload.id)
         })
         
         EventBus.$on('STOPWATCH_UPDATE', (payload) => {
            // Used update a stopwatches
            //this.stopwatch_interval = payload.stopwatch_interval;
            if (payload.action == 'start') {
               this.stopwatch_time = payload.current_time;
               this.stopwatch_start();
            } else if (payload.action == 'pause') {
               this.stopwatch_time = payload.current_time;
               this.stopwatch_pause();
            } else if (payload.action == 'reset') {
               this.stopwatch_reset();
            }
            
         });         
      },
      methods:  {
         signout() {
            if (this.user_role == 'Presenter') {
               var recording_status = document.getElementById('recording_status');
               console.log('recording_status: ',recording_status);
               console.log('recording_status List: ',recording_status.classList);
               if ((recording_status.classList.contains('recording') || recording_status.classList.contains('pause')) &&!this.logoutFromDialouge) {
                  this.$vm2.open('modal-1');
               } else {
                  this.studiocaller.logout();
               }
               console.log('sign_out');
            } else {
               //FIXME: mic is still picked up on the logout page.
               this.$refs["mic_input"].remove()
               this.studiocaller.logout(); 
            }
         },
         double_tap_message : function () {
            this.double_tap_message_outgoing=true;
            var _this = this;
            setTimeout(function() { 
               _this.double_tap_message_outgoing = false; 
            }, 3000 );            
         },
         toggle_mic_producer : function () {
            if (this.presenter_mic_mute) {
               this.presenter_mic_mute = false;
               this.mic.gain.value = 1 ;
            } else {
               this.presenter_mic_mute = true;
               this.mic.gain.value = 0 ;
            }

            this.studiocaller.wss.action('app', '00000000-0000-0000-0000-000000000000', 
            {
               cmd:'mute_mic', 
               data: {
                  peer_uuid:this.studiocaller.uuid,
                  state: this.presenter_mic_mute
               }
            });
            
         },
         toggle_mic_guest : function () {
            // Message has to be sent to the presenter/producer to mute the invitee. 
            // This is to prevent the micVis from breaking on the  presenter/produce side, which is why the muting is not 
            // done on the invitee side just like the presenter/producer.
            // Should only be visiable when on a call.
            // inviter_id is only made available on callup.

            // Do nothing if mobile device is used. 
            // if(isMobile) {
            //    return;
            // }
            
            if(this.studiocaller.inviter_id) {
               this.studiocaller.wss.action('app', this.studiocaller.inviter_id, 
                  {
                     cmd:'toggle_guests_mic', 
                     data: {}
                  }
               );
            } else {
               console.error('Guest cant mute their own microphone. Invite id not available');
            }

         },
         closeDialogue: function () {
            this.$vm2.close('modal-1');
         },
         // --- Stopwatch Time -- 
         showTime: function() {
            this.stopwatch_time += 1;
            this.$refs['stopwatch_display'].innerHTML = this.toHHMMSS(this.stopwatch_time);
         },
         // stopwatch_start: function() {
         //    this.stopwatch_interval = setInterval(this.showTime, 1000);
         //    hideBtn([start_btn]);
         //    showBtn([pause_btn, reset_btn]);
         // },
         stopwatch_start_pause: function() {
            if (this.stopwatch_interval) {
               this.stopwatch_pause()
            } else {
               this.stopwatch_start()
            }
         },
         stopwatch_pause: function() {
            clearInterval(this.stopwatch_interval);
            this.stopwatch_interval = null;
            // pause_btn.innerHTML = 'Start';
            if (this.user_role=='Presenter') {
               this.transmit_stop_watch('pause');
               this.stopwatch_state = 'pause';
            } else {
               this.$refs['stopwatch_display'].innerHTML = this.toHHMMSS(this.stopwatch_time);
            }
            
         },
         stopwatch_start: function() {
            this.stopwatch_interval = setInterval(this.showTime, 1000);
            // pause_btn.innerHTML = 'Stop';
            if (this.user_role=='Presenter') {
               this.transmit_stop_watch('start');
               this.stopwatch_state = 'start';
            }
         },
         stopwatch_reset() {
            clearInterval(this.stopwatch_interval);
            this.stopwatch_interval = null;
            //pause_btn.innerHTML = 'Start';
            this.stopwatch_time = 0;
            this.$refs['stopwatch_display'].innerHTML = this.toHHMMSS(this.stopwatch_time);
            if (this.user_role=='Presenter') {
               this.transmit_stop_watch('reset');
            }
            //hideBtn([pause_btn, reset_btn]);
            //showBtn([start_btn]);
         },
         toHHMMSS: function(time) {
            //time - In seconds.
            let hours = Math.floor(time / 3600);
            let minutes = Math.floor((time - hours * 3600) / 60);
            let seconds = time - hours * 3600 - minutes * 60;

            hours = `${hours}`.padStart(2, '0');
            minutes = `${minutes}`.padStart(2, '0');
            seconds = `${seconds}`.padStart(2, '0');

            return hours + ':' + minutes + ':' + seconds;
         },
         transmit_stop_watch(action,id='00000000-0000-0000-0000-000000000000') {
            var _this = this;

            if(id == '00000000-0000-0000-0000-000000000000') {
               Object.keys(this.studiocaller.wss.peers).forEach((pid) => {
                  if(this.studiocaller.wss.peers[pid].active) { //Don't send to this current contact.  
                     this.studiocaller.wss.action('app', pid , 
                     {
                        cmd:'stopwatch_update', 
                        data: {
                           action: action,
                           // stopwatch_interval: _this.stopwatch_interval,
                           current_time:_this.stopwatch_time
                        }
                     });
                  }
               });
            } else {
               this.studiocaller.wss.action('app', id, 
               {
                  cmd:'stopwatch_update', 
                  data: {
                     action: action,
                     // stopwatch_interval: _this.stopwatch_interval,
                     current_time:_this.stopwatch_time
                  }
               });
            }     
         },
         dont_play_ring_tone: function() {
            this.play_ringtone = !this.play_ringtone;
            EventBus.$emit('DONT_PLAY_RINGTONE',{
               play_ringtone: this.play_ringtone
            }); 
         }
      },
      watch: {
         sliderGain: function(val) {
            var micGainValue   = Math.pow(10, (val/20));
            this.mic.gain.value = micGainValue; 
         }, 
      },
   }
</script>

<style>
   .osciCanvas {
      padding: 3px;
      display: block;
   }
   #mic_input div {
      width: 130px;
   }
   tr.name div {
      float: left;
      margin-left: 10px;
   }
   .radio_station_details {
      _width: 526px;
   }
   .radio_station_details .container {
      display: flex;
      flex-wrap: wrap;
      /* height: 400px; */
      align-content: space-between;
      padding-left:5px;
   }
/*.--old--*/
   .comm_navbar {
      margin-bottom: 25px;
      /* position: relative; */
   }
   .navmenu {
      float:right ;
   }
   .radio_name {
      height: 4px;
   }
   #mic_input {
      /* border: 1px solid black; */
      /* float: right; */
   }
   td.mic_ {
      width : 157px;
   }
   td.mic_  div {
      /* float:right; */
      /* margin-right:10px; */
   }
   .mic_input {
      /* width: 100px; */
      /* height:20px; */
      float: left;
      /* transform: rotate(-90deg)  ; */
      line-height: 0;
      margin-left: 10px;
      background-color: white;
      /* margin-right: 10px; */
   }
   .talk{
      color: #009600;
      font-size: 2rem;
   }
   .sign_out {
      cursor: default;
      display: flex;
      justify-content: center;
      align-items: center; 
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 10px;
      /* border: 1px solid #cad2d970; */
      padding: 5px;
   }
   .sign_out:hover {
      /* background-color: #cad2d92e; */
   }
   .mic_text {
      float: left;
      margin-top: 90%;
   }
   #nav-collapse {
      line-height: 0; 
   }
   .mic_image {
      position: relative;
      display: block;
      float: left;
      bottom: -60px;
      left: 5px;
   }
   /* .navbar-nav {
      display: -webkit-inline-box;
   } */
   #nav-collapse {
      line-height: 1.5;
   }
   .banner {
      overflow: hidden;
      background-color: #23334f;
      position: relative;
      color: aliceblue;
   
      /* border: solid 1px black; */
   }
   .banner div {
      /* float: left;
      color: #343a40;
      text-align: center;
      padding: 20px 20px;
      text-decoration: none;
      font-size: 17px; */
   }
   .banner div.active {
	   background-position:center;
	   background-repeat:no-repeat;
	   background-size:cover;
      padding: 5px;
      height:60px;
	   width:65px;
   }
   .radio_station_logo {
      float: left;
      display: inline-block;
      background: white;
      border-radius: 10px;
      padding: 1px;
      margin: 5px 5px 5px 10px;
   }
   .image_region {
      padding: 0px;
      height: 75px;
      width: 75px;
      /* border-right: 2px solid #21252938; */
      float: left;
      display: table-cell;
      vertical-align: middle;
      height: 77px;
   }
   .radio_station_name {
      float: right;
      /* width: 150px; */
      height: 75px;
      /* border-right: 2px solid #21252938; */
      /* display: flex; */
      /* justify-content: center; */
      align-content: center;
      flex-direction: column;
      /* padding-left: 2px; */
      text-align: left;
      font-size: 27px;
   }
   .user_name {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      margin: 10px;
      cursor: default;
   }
   .logout {
      padding: 13px;
      margin-left: 5px;
      background-image: url("../assets/images/logout.svg");
      background-position: 0 0;
      background-repeat: no-repeat;
      width: 80px;
      height: 80px;
      margin: 11px;
      background-size: 80px;
   }
   .generic_radio_style,.PretoriaFM {
      background: #23334f;
      border: 1px solid red; 
   }
   .Moneyweb {
      background: #23334f;
      border: 1px solid #417898;  
   }
   .RSG{
      background:none;
   }
   .GrootFM {
      background: #23334f;
   }
   .tap_message {
      background-position: 0 -80px;
   }
   .logout_container{
      width: 60px;
      cursor: pointer;
   }
   .logout_container img{
      width: 50px;
   }
   .noselect {
   -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
         -ms-user-select: none; /* Internet Explorer/Edge */
               user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
   }
   .user_mic {
      width: 60px;
      cursor:pointer;
      /* border-left: 2px solid #21252938; */
   }
   .user_mic.guest {
      width: 85px;
   }
   .user_mic img {
      width: 80px;
   }
   .logout_button {
      cursor:pointer;  
   }
   .mic_width {
      width:172px;
   }

   /* Slider */
   .slider {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      height: 1px;
      background: #D3D3D3;
      outline: none;
      opacity: 0.7;
      -webkit-transition: .2s;
      transition: opacity .2s;
   }

   .slider:hover {
      opacity: 1;
   }

   .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 5px;
      height: 20px;
      background: #FF0000;
      cursor: pointer;
   }

   .slider::-moz-range-thumb {
      width: 25px;
      height: 25px;
      background: #FF0000;
      cursor: pointer;
   }

   .sliderticks {
      display: flex;
      justify-content: space-between;
      padding: 0 14px;
   }

   .sliderticks p {
      position: relative;
      display: flex;
      justify-content: center;
      text-align: center;
      width: 1px;
      background: #D3D3D3;
      height: 6px;
      line-height: 40px;
      margin: 0 0 20px 0;
      font-size: 10px;
   }
   .db_scale, .slideContainer {
      float: left;
      margin-left: 4px;
   }
   .slideContainer {
      margin-left: 0px;
   }
   .hide_mic_input {
      visibility:hidden;
   }
   .stopwatch {
      display: inline-block;
   }
   .stopwatch_timer {
      float: left;
      font-size: 27px;
      /* background-color: #bac1cc2e; */
      border-radius: 5px;
      padding: 3px;
      /* border: 2px solid white;  */
   }
   .stopwatch_button {
      margin-left: 7px;
   }
   .stopwatch_button img {
      height: 40px;
   }
   .user_mic .presenter {
      margin-left: 11px;
   }
   .username {
      font-size: 27px;
   }
   .toggle_ringtone {
      width: 60px;
   }
   .toggle_ringtone img {
      width: 80px;
   }
   .toggle_ringtone div {
      margin-left: 11px;
   }
   .qaller_logo {
      min-height: 75px;
      display: inline-flex;
      align-items: center;
      /* border: 1px solid aqua */
   }
@media screen and (max-width: 635px) {
   .banner > table {
      transform: scale(0.85);
      transform-origin: 0 0;
   }
   .radio_station_name {
      display: none;
   }
   .radio_station_details {
      width: 64px;
   }
   .logout_container, .radio_station_details {
      width:64px;
   }
   .mic_input {
      margin: 0 auto;
      display: inline-block;
      float: inherit;
      border: 1px solid white;
   }
   .td.mic_ {
      width: auto;
   }
}

</style>
