<template> 
      <table style="width: 100%;">
         <td class="call_icon" :class="[callstate.state != 'ring_in' ? 'pb-0' : '']" >
               <div class="incoming_accept incoming_call" v-if="callstate.state =='ring_in'">Incoming call</div>
               <div class="outgoing_end outgoing_call" v-if="callstate.state =='hangup'">Outgoing call</div>
            <div v-if="(typeof peer.active_with_host != 'undefined' && peer.active_with_host !=true) ||  peer.name==studiocaller.wss.invitee_detail.sender" class="button_region">
               <!-- <div v-if="!isMobile"> -->
                  <div class=" telephone qaller_button" @click="check" :title="callstate.title ? callstate.title : ''" v-bind:class="[callstate.state, isMobile ? 'mobile' : 'non_mobile' , callstate.state == 'hangup' ? 'center_icon': '']" v-if=" callstate.state 
                  != 'ring_in' ">
                     <img :src="callstate.image" v-bind:class="[callstate.state == 'hangup' ? 'img-fluid d-block outgoing_end_shake phone_icon':'img-fluid d-block  phone_icon']">
                  </div>
               <!-- </div> -->
               <div  v-else-if=" callstate.state !== 'ring_in' "  >
                  <div class="px-0 telephone  qaller_button fitcontents" :title="callstate.title ? callstate.title : ''" v-on:dblclick="check"  @click="double_tap_message"  v-bind:class="callstate.state" v-if=" callstate.state == 'hangup' "  >
                     <p class="tap_message noselect" v-if="double_tap_message_outgoing">Double tap to end call</p>
                     <span><img :src="callstate.image" class="img-fluid d-block mx-auto phone_icon "></span>
                  </div>
                  <div class="px-0 telephone  qaller_button" :title="callstate.title ? callstate.title : ''" @click="check"  v-bind:class="[callstate.state, isMobile ? 'mobile' : 'non_mobile' ]" v-if=" callstate.state == 'call' ">
                     <span><img :src="callstate.image" class="img-fluid d-block mx-auto phone_icon "></span>
                  </div>
                  <div class="px-0 telephone fitcontents qaller_button" :title="callstate.title ? callstate.title : ''" v-on:dblclick="check"  @click="double_tap_message" v-bind:class="callstate.state" v-if=" callstate.state == 'active_call' "  >
                     <p class="tap_message noselect" v-if="double_tap_message_outgoing">Double tap to end call</p>
                     <span><img :src="callstate.image" class="img-fluid d-block mx-auto phone_icon "></span>
                  </div>
               </div>

               <div :class="[callstate.state == 'ring_in' ? 'center_call_icon' : '']" >
                  <div class=" qaller_button call" @click="accept_call" v-bind:class="callstate.state"  v-if=" callstate.state == 'ring_in' " title="Click to accept incoming call">
                     <img src="../assets/images/call.svg" class="img-fluid d-block mx-auto phone_icon incoming_accept_shake ">
                     <div></div>
                  </div>
                  <div class="hangup qaller_button" @click="decline_call" v-bind:class="callstate.state"  v-if="callstate.state == 'ring_in'" title="Click to decline incoming call">
                     <img src="../assets/images/hangup.svg" class="img-fluid d-block mx-auto phone_icon ">
                        {{emit_incoming()}}
                  </div>
               </div>

               <div class="button_region waive">
                  <div class="qaller_button d-inline-block" @click="raise_hand" v-bind:title="hand_tooltip" v-if="peer.active && !raise_hand_state">
                     <img src="../assets/images/raise_arm.svg" v-if="!ishandpaused" class="img-fluid d-block mx-auto phone_icon">
                     <!-- <img src="../assets/images/raise_arm.svg"  title="Your raise hase been paused. Click to unraise hand" v-if="ishandpaused" class="img-fluid d-block mx-auto phone_icon"> -->
                  </div>

                  <div class="qaller_button d-inline-block" @click="unraise_hand" v-bind:title="hand_tooltip" v-if="peer.active && raise_hand_state" v-bind:class="{'raise_hand': raise_hand_state}">
                     <img src="../assets/images/raise_arm.svg" v-if="!ishandpaused" class="img-fluid d-block mx-auto phone_icon" v-bind:class="{'raise_hand_shake': raise_hand_state}">
                     <!-- <img src="../assets/images/raise_arm.svg"  title="Your raise hase been paused. Click to unraise hand" v-if="ishandpaused" class="img-fluid d-block mx-auto phone_icon"> -->
                  </div>

               </div>
            </div>
         </td>  
         <td class="contact_name_region">
            <div class="contact_details">
               <span  class="peer_name lead">{{peer.name}}</span>

            </div>
         </td>
         <td>
            <div style="float: left;height: auto;"> 
               <span v-if="bmessage" >
                  <!-- <input v-if="show_bmessage" class="broadcast_message" readonly name="broadcast_message" style="width: 100%;" :value="bmessage"/> -->
                  <div class="broadcast_message" >
                     {{bmessage}}
                  </div>
               </span>
            </div>
         </td>
         <td class="status_region">
            <div class="unmute_mic">  
               <img src="@/assets/images/unmute_mic.svg"   class="img-fluid d-block mx-auto phone_icon " v-if="mute_state.mic">
            </div>
            <div class="unmute">
               <img src="@/assets/images/mute_both_mic_speaker.svg"   class="img-fluid d-block mx-auto  unmute_mic" v-if="mute_state.speaker">
            </div>
         </td>
      </table>
</template> 

<script>

   import mute_mic_speaker from "../assets/images/mute_mic_speaker.png";
   import EventBus from '../eventBus';
   import Oscilloscope from 'oscilloscope';

   export default {
      name: 'Contact',
      props: {
         peer: Object,
         studiocaller: Object,
         id: String,
         username: String,
         callstate: Object,
         isMobile: Boolean,
         is_muted_by: Object,
         waiting_tone: Object,
         raise_hand_by: Object,
      },
      data () {
         return {
            double_tap_message_outgoing: false,
            name : this.studiocaller.wss.peers[this.id].name,
            sender_detail : this.studiocaller.wss.invitee_detail,
            raise_hand_state: false,
            ishandpaused: false,
            hand_tooltip:'Click to raise hand',
            bmessage: '',
            show_bmessage: false,
            mute_state : {
               mic : false,
               speaker : false
            }
         }
      },
      mounted: function () {
         if (typeof is_muted_by == 'undefined' ) {
            this.muted = false;
         }
         if(this.name== this.sender_detail.sender && this.sender_detail.call_on_login == '1') {
            this.studiocaller.wss.action('call', this.id);
            this.is_muted_by.state = "false";
            this.studiocaller.wss.invitee_detail.call_on_login = 0 ;
         }

         EventBus.$on('UNRAISE_HAND', (payload) => {
            if(this.name== this.sender_detail.sender) {
               this.unraise_hand();
            }
         })

         EventBus.$on('PAUSE_RAISE_HAND', (payload) => {
            // this.pause_hand();
         })

         EventBus.$on('BROADCAST_MESSAGE', (payload) => {
            console.log('BROADCAST_MESSAGE');
            this.bmessage ='';
            if (payload.by == this.id) {
               this.broadcast_message(payload);
               
               var _this = this;
               var timeout; 
               var count =0 ; 
               timeout = setInterval(function() {
                  count++; 
                  if(_this.show_bmessage) {
                     _this.show_bmessage = false;
                  } else {
                     _this.show_bmessage = true;
                  }
                  if(count > 3) {
                     _this.show_bmessage = true;
                     clearTimeout(timeout); 
                  }
               }, 500);
            }
         });

         EventBus.$on('TOGGLE_OTHER_PEERS_MICROPHONE_STATE', (payload) => {
            if (payload.id == this.id) {
               this.mute_state.mic = payload.mute_mic_state; 
               this.mute_state.speaker = payload.mute_state;
            }
         })
      },
      methods : {
         Check1: function () {
            // this.studiocaller.wss.peers[this.id].missed_call=true;
            console.log(this.studiocaller.wss.peers[this.id]);
         }, 
         check: function () {
            if(this.peer.deleted) {
               //  alert('DELETE');
                delete this.studiocaller.wss.peers[this.id] ;
            } else {
               if(this.peer.callingus) {
               } else if(this.peer.calledbyus) {
                  this.studiocaller.wss.action('hangup', this.id);
                  this.$emit('call:hangup',"true");
               } else if(this.peer.active) {
                  // alert('Active');
                  this.studiocaller.wss.action('hangup', this.id);
                  this.studiocaller.wss.action('app', this.id , {cmd:'mute', data:{state:"false",mute_by: this.studiocaller.name}});
               } else if(!this.peer.active && !this.peer.calledbyus && !this.peer.callingus) {
                  this.studiocaller.wss.action('call', this.id);
                  this.is_muted_by.state = "false";
               }
            }
         },
         accept_call: function () {
            this.studiocaller.wss.action('accept', this.id);
            console.log('Accepted call');
         },
         decline_call: function () {
            this.studiocaller.wss.action('decline', this.id);
         },
         emit_incoming : function () {
            this.$emit('call:incoming',"true");
         },
         double_tap_message : function () {
            this.double_tap_message_outgoing=true;
            var _this = this;
            setTimeout(function() { 
               _this.double_tap_message_outgoing = false; 
            }, 3000);            
         },
         raise_hand : function () {
            this.hand_tooltip == 'Click to unraise hand'; 
            this.raise_hand_state = true;
            this.studiocaller.wss.action('app', this.id , {cmd:'raise_hand', data:{state:"true",by: this.studiocaller.name}}); 

         },
         unraise_hand: function() {
            this.hand_tooltip == 'Click to raise hand'; 
            this.raise_hand_state = false;
            this.studiocaller.wss.action('app', this.id , {cmd:'raise_hand', data:{state:"false",by: this.studiocaller.name}});
         },
         broadcast_message : function (message) {
            this.bmessage = message.message;
         }
      },
      watch: {
         bmessage: function (val) {


         },
      }
   }
</script>

<style scoped>
   .incoming_call,
   .outgoing_call {
      color: white;
      border-radius: .25rem;
      text-align: center;
      width: 100%;
      margin-bottom: 4px;
   }
   td.call_icon div.button_region div.center_icon {
      margin: auto;
      width: 54px;
      display: block;
      float: inherit;
   }
   .center_call_icon {
      width:124px;
      margin:auto;
   }
   .broadcast_message{
      /* float:right; */
      background: #2c5aa0ff;
      color: white;
      min-width: 300px;
      max-width: 600px;
      border-radius: 5px;
      border: 2px solid white;
      padding: 10px;
      /* border: 2px solid white */

   }
   .peer_name {
      display: inline-block;
      float:left;
      /* width: 30%;    */
   }
   
   /* .call_icon > .button_region {
      display: inline-block;
      _min-width: 81px;
      width:100%
   } */

   .call_icon .button_region.waive {
      _display: inline-block;
      min-width: 81px;
      _width:100%
   } 
   .qaller_button {
      margin-left: 5px;
   }
   .incoming_accept {
      /* margin: 8px 0px; */
      float:left;
      -webkit-animation: accpet_glow 1500ms infinite;
      -moz-animation: accpet_glow 1500ms infinite;
      -o-animation: accpet_glow 1500ms infinite;
      animation: accpet_glow 1500ms infinite;
/*       width:50%; */
   }
   .incoming_accept_shake {
       animation: incoming_accept_shake 0.5s infinite ;
   }
   .outgoing_end{
      float:left;
      margin: 8px;
      -webkit-animation: outgoing_end 1500ms infinite;
      -moz-animation: outgoing_end 1500ms infinite;
      -o-animation: outgoing_end 1500ms infinite;
      animation: outgoing_end 1500ms infinite;
   }
   .outgoing_end_shake {
       animation: outgoing_end_shake 0.5s infinite ;
   }
   .raise_hand {
      -webkit-animation: accpet_glow 1500ms infinite;
      -moz-animation: accpet_glow 1500ms infinite;
      -o-animation: accpet_glow 1500ms infinite;
      animation: accpet_glow 1500ms infinite;
      border-radius: 16px;
   }
   .raise_hand_shake {
      animation: raise_hand_shake 0.5s infinite ;
   }
   .circular_div {
      border-radius: 50%;
      width: 38px;
   }
   .active_mute{
    margin: auto ; 
   }
   .disable {
      background: lightgrey;  
    }
   .muted_by {
      background: #aa0a0a36;
   }
   .muted_by_us {
      /* background: #ff840038; */
   }
   .muted_both {
      background: #ff840038;
   }
   .disable_mute {
    pointer-events: none;
    opacity: 0.5;
    background: #CCC;
    padding: 1px 0px 1px 0px;
    margin: 0;
    width: 25%;
   }
   .incoming_accept,
   .outgoing_end,
   .hangup,
   .active_call
   {
      /* width: 25%; */
   }
   .call:hover {
      /* background-color:#28a74545; */
   }
   
   .call .mobile {
      width: 38%;
   }
   .call .non_mobile {
      width: 38%;
   }
   .ring_in{
      /* width:12.5%; */
      display: inline-block;
      vertical-align: middle;
   }
   .contact_details {
      padding-left: 9px; 
      text-align: left;
      display: inline-block;
   }
   
   .hangup:hover {
      /* background-color:#fad7d7; */
   }
   .active_call:hover {
      /* background-color:#fad7d7; */
   }
   td {
      vertical-align: middle;
      padding: 4px;
   }
   .contact_name_region{
      height: 50px;
      width: 30%;
   }
   .phone_icon {
      width: 38px ;
   }
   .unmute_mic{
      height:38px;
   }
   .contact_details {
      padding: 0px;
/*       width: 40px; */
      /* border-left: rgb(228, 230, 231);
      border-right: rgb(228, 230, 231);
      border-style: solid;
      border-width: 0px 2px 0px 2px; */
   }
   .blinking{
      animation:blinkingText 1.2s infinite;
   }
   .message {
      /* margin: auto; */
      width: 25%
   }
   
   .message_icon{
      width: 25px;
   }
   
   .message:hover {
      background-color: #ffbc002e; 
   }
   
   .row {
      padding: 0px;
      margin:0px;
   }
   
   img {
      /* padding: 3px; */
   }
   .fitcontents {
      display:contents;
   }
   .telephone {
      display: inline-block;
      vertical-align: middle;
      float: left;
   }
   .email_number {
      text-align: left;
      /* font-size: 11px; */
   }
   td.call_icon {
      width: 200px;
      text-align: left;
      padding: 5px;
   }
   td.status_region div {
      display: block;
      float: right;
      margin-right: 5px;
   }
   .call_icon {
      height: 15%;
   }
   @media (max-width: 767px) {
      .email_number {
         font-size: 11px;
      }
      .col-sm-6 {
/*       -ms-flex: 0 0 50%; */
      }
      .telephone {
/*          border-top:  1px solid #dfdfdf; */
         margin: auto ;
      }
      .contact_details {
         /* border-bottom: 1px solid #dfdfdf; */
      }
/*      .message {
         margin-bottom: 30px; 
         border-bottom: 2px solid #dedbdb; 
         border-bottom: 1px solid blue; 
      }*/
      .incoming_accept
      .outgoing_end
      {
         /* width: 11%; */
      }
      .outgoing_end
      {
         /* width: 12%; */
         /* margin: 0; */
      }
      .tap_message{
         margin:0px;
         padding:0px;
         font-size: 11px;
      }
      .noselect {
      -webkit-touch-callout: none; /* iOS Safari */
         -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                  user-select: none; /* Non-prefixed version, currently
                                       supported by Chrome, Edge, Opera and Firefox */
      }
      .broadcast_message {
         min-width: auto;
      }
   }
   @keyframes blinkingText{
      0%{     color: #000;    }
      49%{    color: #000; }
      60%{    color: transparent; }
      99%{    color:transparent;  }
      100%{   color: #000;    }
   }
   @-webkit-keyframes accpet_glow {
      0% { background-color: #85db5947; -webkit-box-shadow: 0 0 3px #85db5947;   }
      50% { background-color: #85db5970; -webkit-box-shadow: 0 0 40px #85db5970; }
      100% { background-color: #008000bf; -webkit-box-shadow: 0 0 3px #008000bf; }   
   }  

   @-moz-keyframes accpet_glow {
      0% { background-color: #85db5947; -webkit-box-shadow: 0 0 3px #85db5947;   }
      50% { background-color: #85db5970; -webkit-box-shadow: 0 0 40px #85db5970; }
      100% { background-color: #008000bf; -webkit-box-shadow: 0 0 3px #008000bf; }
   }

   @-o-keyframes accpet_glow {
      0% { background-color: #85db5947; -webkit-box-shadow: 0 0 3px #85db5947;   }
      50% { background-color: #85db5970; -webkit-box-shadow: 0 0 40px #85db5970; }
      100% { background-color: #008000bf; -webkit-box-shadow: 0 0 3px #008000bf; }
   }

   @keyframes accpet_glow{
      0% { background-color: #85db5947; -webkit-box-shadow: 0 0 3px #85db5947;   }
      50% { background-color: #85db5970; -webkit-box-shadow: 0 0 40px #85db5970; }
      100% { background-color: #008000bf; -webkit-box-shadow: 0 0 3px #008000bf; }
   }
   
   @-webkit-keyframes outgoing_end {
      0% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 3px #ca7c7cc7;   }
      50% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 40px #ca5f5fc7; }
      100% { background-color: #c83737c7; -webkit-box-shadow: 0 0 3px #c83737c7; }
   }

   @-moz-keyframes outgoing_end {
      0% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 3px #ca7c7cc7;   }
      50% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 40px #ca5f5fc7; }
      100% { background-color: #c83737c7; -webkit-box-shadow: 0 0 3px #c83737c7; }
   }

   @-o-keyframes outgoing_end {
      0% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 3px #ca7c7cc7;   }
      50% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 40px #ca5f5fc7; }
      100% { background-color: #c83737c7; -webkit-box-shadow: 0 0 3px #c83737c7; }
   }

   @keyframes outgoing_end{
      0% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 3px #ca7c7cc7;   }
      50% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 40px #ca5f5fc7; }
      100% { background-color: #c83737c7; -webkit-box-shadow: 0 0 3px #c83737c7; }
   }
   
   @-moz-keyframes incoming_accept_shake { 
      0% { -webkit-transform: rotate(0deg)}
      25% { -webkit-transform: rotate(25deg)}
   }
   @-webkit-keyframes incoming_accept_shake { 
      0% { -webkit-transform: rotate(0deg)}
      25% { -webkit-transform: rotate(25deg)}
   }
   @keyframes incoming_accept_shake { 
      0% { -webkit-transform: rotate(0deg)}
      25% { -webkit-transform: rotate(25deg)}
   }

   @-moz-keyframes outgoing_end_shake { 
         0% { -webkit-transform: rotate(0deg)}
         25% { -webkit-transform: rotate(-25deg)}
   }
   @-webkit-keyframes outgoing_end_shake { 
         0% { -webkit-transform: rotate(0deg)}
         25% { -webkit-transform: rotate(-25deg)}
   }
   @keyframes outgoing_end_shake { 
      0% { -webkit-transform: rotate(0deg)}
      25% { -webkit-transform: rotate(-25deg)}
   }

   @keyframes raise_hand_shake { 
      0% { -webkit-transform: rotate(0deg)}
      25% { -webkit-transform: rotate(25deg)}
   }
   
   @media (max-width: 767px) {
      td.call_icon {
         width: 137px;
         text-align: left;
         padding: 5px;
      }
   }

</style>
