import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('user-token') || '',
    status: '',
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
  },
  mutations: {},
  actions: {
    // [AUTH_REQUEST]: ({commit, dispatch}, user) => {
    //     return new Promise((resolve, reject) => { // The Promise used for router redirect in login
    //       commit(AUTH_REQUEST)
          
    //       axios({url: 'auth', data: user, method: 'POST' })
    //         .then(resp => {
    //           const token = resp.data.token
    //           localStorage.setItem('user-token', token) // store the token in localstorage
    //           commit(AUTH_SUCCESS, token)
    //           // you have your token, now log in your user :)
    //           dispatch(USER_REQUEST)
    //           resolve(resp)
    //         })
    //       .catch(err => {
    //         commit(AUTH_ERROR, err)
    //         localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
    //         reject(err)
    //       })
    //     })
    //   }
  },
  modules: {}
});



// import Vuex from 'vuex'
// import Vue from 'vue'
// 
// // import {App} from '../../public/js/main.js'
// var studiocaller =  new App();
// 
// Vue.use(Vuex)
// 
// export default new Vuex.Store({
//   state: {
//       totalTvCount: 10, // The TV inventory
//       sCaller: studiocaller, 
//   },
// 
// //   getters: {
// //     // Check if both Larry and Jenny are happy
// //     happyStaff: state => {
// //       return state.isLarryHappy && state.isJennyHappy
// //     }
// //   },
// 
//   mutations: {
//     updateQallerState(state,qr) {
//       state.studiocaller = qr
//     }
//   },
// 
//   actions: {
//     // Larry
//     updateQallerState(context,qr) {
// //       if(context.state.sCaller) {
//         context.commit('updateQallerState',qr)
// //       }
//     }
//   }
// });
