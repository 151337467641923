<template>
   <div class="vertical_center" > 
      <div class="alert alert-info logout_message">
         <div>
            <img class="exit_logo" style="width: 30%;" src="../assets/images/qaller.png" />
         </div>
         <p> Thank you for using Qaller <b>{{username}}</b> </p>
         <p> If you logged out accidently, please click the button below</p>
         <p> to log back in</p>
         <a class="btn btn-primary"    :href="url" >Log In</a>
         <!-- {{url}} -->
      </div>
   </div>
</template>

<script>
  
export default {
   name : 'logout',
   props : {
      username: String,
      p_url: String 
   },
   mounted:function() {
   },
   data: function () {
      return {
         url : this.p_url,
      }
   },
   methods: {
      
   }
}
</script>

<style>
   .exit_logo{ 
      margin: 10px auto 20px;
      display: block;
   }
   .exit_message {
      font-size: larger;
   }
   .logout_message {
      width: 50%;
      margin: 0 auto;
   }
   .vertical_center {
      min-height: 90vh;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
   }
</style>
