<template>
   <div class="contacts">

      <div class=" card-default" id="card_contacts">
         <div id="contacts" class="panel-collapse collapse show" aria-expanded="true" style="">
            <div class="active_calls_container">
               <div class="header">
                  <div class="active_calls" style="margin-bottom: 15px; height: 95px; text-align: center;padding-bottom: 1px;padding-top: 1px;">
                     <h4 class="section_header" style="padding-right: 5px;float: left;"> Active Calls</h4>
                     <div class="common_call_buttons" style="float:left">
                        <div @click="cancel_all_calls" title="Click to end all active" class="log_action red_hover cancel_all_calls" data-action="end_all_calls">
                           <img src="@/assets/images/end_call.svg"/>
                        </div>
                     </div>

                     <div>
                        <div class="recording_container">
                           <div class="recording" style="margin-bottom: 12px;line-height: 0px;">
                              <div ref="recordParContainer" class="recordPar" >
                                 <p ref="recordPar"></p>
                              </div>
                           </div>

                           <div class="recording_left_panel">
                              <div class="recording_name_fields">
                                 <input title="Enter name of recording" style="height: 32px;" class="recording_name log_action" data-action="enter_recording_name" type="text" id="recording_name" name="recording_name" v-model.trim="recording_name" placeholder="Enter recording file name">
                              </div>
                              <div class="download_multiple_files" style="width:244px;border-radius:5px;">
                                 <div>
                                    <span 
                                       title="Record all audio streams as a single channel in a SINGLE MP3 file."
                                       style="
                                          font-size: 1.5rem;
                                          margin-right: 3px;
                                       "
                                    >
                                          Single
                                    </span>
                                    <span>
                                       <input
                                          v-model="download_multiple_files_checkbox"
                                          v-on:click="checkbox_clicked"
                                          type="checkbox"
                                          id="switch"
                                          :disabled="disabled=recording_status=='recording'"
                                          class="log_action"
                                          data-action="record_multiple_or_single_files"
                                       /><label title="Toggle to record to a SINGLE or to MULTIPLE MP3 files." for="switch">Toggle</label>
                                    </span>
                                    <span title=" Record audio streams in MULTIPLE MP3 files.
         A separate MP3 file is recorded for each separate audio stream.
         A separate MP3 file is also recorded for the combined audio stream"
                                       style="
                                          font-size: 1.5rem;
                                          margin-left: 3px;
                                       "
                                    >Multi
                                    </span>
                                 </div>
                              </div>
                           </div>


                           <div class="recording_status_container" style="height:92px;width:166px">
                              <div>
                                 <div style="font-family:DSEG7-Classic;display:block;font-size:1.5rem;height:52px;line-height:52px;">
                                    {{recTimeformatted}}
                                 </div>
                                 <div style="font-size:1.5rem;">
                                    Recording time
                                 </div>
                              </div>
                           </div>

                           <div class="recording_right_panel">
                              <div class="recording_buttons">
                                 <!-- {{this.recording_status}} -->
                                 <span class="record_start log_action" title="Click to start new record" @click="startrec()" v-if="this.recording_status !='recording' &&  this.recording_status !='pause'" data-action="record_start">
                                    <img src="@/assets/images/startRecBut.svg">
                                 </span>
                                 <span :class="{recording_in_progress: this.recording_status=='recording'}" class="record_stop log_action" @click="stoprec()" title="Click to stop recording" v-if="this.recording_status=='recording' || this.recording_status=='pause'" data-action="record_stop">
                                    <img src="@/assets/images/stopRecBut.svg">
                                 </span>
                                 <span class="record_pause log_action" data-action="record_pause" @click="pauserec(0)" title="Click to pause recording" v-if="this.recording_status=='recording' || this.recording_status=='inactive' ">
                                    <img src="@/assets/images/pauseRecBut.svg">
                                 </span>
                                 <span class="record_resume log_action" :class="{recording_in_progress: this.recording_status =='pause'}" data-action="record_resume" @click="pauserec(0)" title="Click to resume recording" v-if="this.recording_status=='pause'">
                                    <img src="@/assets/images/playRecBut.svg">
                                 </span>
                                 <!-- <span :class="{download_recording: this.recording_status !='recording' && existing_file}" class="record_download log_action" @click="downloadrec(true)"  title="Click to download recording" data-action="record_download"> -->
                                 <span class="record_download log_action" @click="downloadrec(true)"  title="Click to download recording" data-action="record_download">
                                    <img src="@/assets/images/downloadRecBut.svg">
                                 </span>
                              </div>
                              <div style="line-height: 26px;">
                                 <span v-if="recording_status" :class="[!downloading ? recording_status:'downloading']" class="recording_status" id="recording_status">
                                       <div v-if="recording_status=='pause' && !downloading">Paused</div>
                                       <div v-if="recording_status=='recording' && !downloading">Recording</div>
                                       <div v-if="downloading">Downloading</div>
                                       <div v-if="recording_status=='inactive'"></div>
                                 </span>
                              </div>
                           </div>

                        </div>
                     </div>
                     <div class="common_call_buttons mute_speaker_mic">
                        <div>
                           <div class="mute_mic log_action" data-action="unmute_all_guests_mic" title="Click to mute microphones of all active calls" @click="mute_mic_toggle" v-if="!mute_mic && !mute_speaker">
                              <img src="@/assets/images/mute_mic.svg"   class="img-fluid d-block mx-auto phone_icon ">
                           </div>
                           <div class="unmute_mic log_action" data-action="mute_all_guests_mic" title="Click to unmute microphones of all active calls" @click="mute_mic_toggle" v-if="mute_mic && !mute_speaker">
                              <img src="@/assets/images/unmute_mic.svg"   class="img-fluid d-block mx-auto phone_icon ">
                           </div>
                           <div class="mute_mic" v-if="mute_speaker">
                           </div>
                        </div>
                        <div>
                           <div class="mute log_action" data-action="mute_all_guests_speaker" title="Click to mute microphones and speakers of all active calls">
                              <img src="@/assets/images/speakers.svg" @click="mute_speaker_toggle" v-if="!mute_speaker" class="img-fluid d-block mx-auto phone_icon ">
                           </div>
                           <div class="unmute log_action" data-action="unmute_all_guests_speaker" title="Click to unmute microphones and speakers of all active calls">
                              <img src="@/assets/images/mute_both_mic_speaker.svg" @click="mute_speaker_toggle" v-if="mute_speaker" class="img-fluid d-block mx-auto phone_icon ">
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               
               <div>
                  <div class="contact_list" v-bind:style= "[isMobile ? {'height': '90px'} : {'height':'auto'}]"  v-if="Object.keys(active()).length" >
                     <ul class="list-group pull-down active_list" id="active_contact_list">
                        <li  v-for="(name, id) in active()"  :key=id>
                           <Contact @call:incoming="emit_incoming($event)" ref="Contact" :peer="name" :id="id" :is_muted_by="muted(name.name)" :studiocaller="studiocaller" :callstate="callstate(name)" :ctx="ctx" :isMobile="isMobile" :sent_invites="sent_invites" :section='"active_calls"' :raise_hand_by="raise_hand_by"/>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>

            <!--Waiting Section-->

               <div class="waiting_container">
                  <div class="waiting">
                     <h4 class="section_header">Waiting</h4>
                  </div>
                  <div class="contact_list" v-if="show_waiting_contacts" >
                     <div class="disable_layer"  v-if="active_call && isMobile && Object.keys(active()).length "></div>
                     <ul class="list-group pull-down waiting_list " id="waiting_contact_list">
                        <li v-for="(name, id) in non_active()"  :key=id>
                           <Contact  :peer="name" :id="id"  :studiocaller="studiocaller" :isMobile="isMobile" :is_muted_by="{'id': '' ,'state' : 'false'}" :callstate="callstate(name)"  :ctx="ctx" :sent_invites="sent_invites"  :section='"waiting"' />
                        </li>
                     </ul>
                  </div>
               </div>

               <p class="update_state">{{this.peerUpdate}}</p>
               <!-- {{peerUpdate}} -->
<!-- {{this.names}} -->
{{sent_invites}}
         </div>
      </div>
      <!-- <div class="modal-test"> -->
         <vue-modal-2
            class="modal"
            name="stop_recording"
            @on-close="closeDialogue"
            :headerOptions="{
               title:'Are you sure?'
            }"
            :footerOptions="{
               btn1: 'Yes Stop Recording',
               btn2: 'No Continue Recording',
               btn2Style: {
                  backgroundColor: 'green',
                  display: 'none'
               },
               btn1Style: {
                  backgroundColor: 'red',
                  color: 'white',
                  display: 'none'
               },
               btn1OnClick: () => {
                  stoprec(false);
                  $vm2.close('stop_recording');
               },
               btn2OnClick: () => {
                  $vm2.close('stop_recording');
               },
            }"
         >
            <div class="m-3" style="text-align: left;">
               <div class="stop_recording_modal_instruction yes" @click="modal_yes_stop_recording">
                  YES <br>
                  Stop Recording
               </div>
               <div class="stop_recording_modal_instruction no" @click="modal_no_stop_recording">
                  No <br>
                  Continue {{recording_status == 'pause' ? 'Pausing' : 'Recording'}}
               </div>
            </div>
            <br>
            <div>
               <input type="checkbox" id="dont_show_message_again" @click="btn_dont_show_message_again"> <label for="dont_show_message_again">Dont show this message again</label>
            </div>
         </vue-modal-2>
      <!-- </div> -->
   </div>
</template>

<script>

   import call_icon from "@/assets/images/call.svg";
   import hangup_icon from "@/assets/images/hangup.svg";
   import missed_call_icon from "@/assets/images/missed_call.svg";
   import Contact from "./Contact.vue";
   import EventBus from '../../eventBus';
   import { Mp3MediaRecorder } from 'mp3-mediarecorder';
   import Mp3RecorderWorker from "workerize-loader!./../../workers/worker.js";
   //import { Timer } from 'timer-node';

   export default {
      name : 'contacts',
      components: {
         Contact,
      },
      props : {
         names: {},
         studiocaller: Object,
         search: String,
         peerUpdate: String,
         wss: Object,
         isMobile: Boolean,
         mic: GainNode,
         ctx: AudioContext,
         parentRefs: Object,
         date : Object,
         sent_invites: Object,
         recAudio: { type: Function },
         username: String,
         uuid: String
      },
      data: function () {
         return {
            test_name : this.peerUpdate,
            active_call : false,
            mute : Object,
            muted_by: {'id': '' ,'state' : 'false'},
            count: 0,
            show_waiting_contacts : true,
            show_active_contacts : true,
            raise_hand_by: {'id': '', 'state':'','by':''},
            mute_mic : false,
            mute_speaker: false,
            recDest : null ,
            recDest1: null,
            presenterRec : {'state': 'inactive'},
            recAllPeers: {'state': 'inactive'},
            rec : false,
            pause_rec: false,
            chunks : [],
            recording_name : '',
            recording_status :'inactive',
            recording_style: '', //Depricated
            // recording_status,
            existing_file:false,
            timeout: null,
            continue_recording: null,
            paused: false,
            prev_recording_status: '',
            downloading: false,
            worker: null,
            workerForAllPeers: null,
            logout_clicked: false,
            recordings: {},
            recAudioObj: [],
            recGainNode: this.ctx.createGain(),
            recTimer :  null,
            recTime: 0,
            isPaused: false,
            lastTimeRecorder: 0,
            recTimeformatted: '00:00:00',
            download_multiple_files_checkbox: false,
            recButtonClicked: false,
            play_ringtone: true,
         }
      },
      beforeMount() {
         this.wss.oncallup = this._oncallup.bind(this);
         this.wss.oncalldown = this._oncalldown.bind(this);
      },
       mounted:function() {
         var _this = this;
         EventBus.$on('RAISE_HAND', (payload) => {
            this._onappmsg(payload.id,payload.cmd);
         });

         EventBus.$on('STOP_DOWNLOAD_REC', (payload) => {

            this.stoprec();
            // NOTE: used to logout automatically after download is complete.
            // We dont have a way of knowing when a download is completete so this is
            // set to false for now.
            this.logout_clicked = false;
         });

         EventBus.$on('INVITEE_MUTE_MIC_SPEAKER_CLICKED', (payload) => {
            // Event for when presenter/producer clicks the mic icon.
            // This eventBus determines the correct state for the global mute icon.

            var count_active_peers=0;;
            var count_speaker_muted=0;;
            var count_mic_muted=0;

            Object.keys(this.wss.peers).forEach((pid) => {
               if(this.wss.peers[pid].active) {
                  count_active_peers++;
                  if(!this.wss.peers[pid].micGain.gain.value && this.wss.peers[pid].rtp.getgain_i()) {
                     count_mic_muted++;
                  } else if (!this.wss.peers[pid].micGain.gain.value && !this.wss.peers[pid].rtp.getgain_i()) {
                     count_speaker_muted++;
                  }
               }
            });

            if(count_mic_muted == count_active_peers) {
               this.mute_mic = true;
            } else {
               this.mute_mic = false;
            }

            if(count_speaker_muted==count_active_peers) {
               this.mute_speaker = true;
            } else {
               this.mute_speaker = false;
            }
         });

         if (!this.worker) {
            this.worker = Mp3RecorderWorker();
         }

         if (!this.workerForAllPeers) {
            this.workerForAllPeers = Mp3RecorderWorker();
         }

         var session_event_log_listener = function(btn) {
            var action = btn.getAttribute("data-action");
            _this.session_event_log({
               session: _this.studiocaller.session,
               action: action,
               pool: _this.studiocaller.pool,
               uuid: _this.studiocaller.uuid
            });
         };

         // document.getElementsByClassName("log_action").forEach(function(btn) {
         //    console.log('Contacts btn log_action',btn);
         //    if (btn.removeEventListener) {
         //       btn.removeEventListener("click",session_event_log_listener(btn));
         //    }
         //    btn.addEventListener("click",session_event_log_listener(btn));
         // });

         EventBus.$on('DONT_PLAY_RINGTONE', (payload) => {
            this.play_ringtone = payload.play_ringtone
         });
      },
      methods: {
         mute_mic_toggle: function () {
            if(this.mute_mic) {
               this.mute_mic = false;
            } else {
               this.mute_mic = true;
            }

            var payload = {
               mic_mute_state : this.mute_mic
            };
            EventBus.$emit('MUTE_MIC',payload);
         },
         mute_speaker_toggle: function () {
            if(this.mute_speaker) {
               this.mute_speaker = false;
            } else {
               this.mute_speaker = true;
               if(this.mute_mic) {
                  this.mute_mic_toggle();
               }
            }

            var payload = {
               speaker_mute_state : this.mute_speaker
            };

            console.log("this.mute_speaker: Contacts",payload);
            EventBus.$emit('MUTE_SPEAKER',payload);
         },
         callstate: function (p) {
            var state = '';
            var ring = false;
            var image = '' ;
            var title = '';
               if(p.callingus) {
                  state =  'ring_in';
                  image =  'ring_in';
                  ring  =  true;
                  //title =
               } else if(p.calledbyus) {
                  state =  "hangup" ;
                  ring = true;
                  this.active_call = true ;
                  image = hangup_icon;
                  title = 'Click to end call';
               } else if(p.active) {
                  state =  "active_call" ;
                  image =  hangup_icon ;
                  ring = false;
                  title = 'Click to end active call';
               } else if(!p.active && !p.calledbyus && !p.callingus) {
                  state  = "call" ;
                  if(p.missed_call) {
                     image = missed_call_icon;
                     title = 'Missed call. Click to call guest';
                  } else {
                     image = call_icon;
                     title = 'Click to call guest';
                  }
                  ring = false;
               }
            return {
               state: state,
               image: image,
               ring:  ring,
               title: title,
            }
         },
         non_active : function() {
            var obj  =  { ...this.names};
            Object.keys(obj).forEach(key=>{

               var callstate = this.callstate(obj[key]);
               if((callstate.state == 'call' && ( obj[key].name) && obj[key]['invited'] === true) && !obj[key].active_with_host) {
                  if(this.search) {
                     if(!obj[key].name.toLowerCase().includes(this.search.replace(/\s/g,'').toLowerCase() )) {
                        delete obj[key];
                     }
                  }
               } else {
                  delete obj[key]
               }
            });
            return obj
         },
         active : function() {
            var obj  =  { ...this.names} ;
            var countNumRing = 0;
            var countNumActive = 0;

            Object.keys(obj).forEach(key=> {

               var callstate = this.callstate(obj[key]);
               if(callstate.ring) {
                  countNumRing++;
               }

               if(obj[key].active) {
                   countNumActive++;
               }

               if(((callstate.state == 'hangup' || callstate.state == 'ring_in' || callstate.state == 'active_call') && obj[key].invited) || obj[key].active_with_host) {
                  if(this.search) {
                     if(!obj[key].name.toLowerCase().includes(this.search.replace(/\s/g,'').toLowerCase() )) {
                        delete obj[key];
                     }
                  }
               } else {
                  delete obj[key]
               }
            });


            if(countNumActive) {
               this.active_call = true ;
            }

            if(countNumRing && this.play_ringtone) {
              if(this.studiocaller.ring ) {
                  this.studiocaller.ring.start('ring3');
               }
            } else {
                 if(this.studiocaller.ring) {this.studiocaller.ring.stop();}
            }
            return obj
         },
         print_debug : function(text) {
            this.count+=1 ;
            // this.parentRefs.debug_state.innerHTML += text +"<br/>" ;
            if(this.count > 11 ) {
               // this.parentRefs.debug_state.removeChild(this.parentRefs.debug_state.childNodes[0]);
               // this.parentRefs.debug_state.removeChild(this.parentRefs.debug_state.childNodes[0]);
            }
         },
         emit_incoming : function (updateTitle) {
           if(updateTitle) {
               this.$emit('call:incoming', updateTitle);
           }
         },
         _oncallup(id) {
               this.active_call = true;

               // this.mute_speaker = false;
               // this.mute_mic = false;

               if(this.wss._stream) {
                  console.log('ignore oncallup when in LITE mode');
                  return;
               }

               //alert("oncallup: " + id);
               if(!(id in this.wss.peers)) {
                  alert('oncallup for id ('+id+') not in peers?');
                  return;
               }
               if(!this.wss.peers[id].rtp) {
                  alert('oncallup for id ('+id+') without rtp?');
                  return;
               }
               //NB: "our" refers to the person joining the call.
               // connect mic to us
               //
               // We connect our mic to peer rtp
               this.mic.connect(this.wss.peers[id].rtp);

               //Create new micGain
               this.wss.peers[id].micGain = this.ctx.createGain();

               // we connect our rtp to the new micGain.
               this.wss.peers[id].rtp.connect(this.wss.peers[id].micGain);

               // connect us to speakers
               // we connect Gain node to speakers.

               if(window.mydestination) {
                  console.error('connect call audio to loopback')
                    this.wss.peers[id].micGain.connect(window.mydestination);
               } else {
                    this.wss.peers[id].micGain.connect(this.ctx.destination)
               }

               Object.keys(this.wss.peers).forEach((pid) => {
                  // Mixing is setup
                  if(this.wss.peers[pid].active && this.wss.peers[pid].rtp && pid != id) {
                     // connect our output to all other inputs
                     this.wss.peers[id].micGain.connect(this.wss.peers[pid].rtp);
                     // connect all other outputs to our input
                     this.wss.peers[pid].micGain.connect(this.wss.peers[id].rtp);
                  }
               });

               // Connecting Invitee mic

               var payload = {
                  'id' : id,
                  'from': 'callup'
               };

               EventBus.$emit('SPKVIS',payload);

               // FIXME:
               this.wss.peers[id].rtp.setgain_i(1.0);
               this.wss.peers[id].rtp.setgain_o(1.0);

               console.log('RTP: ',this.wss.peers[id].rtp);
               if(this.mute_mic) {
                  this.mute_mic = false;
                  this.mute_mic_toggle();
               } else if(this.mute_speaker) {
                  this.mute_speaker=false;
                  this.mute_speaker_toggle();
               }

               // MP3 recording
               //console.log('Worker on callup: ',this.worker);
               var startRecDate = new Date();
               var startReTime = startRecDate.toLocaleTimeString();

               console.log('startReTime: '+startReTime);

               if (typeof this.wss.peers[id].peerRec != 'undefined') {
                  delete this.wss.peers[id].peerRec;
               }

               var worker = Mp3RecorderWorker();
               var peerRec = new this.recAudio (
                  this.ctx,
                  worker,
                  Mp3MediaRecorder,
                  this.wss.peers[id].name,
                  startReTime,
                  id
               );

               this.recording_init(peerRec);
               this.wss.peers[id].peerRec = peerRec; 
               
               if ((this.recording_status == 'recording' || this.recording_status == 'pause') && this.download_multiple_files_checkbox) {
                  this.wss.peers[id].peerRec.start(true, (this.recording_status == 'pause') ? true : false);
               };
               
               this.studiocaller.wss.action('app', id , {cmd:'recording_status', data:{status:this.recording_status}});
               
               this.wss.peers[id].micGain.connect(this.wss.peers[id].peerRec);
               // Connect Us to recGainNode
               this.wss.peers[id].micGain.connect(this.recGainNode);

               // send stopwatch details
               EventBus.$emit('STOPWATCH_NEW_PEER',payload);
         },
         _oncalldown(id) {
            //alert('On call down');
            this.active_call = false;

            if(this.wss._stream) {
               console.log('ignore oncalldown when in LITE mode');
               return;
            }
            //alert("oncalldown: " + id);
            if(!(id in this.wss.peers)) {
               alert('oncalldown for id ('+id+') not in peers?');
               return;
            }

            if(!this.wss.peers[id].rtp) {
               alert('oncalldown for id ('+id+') without rtp?');
               return;
            }

            // this.mic.disconnect(this.wss.peers[id].rtp);
            // disconnect all of our outputs

            this.wss.peers[id].micGain.disconnect();
            Object.keys(this.wss.peers).forEach((pid) => {
               if(this.wss.peers[pid].active && this.wss.peers[pid].rtp && pid != id) {
                  // disconnect all other outputs to our input
                  this.wss.peers[pid].micGain.disconnect(this.wss.peers[id].rtp);
                  // this.wss.peers[pid].rtp.disconnect(this.wss.peers[id].micGain);
               }
            });

            console.log('On call down: ',this.wss.peers[id]);

            if (this.wss.peers[id].peerRec) {
               this.wss.peers[id].peerRec.stop();
            } else {
               console.warn('No rec object associated with');
            }

            if (this.recording_status != 'inactive') {
               this.$refs['recordPar'].innerHTML ='';
            }
            //if (document.getElementById("audio_recording_"+ this.wss.peers[id].name.replace(/\s/g, ''))) {
            //   document.getElementById("audio_recording_"+ this.wss.peers[id].name.replace(/\s/g, '')).remove();
            //}

            delete this.wss.peers[id].micGain;
            // Update stopwatch
            EventBus.$emit('STOPWATCH_NEW_PEER', {
              'id' : id,
               'from': 'calldown'
            });
         },
         muted(name) {
            if (typeof this.muted_by == 'undefined') {
               return {'id': '' ,'state' : 'false'}
            } else if(name == this.muted_by.mute_by) {
               return this.muted_by
            } else {
               return {'id': '' ,'state' : 'false'}
            }
         },
         _onappmsg(id,cmd) {
            if(cmd) {
               if (cmd.cmd == 'mute') {
                  this.muted_by =  {
                     'id': id ,
                     'state' : cmd.data.state,
                     'mute_by' : cmd.data.mute_by
                  }
               };
               if(cmd.cmd == 'raise_hand') {
                  this.raise_hand_by =  {
                     'id': id ,
                     'state': cmd.data.state,
                     'by' : cmd.data.by
                  }

                  for(var idx in this.names) {
                     if(this.raise_hand_by.id == idx) {
                        this.names[idx].raise_hand = this.raise_hand_by.state;
                     }
                  }
               }
            }
         },
         toggle_waiting() {
            if (this.show_waiting_contacts) {
               this.show_waiting_contacts = false;
            } else {
               this.show_waiting_contacts = true;
            }
         },
         toggle_active(){
            if (this.show_active_contacts) {
               this.show_active_contacts = false;
            } else {
               this.show_active_contacts = true;
            }
         },

         cancel_all_calls() {
            Object.keys(this.wss.peers).forEach((pid) => {
               if(pid) {
                  if(this.wss.peers[pid].active ==true || this.wss.peers[pid].calledbyus == true){
                     this.studiocaller.wss.action('hangup', pid);
                  } else if(this.wss.peers[pid].callingus == true) {
                     this.studiocaller.wss.action('decline',pid);
                  }
               }
            });
         },
         recording_init(recObject,clearblob=true,pause=false) {
            recObject.onstart = (e) => {
               if (recObject.getUserName() == 'AllUsersOnCall') {
                  this.recording_status = 'recording';
               }
            };

            recObject.ondataavailable = (e) => {
               // blobs.push(e.data);
               // combinationOfBlobs.push(e.data);
            };

            recObject.onstop = () => {
               if (recObject.getUserName() == 'AllUsersOnCall') {
                  this.recording_status = 'inactive';
               }
            };

            recObject.ondownload = (mp3BlobUrl,user_name,start_recording_time,uuid) => {

               console.log('ondownload start_recording_time: '+start_recording_time);
               var a_id = "audio_recording_"+user_name.replace(/\s/g, '');
               // Remove existing reocordings
               var existingRecordingForPeer = document.getElementById(a_id);
               if(existingRecordingForPeer) {
                  existingRecordingForPeer.remove();
               }
               if(this.recButtonClicked) {
                  this.$refs['recordPar'].innerHTML ='';
                  this.recButtonClicked = false;
               }

               var a = document.createElement("a");
               this.$refs['recordPar'].appendChild(a);
               a.id=a_id;
               a.className = "audio_recording";
               //a.classList.add(recObject.getUserName());
               a.href = mp3BlobUrl;
               a.setAttribute('username',recObject.getUserName());
               a.setAttribute('starttime',start_recording_time);
               if(uuid) {
                  a.setAttribute('uuid',uuid);
               }
               this.existing_file = true;
               this.download();
            };

            recObject.onpause = (e) => {
                this.recording_status = 'pause';
               console.log('onpause', e);
               this.paused = true;
               this.pause_rec = true ;
            };

            recObject.onresume = (e) => {
                this.recording_status = 'recording';
               // // console.log('onresume', e);
                this.paused = false;
            };

            recObject.onerror = (e) => {
               // console.error('onerror', e);
            };

         },
         startrec(clearblob=true,pause=false) {

            clearInterval(this.recTimer);
            if (clearblob) {
               this.recTime=0;
            }

            this.recTimer = window.setInterval(function() {
               if(_this.recording_status !='pause') {
                  _this.recTime++;
                     _this.recTimeformatted = _this.convertSecToHHMMDD(_this.recTime);
               }
            }, 1000);

            var startRecDate = new Date();
            var startReTime = startRecDate.toLocaleTimeString();
            console.log('startReTime: '+startReTime);

            if (!this.worker && this.download_multiple_files_checkbox) {
               this.worker = Mp3RecorderWorker();
            }

            var _this = this;
            if (typeof this.presenterRec._user_name == 'undefined' && this.download_multiple_files_checkbox) {
               console.log('Connect presenters mic');
               // FIXME: change name
               this.presenterRec = new this.recAudio (
                  this.ctx,
                  this.worker,
                  Mp3MediaRecorder,
                  this.username,
                  startReTime
               );
               // this.recAudioObj.push();
               console.log('uuid: ',this.uuid);
               console.log('this.wss.peers[this.uuid]: ',this.wss.peers);
               this.mic.connect(this.presenterRec, 0, 0);
            }

            if (typeof this.recAllPeers._user_name == 'undefined') {
                  this.recAllPeers = new this.recAudio (
                     this.ctx,
                     this.workerForAllPeers,
                     Mp3MediaRecorder,
                     'AllUsersOnCall',
                     startReTime
                  );

                  this.mic.connect(this.recAllPeers, 0, 0);
                  this.recGainNode.connect(this.recAllPeers);
            }

            if (this.download_multiple_files_checkbox) {
               Object.keys(this.wss.peers).forEach(function(key) {
                  var peerRec = _this.wss.peers[key].peerRec;
                  if (peerRec && _this.wss.peers[key].active) {
                     peerRec.reset(clearblob);
                     peerRec.start(clearblob,pause);
                     _this.recording_init(peerRec);
                  } else if (peerRec && !_this.wss.peers[key].active) {
                     delete _this.wss.peers[key].peerRec;
                  }
               });
            }

            // For presenter
            if (this.download_multiple_files_checkbox) {
               if(this.presenterRec) {
                  this.presenterRec.reset(clearblob);
               }
               this.presenterRec.start(clearblob,pause);
               this.recording_init(this.presenterRec);
            }
            // Combine for everyone on call.
            if(this.recAllPeers) {
               this.recAllPeers.reset(clearblob);
            }
            this.recAllPeers.start(clearblob,pause);
            this.recording_init(this.recAllPeers);
         },
         stoprec(show_dialog=true) {

            if( localStorage.getItem('show_recording_stop_modal') != null) {
               console.log('show_recording_stop_modal: ',localStorage.getItem('show_recording_stop_modal'));
               show_dialog = false;
            }

            if ((this.recording_status == 'pause' || this.recording_status == 'recording') && show_dialog) {
               this.$vm2.open('stop_recording');
               return;
            }

            var _this = this;
            // Recording for peers
            this.recAllPeers.stop();
            if (this.download_multiple_files_checkbox) {
               Object.keys(this.wss.peers).forEach(function(key) {
                  var peerRec = _this.wss.peers[key].peerRec;
                  if (peerRec) {
                     peerRec.stop();
                  }
               });
               this.presenterRec.stop();
            }
            clearInterval(this.recTimer);
         },
         pauserec(e) {
            var _this = this;
            console.log('this.recAllPeers: ',this.recAllPeers);
            if(this.recAllPeers.getRecState() === "recording") {
               // Pause Presenter's recording
               this.recAllPeers.pause();
               if (this.download_multiple_files_checkbox) {
                  this.presenterRec.pause();
                  // Pause all peers recording
                  Object.keys(this.wss.peers).forEach(function(key) {
                     var peerRec = _this.wss.peers[key].peerRec;
                     console.log('pauserec');
                     if (peerRec) {
                        peerRec.pause();
                     }
                  });
               }
            } else if(this.recAllPeers.getRecState() === "paused") {
               // Pause Presenter's recording
               this.recAllPeers.resume();
               if (this.download_multiple_files_checkbox) {
                  this.presenterRec.resume();
                  // Pause all peers recording
                  Object.keys(this.wss.peers).forEach(function(key) {
                     var peerRec = _this.wss.peers[key].peerRec;
                     // console.log('resumerec');
                     if (peerRec) {
                        peerRec.resume();
                     }
                  });
               }
            }
         },
         downloadrec() {
            this.recButtonClicked = true;
            console.log('recording_statue: ', this.recording_status);
            //console.log('this.combinationOfBlobs: ',combinationOfBlobs);
            if (this.recording_status == 'recording') {
               console.log('Downlad recording while in recording state');
               // Download while recording is in progress
               // Stop recording
               this.stoprec(false);
               // Start recording again but do no clear the blob
               this.startrec(false);
            } else if (this.recording_status == 'inactive') {
               console.log('Downlad recording while in inactive state');
               // Download recording after it has been stopped
               this.download();
            } else if (this.recording_status == 'pause') {
               console.log('Downlad recording while in pause state');
               // Download recording while it has been paused
               this.stoprec(false);
               // Start recording again but do no clear the blob
               this.startrec(false,true);
            }
         },
         download() {

            // callback on download
            var prev_recording_status = this.recording_status;
            // this.recording_status = 'downloading';
            this.downloading = true;
            if (this.timeout) {
               clearTimeout(this.timeout);
            }

            var _this = this;
            //alert('Click');
            // Debouncer
            this.timeout = setTimeout(() => {
               //FIXME:
               var audio_recording = document.getElementsByClassName("audio_recording");
               const keys = Object.keys(audio_recording);
               console.log('keys: ',keys);
               var _this = this;

               for (var key of keys) {
                  var audio = audio_recording[key];

                  if(audio) {
                     var cdate = new Date();
                     var date = cdate.getFullYear()+''+(+cdate.getMonth()+1).toString().padStart(2, '0')+''+cdate.getDate().toString().padStart(2, '0');
                     var time =  cdate.getHours().toString().padStart(2, '0')+'_'+cdate.getMinutes().toString().padStart(2, '0')+'_'+cdate.getSeconds().toString().padStart(2, '0');
                     var prependRecordingNameWith= 'radio_qaller';

                     if(_this.recording_name) {
                        prependRecordingNameWith = _this.recording_name;
                     }

                     audio.download = prependRecordingNameWith+'_'+date+'_start_'+audio.getAttribute('starttime')+'_end_'+time+'_'+audio.getAttribute('username').replace(/\s/g, '');
                  } else {
                     console.error('Cant download: No recording');
                  }
                  audio.click();
               }
               _this.downloading = false;
               //console.log('Inside download rec: ',_this.wss.peers);

               // The code below caters for users that have logged out before the recording has been stopped.
               var audio_recording = document.getElementsByClassName('audio_recording');
               for(let recording of audio_recording) {
                  console.log('recording: ',recording);
                  var peer_audio_recording_name = recording.getAttribute('username');
                  var recording_uuid = recording.getAttribute('uuid');
                  var peers_uuid =  Object.keys(this.wss.peers);
                  if (
                     (
                        (peer_audio_recording_name != this.username &&
                        peer_audio_recording_name != 'AllUsersOnCall') &&
                        (recording_uuid && peers_uuid && !peers_uuid.includes(recording_uuid))
                     )
                     &&
                     (this.recording_status == 'recording')
                  ) {
                     recording.remove();
                  }

               }

               // When guest moves to the waiting region
               Object.keys(_this.wss.peers).forEach((pid) => {
                  if(!_this.wss.peers[pid].active) {
                     // audio_recording
                     if (document.getElementById("audio_recording_"+_this.wss.peers[pid].name.replace(/\s/g, ''))) {
                        document.getElementById("audio_recording_"+_this.wss.peers[pid].name.replace(/\s/g, '')).remove();
                     }

                     // Terminate service workers.
                     if (typeof _this.wss.peers[pid].peerRec != "undefined") {
                        _this.wss.peers[pid].peerRec.terminateWorker();
                        delete _this.wss.peers[pid].peerRec;
                     }
                  }
               });
            }, 200);

         },
         convertSecToHHMMDD(recTime) {
            var hours = Math.floor(recTime / 60 / 60);
            var minutes = Math.floor(recTime / 60) - (hours * 60);
            var seconds = recTime % 60;
            return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
         },
         checkbox_clicked() {

            if (this.download_multiple_files_checkbox) {
               this.download_multiple_files_checkbox = false;
            } else {
               this.download_multiple_files_checkbox = true
            }

            this.existing_file = false;
            this.$refs['recordPar'].innerHTML ='';
         },
         closeDialogue: function () {
            this.$vm2.close('stop_recording');
         },
         btn_dont_show_message_again: function (event) {
               if (event.target.checked) {
                  localStorage.setItem('show_recording_stop_modal', false)
               } else {
                  localStorage.removeItem("show_recording_stop_modal");
               }
         },
         modal_yes_stop_recording: function() {
            this.stoprec(false);
            this.$vm2.close('stop_recording');
         },
         modal_no_stop_recording: function () {
            console.log('modal_no_stop_recording');
            this.$vm2.close('stop_recording');
         },
      },
      computed: {
      },
      watch: {
         'presenterRec.state' : function (newVal,oldVal){
         //to work with changes in someOtherProp
            console.log('newVal: '+newVal+' oldVal: '+oldVal);
         },
         'recording_status' : function (newVal, oldVal){
            //to work with changes in someOtherProp
            console.log('recording_status in watcher: '+this.recording_status);

            Object.keys(this.wss.peers).forEach((pid) => {
               if (this.wss.peers[pid].active) {
                  this.studiocaller.wss.action('app', pid , {cmd:'recording_status', data:{status:this.recording_status}});
               }
            });
         },
      }
   }

</script>

<style scoped>
   /* #vm2_box_stop_recording,.vm2_body {
      background-color: #23334f;
      color: #f8f9fa;
      text-align: 'center';
   }
   .vm2_modal-content, .vm2_modal-dialog, .vm2_body {
      background-color: #23334f;
      color: #f8f9fa;
      text-align: 'center';
   } */
   .contact_list  li {
      padding: 0px;
      background-color: #a9eda9c7;
      margin: 3px;
      position: relative;
      display: block;
      border: 0;
      color: #3a3a3a;
      /* background-color: #a2c3a263; */
      border-radius: 4px;
   }

   .contact_list .waiting_list li {
      background-color: #475369;
      color: #b8d0e5;
   }
   .active_calls div {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
   }
   .cancel_all_calls {
      vertical-align: top;
   }
   .common_call_buttons > div {
      margin-left : 10px;
      cursor: pointer;
   }
   .recording_name {
      color: rgb(67, 128, 68);
      text-align: center;
      width: 242px;

   }
   .recording_buttons span {
      /* margin-left: 8px; */
      /* cursor:pointer; */
      display: inline-block;
      border-radius: 26px;
   }
   span#recording_status {
      margin-left: 0px;
      width: 174px;
      border-radius: 5px;
      padding-left: 0px;
   }
   .record_stop, .record_pause, .record_resume, .record_download {
      cursor:pointer;
   }
   
   .record_resume,
   .record_pause {
      margin: 0px 6px;
   }
   .recording_buttons img, .common_call_buttons img {
      height: 54px;
   }
   .mute_speaker_mic {
      float: right;
      margin-right: 10px;
   }
/*---old---*/
   .contacts {
/*      overflow-y: scroll;
      height: 80%;
      position: relative;
      bottom: 0;*/
   }
   .row {
      padding: 0px;
      margin:0px;
   }
   .update_state {
      display: None;
   }
   .active_list{
      margin-bottom: 20px;
      border: 4px solid #00960063;
      position: relative;
      bottom: 0;
      border:0;
   }
   .disable_layer {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #faebd78a;
      z-index: 10;
   }
   #card_contacts {
      position: relative;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      /* background-color: #fff; */
      background-clip: border-box;
      border-radius: .25rem;
   }
   .non_active_list {
      /* border: 4px solid #dedbdb; */
      /* overflow-y: scroll; */
      height: 80%;
      position: relative;
      bottom: 0;
   }
   .contact_list {
      position: relative;
   }

   .invite {
      margin-top: 10px;
      margin-bottom: 20px;
      border: 4px solid #dedbdb;
   }

   .recording_in_progress {
      -webkit-animation: recording_glow 1500ms infinite;
      -moz-animation: recording_glow 1500ms infinite;
      -o-animation: recording_glow 1500ms infinite;
      animation: recording_glow 1500ms infinite;
   }

   .recording_paused {
    background-color: white;
    -webkit-box-shadow: 0px 0px 20px white;
    box-shadow: 0px 0px 20px white;
   }

   .download_recording {
      -webkit-animation: download_recording_glow 1500ms infinite;
      -moz-animation: download_recording_glow 1500ms infinite;
      -o-animation: download_recording_glow 1500ms infinite;
      animation: download_recording_glow 1500ms infinite;
   }

   div.recording_name_fields {
      /* display: block;
      margin-bottom: 30px; */
      display: block;
      margin-bottom: 21px;
      margin-top: 12px;
   }

   div.recording_buttons {
      display: block;
      margin-bottom: 10px;
   }

   div.recording_status_container {
      /* margin-left: 26px; */
      font-size: 27px;
   }

   .active_calls_container, .waiting_container {
      padding: 10px;
      background-color: #23334f;
      margin: 40px;
      border-radius: 10px;
      color: #dfe4e8;
   } 
/*
  - -     - - - - - - - - - - - --
*/

   #contacts {
      margin-bottom: 10px;
   }
   .section_header {
      display: inline;
      line-height: 38px;
      margin-bottom: 0px;
   }
   .expand_button {
      float : right;
      background-image: url("../../assets/images/expand_section.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width:50px;
      height:20px;
      margin: 4px;
   }
   .collapse_button {
      float : right;
      /* background-image: url("../../assets/images/collapse_section.png"); */
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      margin-right: 17px;
   }
   .expand_button:hover {
      background-color: #ddd;
      color: #343a40;
   }
   .red_hover:hover {
      /* background-color:#e84441;; */
    }
   span.recording_status {
      /* border: 6px solid red; */
      border-radius: 16px;
      line-height: 30px;
      margin-bottom: 0px;
      width: 150px;
      text-align: center;
      height: 31px;
      display: inline-block;
   }
   span.recording_status div {
      height: 31px;
      display: inline-block;
   }
   .recording_left_panel {
      /* border-right: 2px solid #dfe4e8; */
      padding-right: 6px;
      margin-right: 4px;
   }

   .recording_right_panel {
      /* border-left: 2px solid #dfe4e8; */
      padding-left: 6px;
      margin-left: 4px;
   }
   /* Checkbox*/

.recording_container .download_multiple_files {
   /* vertical-align: baseline;   */
   border-radius: 30px;
   background-color: #dcdcdc;
   /* margin-left: 8px; */
   padding: 3px 10px;
       color: #23334f;
}
.download_multiple_files span {
   float:left;
   line-height: 24px;

}
.recording_container #switch {
	height: 0;
	width: 0;

   display: none;
}

.recording_container label {
	cursor: pointer;
	text-indent: -9999px;
	width: 62px;
	height: 23px;
	background: #aa0000;
	display: block;
	border-radius: 100px;
	position: relative;
   margin-bottom: 0px;
}

.recording_container label:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 16px;
	height: 14px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.recording_container input:checked + label {
	background: #008000;
}

.recording_container input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.recording_container label:active:after {
	width: 25px;
}

.tooltip-inner {
   white-space: pre-wrap;
}

   /* .disabled_grayscale {
      filter: grayscale(100%);
   } */
.stop_recording_modal_instruction {
   width: 49%;
   display: inline-block;
   text-align: center;
   color: white;
   cursor: pointer;
}
.stop_recording_modal_instruction.yes {
   background-color: rgb(255 0 0);
   margin-right: 5px;
}
.stop_recording_modal_instruction.no {
   background-color: rgb(0 128 0);
}
   .recording_status.recording {
      border: 1px solid white;
      background: #aa0000;
   }
   .recording_status.pause{
      border: 1px solid white;
      background: orangered;
   }
   .recording_status.downloading{
      border: 1px solid white;
      /* background: white; */
   }
   .recording_status.inactive{
      border: 1px solid white;
      /* background: white; */
   }
   @-webkit-keyframes recording_glow {
      0% { background-color: white; -webkit-box-shadow: 0 0 3px white;   }
      50% { background-color: white; -webkit-box-shadow: 0 0 5px 4px white; }
      100% { background-color: white; -webkit-box-shadow: 0 0 3px white; }
   }

   @-moz-keyframes recording_glow {
      0% { background-color: white; -webkit-box-shadow: 0 0 3px white;   }
      50% { background-color: white; -webkit-box-shadow: 0 0 5px 4px white; }
      100% { background-color: white; -webkit-box-shadow: 0 0 3px white; }
   }

   @-o-keyframes recording_glow {
      0% { background-color: white; -webkit-box-shadow: 0 0 3px white;   }
      50% { background-color: white; -webkit-box-shadow: 0 0 5px 4px white; }
      100% { background-color: white; -webkit-box-shadow: 0 0 3px white; }
   }

   @keyframes recording_glow{
      0% { background-color: white; -webkit-box-shadow: 0 0 3px white;   }
      50% { background-color: white; -webkit-box-shadow: 0 0 5px 4px white; }
      100% { background-color: white; -webkit-box-shadow: 0 0 3px white; }
   }

   @-webkit-keyframes download_recording_glow {
      0% { background-color: green; -webkit-box-shadow: 0 0 3px green;   }
      50% { background-color: green; -webkit-box-shadow: 0 0 5px 6px green; }
      100% { background-color: green; -webkit-box-shadow: 0 0 3px green; }
   }

   @-moz-keyframes download_recording_glow {
      0% { background-color: green; -webkit-box-shadow: 0 0 3px green;   }
      50% { background-color: green; -webkit-box-shadow: 0 0 5px 6px green; }
      100% { background-color: green; -webkit-box-shadow: 0 0 3px green; }
   }

   @-o-keyframes download_recording_glow {
      0% { background-color: green; -webkit-box-shadow: 0 0 3px green;   }
      50% { background-color: green; -webkit-box-shadow: 0 0 5px 6px green; }
      100% { background-color: green; -webkit-box-shadow: 0 0 3px green; }
   }

   @keyframes download_recording_glow{
      0% { background-color: green; -webkit-box-shadow: 0 0 3px green;   }
      50% { background-color: green; -webkit-box-shadow: 0 0 5px 6px green; }
      100% { background-color: green; -webkit-box-shadow: 0 0 3px green; }
   }

   @keyframes recording_paused_glow {
      50% { background-color: white; -webkit-box-shadow: 0 0 5px 4px white; }
   }

</style>
