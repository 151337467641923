<template>
   <div class="contacts"> 
      <div class=" card-default" id="card_contacts">
         <div id="contacts" class="panel-collapse collapse show" aria-expanded="true" style="">
            <div class="active_calls" style="margin-bottom: 10px;">
               <h4 class="section_header"> Active Calls</h4>
               <div style="width: 150px;line-height: 26px;margin-left: 8px;display: inline-block;">
                  <!-- Make smaller -->
                  <span v-if="recording_status && on_call" class="recording_status" :class="recording_status" id="recording_status">
                        <div title="The recording has been paused by the inviter" v-if="recording_status=='pause'">Paused</div>
                        <div title="This call is being recorded by the inviter" v-if="recording_status=='recording'">Recording</div>
                        <!-- <div v-if="downloading">Downloading</div> -->
                        <div v-if="recording_status=='inactive'"></div>
                  </span>
               </div>
            </div>
            <div>
               <div class="contact_list" v-if="Object.keys(active()).length" >
                  <ul class="list-group pull-down active_list" id="active_contact_list">
                     <li v-for="(name, id) in active()"  :key=id>
                        <Contact @call:incoming="emit_incoming($event)"   ref="Contact" :peer="name" :id="id" :is_muted_by="muted(name.name)" :studiocaller="studiocaller" :callstate="callstate(name)" :isMobile="isMobile" :waiting_tone="waiting_tone" />
                     </li>
                  </ul>
               </div>
            </div>
            <div class="waiting">
               <!-- <h4 class="section_header">Waiting</h4> -->
               <!-- <div  v-bind:class="{'expand_button': show_waiting_contacts, 'collapse_button': !show_waiting_contacts}" @click="toggle_waiting"> -->
               <!-- </div> -->
            </div>
            <div class="contact_list" v-if="show_waiting_contacts" >
               <!-- <div class="contact_list"> -->
                  <div class="disable_layer"  v-if="active_peer && isMobile && Object.keys(active()).length " ></div>
                  <ul class="list-group pull-down waiting_list" id="waiting_contact_list">
                     <li v-for="(name, id) in non_active()"  :key=id>
                        <Contact  :peer="name" :id="id"  :studiocaller="studiocaller" :isMobile="isMobile" :is_muted_by="mute_setDefault(name.name)" :callstate="callstate(name)" :waiting_tone="waiting_tone" />
                     </li>
                  </ul>
               <!-- </div> -->
            </div>
            <p class="update_state">{{this.peerUpdate}}</p>
         </div>
      </div>
   </div>
</template>

<script>

   import call_icon from "@/assets/images/call.svg";
   import missed_call_icon from "../assets/images/missed_call.svg";
   import hangup_icon from "../assets/images/hangup.svg";
   import Contact from "./Contact.vue";
   import {Ring} from '../../public/js/helper.js';
   import EventBus from '../eventBus';
   // import {isMobile} from 'mobile-device-detect';
   
   export default {
      name : 'contacts',
      components: {
         Contact,
      },
      props : {
         names: {},
         studiocaller: Object,
         search: String,
         peerUpdate: String,
         wss: Object,
         isMobile: Boolean,
         mic: GainNode,
         ctx: AudioContext,
         parentRefs: Object,
         date : Object,
         muted_by: Object, 
      },
      data: function () {
         return {
            test_name : this.peerUpdate,
            active_peer : false,
            on_call : false,
            mute : Object,
            // muted_by: {'id': '' ,'state' : 'false'},
            count: 0,
            waiting_tone : null,
            disable: {},
            show_active_contacts: true,
            show_waiting_contacts: true,
            recording_status: 'inactive',
            //isMobile: isMobile
         }
      },
      beforeMount() {
         this.wss.oncallup = this._oncallup.bind(this);
         this.wss.oncalldown = this._oncalldown.bind(this);
         // this.wss.onappmsg = this._onappmsg.bind(this);
      },
       mounted:function() {
         
         if(!this.waiting_tone) {
            this.waiting_tone = new Ring(this.ctx);
            this.waiting_tone.load('waiting_tone', '../sounds/waiting_tone.u16');
         }

         EventBus.$on('RECORDING_STATUS', (payload) => {
            //console.log('RECORDING_STATUS payload: ',payload);
            //if(payload && (payload.id == this.id)) {
            console.log('recording_status: '+this.recording_status);
            this.recording_status = payload.status; 
            //} 
         });

         EventBus.$on('TOGGLE_GUEST_MICROPHONE_STATE', (payload) => {
            this.guest_mute.speaker = payload.mute_state;
            this.guest_mute.mic = payload.mute_mic_state;
         });
         
       },
      methods: {
         callstate: function (p) {
            var state = '';
            var ring = false; 
            var image = '';
            var title ='';
               if(p.callingus) {
                  state =  'ring_in';
                  image =  'ring_in';
                  ring = true;
               } else if(p.calledbyus) {
                  state =  "hangup" ;
                  ring = true; 
                  this.active_peer = true ;
                  image =  hangup_icon;
                  title = 'Click to end call';
               } else if(p.active) {
                  state =  "active_call" ;
                  image =  hangup_icon ;
                  ring = false;
                  title = 'Click to end active call';
               } else if(!p.active && !p.calledbyus && !p.callingus ) {
                  state  = "call" ;
                  if(p.missed_call) {
                     image = missed_call_icon;
                     title = 'Missed call. Click to call';
                  } else {
                     image = call_icon;
                     title = 'Click to call';
                  }
                  ring = false;
               }
            return {
               state: state,
               image: image,
               ring: ring,
               title: title,
            }            
         },
         active : function() {
            var obj  =  { ...this.names} ;
            var countNumRing = 0;
            var countNumActive = 0;
            
            Object.keys(obj).forEach(key=> {
               var callstate = this.callstate(obj[key]);
               if(callstate.ring) {
                  countNumRing++;
               }
               
               if(obj[key].active) {
                   countNumActive++;
               }
               
               if((callstate.state == 'hangup' || callstate.state == 'ring_in' || callstate.state == 'active_call' || (obj[key].active_with_host && this.on_call))) {
                  if(this.search) {
                     if(!obj[key].name.toLowerCase().includes(this.search.replace(/\s/g,'').toLowerCase())) {
                        delete obj[key];
                     }
                  } 
               } else {
                  delete obj[key]
               }
            });

            if(countNumActive) {
               this.active_peer = true ;
               // this.on_call = true;  
            }  
             
            if(countNumRing) {
              if(this.studiocaller.ring ) {
                  this.studiocaller.ring.start('ring3');
               }
            } else {
               if(this.studiocaller.ring) {this.studiocaller.ring.stop();}
            }
            return obj
         },
         non_active : function() {
            var obj  =  { ...this.names} ;
            Object.keys(obj).forEach(key=>{
               var callstate = this.callstate(obj[key]);
               if(callstate.state == 'call' && (this.studiocaller.wss.invitee_detail.sender == obj[key].name)) {
                  if(this.search) {
                     if(!obj[key].name.toLowerCase().includes(this.search.replace(/\s/g,'').toLowerCase())) {
                        delete obj[key];
                     }
                  } 
               } else {
                  delete obj[key]
               }
            });
            return obj
         },

         print_debug : function(text) {
            this.count+=1 ;
            // this.parentRefs.debug_state.innerHTML += text +"<br/>" ;
            if(this.count > 11 ) {
               // this.parentRefs.debug_state.removeChild(this.parentRefs.debug_state.childNodes[0]);
               // this.parentRefs.debug_state.removeChild(this.parentRefs.debug_state.childNodes[0]);
            }
         },
         emit_incoming : function (updateTitle) {
           if(updateTitle) {
               this.$emit('call:incoming', updateTitle);
           }   
         },
         _oncallup(id) {
               this.active_peer = true; 
               this.on_call = true;
               console.log('_oncallup : ' + id) ;
               // Send message to Presenter telling them the device used by the invitee.     
               // var _isMobile = this.isMobile;
               // var _this  = this; 
               // alert('getIsMobi _this: '+ _this.isMobile);

               // Assuming that the guest will only be on a call with someone that has the producer/presenter interface.
               this.studiocaller.inviter_id = id;
               EventBus.$emit('SHOW_HIDE_MUTE_ICON',{show:true});

               this.studiocaller.wss.action('app', 
                  id, 
                  {
                     cmd:'isMobile', 
                     data:{
                        isMobile: this.isMobile,
                     }
                  }
               );
               if(this.wss._stream) {
                  console.log('ignore oncallup when in LITE mode');
                  return;
               }
               //alert("oncallup: " + id);
               if(!(id in this.wss.peers)) {
                  alert('oncallup for id ('+id+') not in peers?');
                  return;
               }
               if(!this.wss.peers[id].rtp) {
                  alert('oncallup for id ('+id+') without rtp?');
                  return;
               }
               // connect mic to us
               this.mic.connect(this.wss.peers[id].rtp);
               // connect us to speakers
               // this.wss.peers[id].rtp.connect(this.ctx.destination);
               this.wss.peers[id].rtp.directplay();
               Object.keys(this.wss.peers).forEach((pid) => {
                  if(this.wss.peers[pid].active && this.wss.peers[pid].rtp && pid != id) {
                     // connect our output to all other inputs
                     this.wss.peers[id].rtp.connect(this.wss.peers[pid].rtp);
                     // connect all other outputs to our input
                     this.wss.peers[pid].rtp.connect(this.wss.peers[id].rtp);
                  }
               });
               this.wss.peers[id].rtp.setgain_i(1.0);
               this.wss.peers[id].rtp.setgain_o(1.0); 
         },
         _oncalldown(id) {
            this.active_peer = false;
            this.on_call = false;
            EventBus.$emit('RESET_MUTE_STATUES');
            this.muted_by = {'id': '' ,'mute_state' : '', 'mute_mic_state':'', 'mute_by':''}; 

            // Assuming that the guest will only be on a call with someone that has the producer or host interface.
            this.studiocaller.inviter_id = null;
            EventBus.$emit('TOGGLE_GUEST_MICROPHONE_STATE', {
               mute_mic_state : false,         
            });
            EventBus.$emit('SHOW_HIDE_MUTE_ICON',{show:false});
            
            if(this.wss._stream) {
               console.log('ignore oncalldown when in LITE mode');
               return;
            }
            //alert("oncalldown: " + id);
            if(!(id in this.wss.peers)) {
               alert('oncalldown for id ('+id+') not in peers?');
               return;
            }
            
            if(!this.wss.peers[id].rtp) {
               alert('oncalldown for id ('+id+') without rtp?');
               return;
            }
            this.mic.disconnect(this.wss.peers[id].rtp);
            // disconnect all of our outputs
            this.wss.peers[id].rtp.disconnect();
            Object.keys(this.wss.peers).forEach((pid) => {
               if(this.wss.peers[pid].active && this.wss.peers[pid].rtp && pid != id) {
                  // disconnect all other outputs to our input
                  this.wss.send({cmd:'log', data: this.date+ ' user: '+pid+ ' -> Contacts: _oncalldown : before : disconnect'}); 
                  this.print_debug('user: '+pid+' -> Contacts: _oncalldown : before : disconnect');
                  this.wss.peers[pid].rtp.disconnect(this.wss.peers[id].rtp);
               }
            });  
            
            var payload = {
               'id' : id,
               'status' : 'inactive'
            };

            EventBus.$emit('RECORDING_STATUS',payload);
         },
         muted(name) {
            var setToIntial = false;
            if (typeof this.muted_by == 'undefined') {
               return {'id': '' ,'mute_state' : '', 'mute_mic_state':'', 'mute_by':''}
            } else if(name == this.muted_by.mute_by) {
               return this.muted_by
            } else {
               return {'id': '' ,'mute_state' : '', 'mute_mic_state':'', 'mute_by':''}
            }
        },
        mute_setDefault(name) {
            if (typeof this.muted_by == 'undefined') {
               return {'id': '' ,'mute_state' : '', 'mute_mic_state':'', 'mute_by':''}
            } else if(name == this.muted_by.mute_by) {
               this.muted_by  =  {'id': '' ,'mute_state' : '', 'mute_mic_state':'', 'mute_by':''}
               return this.muted_by
            } else {
               return {'id': '' ,'mute_state' : '', 'mute_mic_state':'', 'mute_by':''}
            }
        },
         toggle_waiting(){
            if (this.show_waiting_contacts) {
               this.show_waiting_contacts = false; 
            } else {
               this.show_waiting_contacts = true; 
            }
         },
         toggle_active(){
            if (this.show_active_contacts) {
               this.show_active_contacts = false; 
            } else {
               this.show_active_contacts = true; 
            }
         }
      },
      computed: {
      },
   }
   
</script>

<style scoped>
   .contact_list  li {
      padding: 0px;
      background-color: #a9eda9c7;
      margin: 3px;
      position: relative;
      display: block;
      border: 0;
      color: #3a3a3a;
      /* background-color: #a2c3a263; */
      border-radius: 4px;
   }
   .contact_list .waiting_list li {
      background-color: #475369;
      color: #b8d0e5;
   } 
/* --Old--*/
   .contacts {
/*      overflow-y: scroll;
      height: 80%;
      position: relative;
      bottom: 0;*/
   }
   .row {
      padding: 0px;
      margin:0px;
   }
   .list-group-item {
/*       height: 60px ; */
      padding:  0px;
/*       margin-bottom:6px; */
   }
   .update_state {
      display: None;
   }
   .active_list{
      margin-bottom: 20px;
      border: 4px solid #00960063;
      position: relative;
      bottom: 0;
      border: 0;
   }
   .disable_layer {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #faebd78a;
      z-index: 10;
   }
   #card_contacts {
      position: relative;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      /* background-color: #fff; */
      background-clip: border-box;
      border-radius: .25rem;
   }
   .non_active_list {
      border: 4px solid #dedbdb;
      overflow-y: scroll;
      height: 80%;
      position: relative;
      bottom: 0;
   }
   .contact_list {
      position: relative;
   }
   

   .invite {
      margin-top: 10px;
      margin-bottom: 20px;
      border: 4px solid #dedbdb;

   }
   span.recording_status {
      /* border: 6px solid red; */
      border-radius: 16px;
      line-height: 30px;
      margin-bottom: 0px;
      width: 150px;
      text-align: center;
      height: 31px;
      display: inline-block;
      _vertical-align: middle;
      _text-align: center;
      color: #dfe4e8;
      margin:0px;
   }
   .recording_status.recording {
      border: 1px solid white;
      background: #aa0000;
   }
   .recording_status.pause{
      border: 1px solid white;
      background: orangered;
   } 
   .recording_status.downloading{
      border: 1px solid white;
      /* background: white; */
   } 
/*
  - -     - - - - - - - - - - - -- 
*/

   #contacts {
      margin-bottom: 10px;
   }
   .section_header {
      display: inline;
   }
   .expand_button {
      float : right;
      background-image: url("../assets/images/expand_section.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width:50px;
      height:20px;
      margin: 4px;
   }
   .collapse_button {
      float : right;
      background-image: url("../assets/images/collapse_section.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      margin-right: 17px;
   }
   .expand_button:hover {
      background-color: #ddd;
      color: #343a40;
   }


</style>
