<template>
  <div id="app" :class="{mobile_device:isM}">
    <router-view />
  </div>
</template>

<script>
import {isMobile} from 'mobile-device-detect';
import {detect}  from 'detect-browser'

var browser = detect();

function iOSversion() {
  if ((/iPad|iPhone|Macintosh/).test(navigator.platform)) {
   // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
   var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
   return parseInt(v[1], 10);
  }
}

var ver = iOSversion();

if (ver < 14 && (/iPad|iPhone/).test(navigator.platform)) {
   alert('Oops! Qaller uses the WebRTC Real Time Communication protocol. The version of iOS on this device does not yet support WebRTC. Upgrade to the latest version of iOS and try again or use a different device.');
}

if (ver < 11 && (/Macintosh/).test(navigator.platform)) {
   alert('Oops! Qaller uses the WebRTC Real Time Communication protocol. The version of iOS on this device does not yet support WebRTC. Upgrade to the latest version of iOS and try again or use a different device.');
}

export default {
   data() {
      return {
         isM: isMobile,
      }
   }
}

</script>
<style>
input {
   background-color:cornsilk;
   border-radius: 5px;
   border: 0px solid #4a7296;
   color: #3a3a3a;
}

#app {
   font-family: "Avenir", Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-align: center;
   color: #2c3e50;
   min-height:100vh;
   background-color: #417898;
   _min-width: 1356px;
   overscroll-behavior-x: none;
}

#nav {
   padding: 30px;
}

#nav a {
   font-weight: bold;
   color: #2c3e50;
}

#nav a.router-link-exact-active {
   color: #42b983;
}

.active_calls, .waiting, .invitation, .recording  {
   text-align: left;
   padding-left: 10px;
   border: 1px solid #ffffff;
   background-color: transparent;
   border: solid 1px black;
   border: 0;
   margin-bottom: 1px;
   margin-top: 1px;
}

.toggle_button {
   float : right;
   background-image: url("assets/images/expand_section.png");
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;
   width:50px;
   height:20px;
   margin: 4px;
}
#vm2__cover_wrapper_modal-1 {
   z-index: 100 
}
div, td, span {
   -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
         -ms-user-select: none; /* Internet Explorer/Edge */
               user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

input {
   background-color: #bac1cc;
   border-radius: 5px;
   border: 2px solid white;
}

#app.mobile_device {
   min-width: auto;
}

</style>
