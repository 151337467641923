'use strict';

import {rtpSig, rtpAudio, recAudio, loAudio} from './helper.js';

class rtpSock extends rtpSig {
  constructor(wss, pid) {
    super();
    
    this.wss = wss;
    this.pid = pid;
  }
  
  message(msg) {
    console.log(this.pid + ": Receive message: ", msg);
    super.rtpRx(msg);
  };

  rtpSend(msg) {
    console.log(this.pid + ": Send message: ", msg);
    if(this.wss && this.wss.isopen) { this.wss.send({cmd:'rtp', to:this.pid, data:msg}); }
  }
  
  close() {
    super.close();
    this.wss = null; // break ref
  }
}

export class wsSock {
  constructor(poolid, wsid) {
    console.log("wsSock - constructor");

    // check URL for id?
    //alert('url: ' + window.location);
    //if(window.location.search && window.location.search.indexOf("?") == 0) {
    //  this.wsid = window.location.search.substring(1);
    //  //alert('urlid: ' + this.urlid);
    //} else {
    //  this.wsid = null;
    //}
    if(poolid) {
      console.log('set pool id: ' + poolid);
      this.poolid = poolid;
    } else {
      this.poolid = '';
    }
    if(wsid) {
      console.log('set ws id: ' + wsid);
      this.wsid = wsid;
    } else {
      this.wsid = localStorage.getItem('__wsid__' + this.poolid);
      if(this.wsid) {
        console.log('using old wsid: ' + this.wsid);
      } else {
        this.wsid = '';
      }
    }

    this.peers = {};
    this.isopen = false;
    this.logout = false;
    this.id = null;
    this.turnpw = null;
    this._ctx = null; // use setaudio to set this before anything else
    this._stream = null; // use setaudio to set this to enable LITE MODE
    this._ws = null; // actual websocket
    this._hb = null; // heartbeat timeout
    this._q = []; // transmit queue

    this.onopen = null; // onopen() - called when server socket connects
    this.onreject = null; // onreject(call, data) - called when server message is rejected
    this.onaccept = null; // onaccept(call, data) - called when server message is accepted
    this.onclose = null; // websocket closed - called after all audio/message objects are stopped and freed
    this.onpeer = null; // called whenever peer list is updated
    this.oncallup = null; // oncallup(id) - called when a call with peer id is fully established
    this.oncalldown = null; // oncalldown(id) - called when a call with peer id is about to be torn down
    this.onappmsg = null; // onappmsg(id, data) receive app messages from peer
    this.onstate = null; // onstate(msg) receive rtp state change messages
    this.onalert = null; // redirect alerts to parent app
    this.onrefresh = null; // receive original name if rebinding comms (eventually send all state?)
    this.onaction = null; 
  }
  
  alert(t) {
    if(this.onalert) {
      this.onalert('wsSock: ' + t);
    } else {
      alert('wsSock: ' + t);
    }
  }
  
  setaudio(c, s = undefined) {
    if(this._ctx) {
      this.alert("Can not call setaudio() twice...");
      return;
    }
    this._ctx = c;
    this._stream = s;
  }
  
  hb() {
    console.log('hb: ', this._hb);
    if(this._hb) {
      clearTimeout(this._hb);
      this._hb = null;
    }
    this._hb = setTimeout(() => {
      this.alert('Comms heartbeat fail...');
      this.close();
    }, 40000);

    // this._hb = setTimeout(() => {
    //   this.alert('Comms heartbeat fail...');
    //   this.close();
    // }, 2);
  }
  
  open() {
    if(this._ws) {
      this.alert("Can not open WS - WS already open!");
      return;
    }
    var uri = '';
    if(this.poolid != '') {
      uri = this.poolid + '&' + this.wsid;
    } else {
      uri = this.wsid;
    }
    this.logout = false;
    //this._ws = new WebSocket("wss://radio.qaller.co.za:443/ws8084/"+uri);
    
    if(process.env.NODE_ENV=="development") {
      this._ws = new WebSocket("wss://qaller.expertron.co.za:443/ws8080/"+uri);
    } else {
      this._ws = new WebSocket("wss://radio.qaller.co.za:443/ws8084/"+uri);
    }
    
    console.log("this._ws: ",this._ws); 
   
    this._ws.onopen = (e) => this._onopen(e);
    this._ws.onclose = (e) => this._onclose(e);
    this._ws.onerror = (e) => this._onerror(e);
    this._ws.onmessage = (e) => this._onmessage(e);
    this.hb();
  }
  
  close() {
    if(this._ws) {
      if(this._hb) {
        clearTimeout(this._hb);
        this._hb = null;
      }
      this._ws.onopen = null;
      this._ws.onclose = null;
      this._ws.onerror = null;
      this._ws.onmessage = null;
      this._ws.close();
      this._ws = null;
    }
    this.isopen = false;
    // clear peers - they will all receive a delpeer message from the message switch anyway - let them deal with their own clean up...
    // FIXME - kill all sig and audio objects
    
    if(this.logout) {
      console.log('explicit logout - do not attempt reconnection');
    } else {
      console.log('other close - scheduling reconnection attempt');
      setTimeout(() => {
        console.log('Attempt reconnection...');
        this.open();
      }, 1000);
    }
    //this.peers = {};
    //// reset id
    //this.id = null;
    if(this.onclose) { this.onclose(); }
  }
  
  send(msg) {
    console.log("Send message: ", msg);
    if(this._ws && this.isopen) {
      this._ws.send(JSON.stringify(msg));
    } else {
      this._q.push(JSON.stringify(msg));
    }
  }
  
  setid(id) {
    if(this.id) {
      this.alert("Can not set ID - ID already set!");
      return;
    }
    //if(!this._ws) {
    //  this.alert("Can not set ID - WS not open!");
    //  return;
    //}
    this.send({cmd:'setid', data:id})
    //this.id = id;
  }


  // all UI call actions in one call...
  action(act, id, data = undefined) {
    // this.onstate("Action (" + act  +") ID (" + id + ")");
    console.log(act + ': ', id);
    // Used for logging actions in the db.
    if(this.onaction) {this.onaction(act, id, data);}
    if(!id in this.peers) {
      this.alert("Action (" + act  +") ID (" + id + ") does not exist!");
      return;
    }
    if(act == 'app') {
      this.send({cmd:act, to:id, data:data});
      return;
    } else if(act == 'call') {
      if(this.peers[id].callingus || this.peers[id].calledbyus || this.peers[id].active || this.peers[id].deleted || this.id == null) {
        console.log('call inalid state: ', this.peers[id]);
        this.alert("Call ID (" + id + ") is in an invalid state to place a call!");
        return;
      }
      this.peers[id].calledbyus = true;
      this.peers[id].missed_call = false;
    } else if(act == 'hangup') {
      if(this.peers[id].calledbyus || this.peers[id].active) {
        this.peers[id].calledbyus = false;
        if(this.peers[id].active) {
          // tear down active call
          this._delcall(id);
        }
      } else {
        this.alert("Hangup ID (" + id + ") is in an invalid state to hangup a call!");
        return;
      }
    } else if(act == 'accept') {
      if(this.peers[id].callingus) {
        this.peers[id].callingus = false;
        this._addcall(id, 0);
      } else {
        this.alert("Accept ID (" + id + ") is in an invalid state to accept a call!");
        return;
      }
    } else if(act == 'decline') {
      if(this.peers[id].callingus) {
        this.peers[id].callingus = false;
      } else {
        this.alert("Decline ID (" + id + ") is in an invalid state to decline a call!");
        return;
      }
    } else {
      this.alert("Action (" + act  +") ID (" + id + ") invalid!");
      return;
    }
    this.peers[id].updated = true;
    this.send({cmd:act, to:id});
    if(this.onpeer) { this.onpeer(); }
  }  
  
  _onopen(event) {
    this.isopen = true;
    console.log("Connection established: ", event);
    this.hb();
    // stream off queued messagse
    console.log('q: ' + this._q.length);
    while(this._q.length > 0) {
      console.log('send queued message...');
      this._ws.send(this._q.shift());
    }
    if(this.onopen) { this.onopen(); }
  };

  _onclose(event) {
    if(event.wasClean) {
      console.log("WS closed cleanly: ", event);
    } else {
      console.log("WS died");
    }
    this._ws = null;
    this.isopen = false;
    this.close();
  };

  _onerror(error) {
    console.log("WS error: ", error);
    this.alert("WS error: ", error);
    // should be followed by a close - no need to handle further?
  }
  
  // handle all rejections...
  _rejectmsg(o) {
    if(o.cmd == 'reject') {
      var reason = o.reason;
      var msg = o.data;
      console.log("message rejected because " + reason + ": ", msg);
      if(msg.cmd == 'setid') {
        //this.id = null;
        if(this.onreject) { this.onreject('setid', msg.data); }
      } else {
        console.log("unhandled reject " + reason + ": ", msg);
      }
      return true;
    }
    return false;
  }
  
  // handle all accepts...
  _acceptmsg(o) {
    if(o.cmd == 'ack') {
      var msg = o.data;
      console.log("message accepted: ", msg);
      if(msg.cmd == 'setid') {
        this.id = msg.data;
        if(this.onaccept) { this.onaccept('setid', msg.data); }
      } 
      else {
        console.log("unhandled accept: ", msg);
        if(this.onaccept) {this.onaccept(msg.cmd, msg.data);}
      }
      return true;
    }
    return false;
  }
  
  // handle all peer messages
  _peermsg(o) {
    if(o.cmd == 'newpeers') {
      console.log('new peers: ', o.data)
      for (var i = 0; i < o.data.length; i++) {
        var p = o.data[i];
        console.log("add peer: ", p);
        if(p.id in this.peers) {
           //console.log('re-add peer: ', p);
           //this.alert("Peer " + p.id + " state lost - call may be affected!");
           //delete this.peers[p.id];
           
           // FIXME - until we move call state server side, our side is more reliable...
           console.log('received peer update from server. assume ours is better and discard server update', p);
           continue;
        }
        this.peers[p.id] = {
           name: p.name,
           updated: true,
           callingus: false,
           calledbyus: false,
           active: false,
           deleted: false,
           sig: null,
           rtp: null,
           missed_call : false,
           invited: false,
        };
      }
      console.log('peers: ', this.peers);
      if(this.onpeer) { this.onpeer(o);}
      return true;
    }
    if(o.cmd == 'delpeer') {
      console.log('del peer: ', o.data)
      // we don't actually delete it - mark it as deleted and trigger onpeers so
      // app can know it is deleted and clean up...
      var pid = o.data;
      if(pid in this.peers) {
       // peer is gone - all other state will be lost
       // just clean up active call...
       if(this.peers[pid].active) {
          // active call with this peer - hang up
          // destroy rtp and audio objects...
          this.send({cmd:'hangup', to:pid});
          this._delcall(pid);
        }
        this.peers[pid].callingus = false;
        this.peers[pid].calledbyus = false;
        this.peers[pid].deleted = true;
        this.peers[pid].updated = true;
        console.log('peers: ', this.peers);
        if(this.onpeer) { this.onpeer(o); }
      } else {
        //this.alert("Peer " + pid + " deleted, but we have no record of it...");
        console.log("Peer " + pid + " deleted, but we have no record of it...");
      }
      return true;
    }
    return false;
  }
  
  _actionmsg(o) {
    var cmd = o.cmd;
    if(cmd != 'app' && cmd != 'call' && cmd != 'hangup' && cmd != 'accept' && cmd != 'decline') {
      return false;
    }
    var id = o.from;
    console.log('call action ' + cmd + ' for ' + id);
    if(!this.peers[id]) {
      this.alert("Received call action " + cmd + " from a peer we do not know of?");
      return true;
    }
    if(cmd == 'app') {
      if(this.onappmsg) { 
        console.log('if statement-> actionmsg');
        this.onappmsg(id, o.data); 
      }
      return true;
    }
    if(cmd == 'call') {
      if(this.peers[id].callingus || this.peers[id].calledbyus || this.peers[id].active) {
        // already have some sort of ongoing call?
        console.log('call request in invalid state: ', this.peers[id]);
        this.alert('Received a call request from '+id+' but we believe some sort of call is already in progress?');
        this.send({cmd:'decline', to:id})
        return true;
      }
      this.peers[id].missed_call = true;
      this.peers[id].callingus = true;
      console.log('peers: ', this.peers[id]);
    } else if(cmd == 'hangup') {
      if(!this.peers[id].callingus && !this.peers[id].active) {
        // no call initiated by this peer?
        console.log('hangup request in invalid state: ', this.peers[id]);
        // no log - synchronised hangup can cause us to receive this message after we have already cleaned up...
        //alert('Received a hangup request from '+id+' but we do not believe a call is in progress?');
        // no return, as peer already believe we are transitioning to an inactive state...
        return true;
      }
      if(this.peers[id].active) {
        // tear down call!
        this._delcall(id);
      }
      this.peers[id].callingus = false;
    } else if(cmd == 'decline') {
      if(!this.peers[id].calledbyus) {
        // no call initiated to this peer?
        console.log('decline request in invalid state: ', this.peers[id]);
        this.alert('Received a decline request from '+id+' but we do not believe a call is in progress?');
        // no return, as peer already believe we are transitioning to an inactive state...
        return true;
      }
      this.peers[id].calledbyus = false;
    } else if(cmd == 'accept') {
      if(!this.peers[id].calledbyus) {
        // no call initiated to this peer?
        console.log('accept request in invalid state: ', this.peers[id]);
        this.alert('Received a accept request from '+id+' but we do not believe a call is in progress?');
        // peer will have transitioned to active, so send hangup...
        this.send({cmd:'hangup', to:id})
        return true;
      }
      this.peers[id].calledbyus = false;
      this._addcall(id, 1);
    } else {
      this.alert('Huh? Unhandled call action (' + cmd + ')!');
    }
    this.peers[id].updated = true;
    if(this.onpeer) { this.onpeer(o); }
    return true;
  }

  _rtpmsg(o) {
    if(o.cmd != 'rtp') {
      return false;
    }
    var id = o.from;
    console.log('RTP for ' + id + ': ', o);
    if(!id in this.peers) {
      this.alert("Received rtp " + o + "from a peer we do not know of?");
      return true;
    }
    if(!this.peers[id].active) {
      this.alert("Received rtp " + o + " from a peer which is not marked active?");
      return true;
    }
    if(!this.peers[id].sig) {
      this.alert("Received rtp " + o + " from a peer which we do not have a signal object?");
      return true;
    }
    this.peers[id].sig.message(o.data);
    return true;
  }

  _pingmsg(o) {
    if(o.cmd != 'ping') {
      return false;
    }
    this.send({cmd:'pong'});
    return true;
  }
  
  _idmsg(o) {
    if(o.cmd == 'oldname') {
      console.log('received old name: ' + o.data);
      if(!this.id || this.id != o.data) {
        console.log('identity changed...');
        this.id = o.data;
        if(this.onrefresh) {
          console.log('pre-rebind');
          this.onrefresh(o.data);
          console.log('post-rebind');
        }
      }
      return true;
    }
    
    if(o.cmd == 'turnpw') {
       this.turnpw = o.data;
      console.error('got turnpw: ', this.turnpw);
      return true;
    }

    if(o.cmd != 'newid') {
      return false;
    }
    this.wsid = o.data;
    localStorage.setItem('__wsid__'+this.poolid, o.data);
    //if(this.onrefresh) {
    //  //alert('url: ' + window.location.href);
    //  if(window.location.href.indexOf('?') >= 0) {
    //    window.location.assign(window.location.href.substring(0, window.location.href.indexOf('?')) + '?' + o.data);
    //    //alert('1:' + window.location.href.substring(0, window.location.href.indexOf('?')) + '?' + o.data);
    //  } else {
    //    window.location.assign(window.location.href + '?' + o.data);
    //    //alert('2:'+window.location.href + '?' + o.data);
    //  }
    //}
    return true;
  }
  
  _logoutmsg(o) {
    if(o.cmd == 'logout') {
      console.log('RECEIVED LOGOUT');
      this.logout = 1;
      return true;
    }
    return false;
  }
  _onmessage(event) {
    console.log('onmessage: ', event);
    this.hb();
    if(typeof event.data == "string") {
      console.log("Message received: ", event);
      try {
        let resp = JSON.parse(event.data);
        console.log("RX JSON: ", resp);
        if(Array.isArray(resp)) {
          for (var i = 0; i < resp.length; i++) {
            var o = resp[i];
            console.log("process: ", o);
            // try each message type handler in sequence...
            if(this._rejectmsg(o)) {
            } else if(this._acceptmsg(o)) {
            } else if(this._peermsg(o)) {
            } else if(this._actionmsg(o)) {
            } else if(this._rtpmsg(o)) {
            } else if(this._pingmsg(o)) {
            } else if(this._idmsg(o)) {
            } else if(this._logoutmsg(o)) {
            } else {
              console.log("Unhandled text message: ", o);
              this.alert("Unhandled text message.");
            }
          }
        } else {
          console.log("Invalid message (not array) (" + event.data + ")");
          this.alert("Invalid message (not array).");
        }
      } catch(e) {
        console.log("Invalid text message (" + event.data + "): ", e);
        this.alert("Invalid text message.");
      }
    } else {
      console.log("Unhandled binary message: ", event);
      this.alert("Received unhandled binary message.");
    }
    console.log('_onmessage done');
  };
  
  _addcall(id, remote) {
    console.log('create call for: ' + id);
    if(!id in this.peers) {
      this.alert("Attempt to open call to peer we know nothing about: " + this.peers);
      return;
    }
    if(this.peers[id].active) {
      console.log("Attempt to open call to peer but peer is already marked active: " + this.peers);
      this.action('hangup', id);
      return;
    }
    if(this.peers[id].sig) {
      this.alert("Attempt to open call to peer but peer is already has signal object: " + this.peers);
      this.action('hangup', id);
      return;
    }
    if(this.peers[id].rtp) {
      this.alert("Attempt to open call to peer but peer is already has rtpAudio object: " + this.peers);
      this.action('hangup', id);
      return;
    }
    this.peers[id].sig = new rtpSock(this, id);
    this.peers[id].rtp = new rtpAudio(this._ctx, this.peers[id].sig, remote, this._stream);
    this.peers[id].rtp.onclose = () => {
      console.log('onclose hangup ' + id + ': ', this);
      this.action('hangup', id);
    }
    this.peers[id].rtp.onstate = (e) => {
      if(this.onstate){
        console.log("forward onstate");
        this.onstate(id + ' : ' + e);
      }
    }
    this.peers[id].active = true;
    this.peers[id].missed_call = false;
    console.log('set up call: ', this.peers[id]);
    if(this.oncallup) { this.oncallup(id); }
  }

  _delcall(id) {
    // could theoretically be called from partially configured state...
    console.log('delete call for: ' + id);
    if(!id in this.peers) {
      console.log("Attempt to delete call for peer we know nothing about: " + this.peers);
      return;
    }
    if(this.peers[id].rtp) {
      if(this.oncalldown) { this.oncalldown(id); }
      // clean up rtp
      this.peers[id].rtp.onclose = null; // disconnect onclose handler first...
      this.peers[id].rtp.onstate = null;
      this.peers[id].rtp.hangup();
    }
    if(this.peers[id].sig) {
      // clean up signal
      this.peers[id].sig.close();
    }
    this.peers[id].rtp = null;
    this.peers[id].sig = null;
    this.peers[id].active = false;
  }
}
