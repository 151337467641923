<template>
   <div class="home" style="background: ##fbfefc;"  v-if="username" >
      <Navigation :username='username' :studiocaller='studiocaller' :mic='mic' :canvas='canvas' :user_role='user_role'></Navigation>
         <div id="container" ref="container" >
            <p v-if="errorText" class="text-danger">
               {{errorText}}
            </p>
            <div class="component_container" :style="disable_style">
               <Contacts @call:incoming="focus_incoming_call"   :wss="wss" ref="Contacts"  :names="get_onpeer" :mic="mic" :ctx="ctx" :peerUpdate="peerUpdate" :studiocaller="app" :search="search" :isMobile="isMobile" :parentRefs="$refs" :p_date="date" :recAudio="recAudio" :username="username" :uuid="uuid"/>
            </div>
            <div class="component_container invites" v-if="show_invitation">
            <div class="invitation">
               <h4 class="section_header">Invitation</h4>
            </div>
               <Invites :wss="wss" :search="search" :studiocaller="studiocaller" :sent_invites="sent_invites" />
            </div>
         </div>
         <p class="update_state" > {{this.peerUpdate}} </p> 
         <div class="debug_state" style="display:none;position: absolute; bottom: -800px;" ref="debug_state">
            <br> 
         </div>
   </div>

</template>

<script>

import Navigation from '../components/Navigation'
import Contacts from '@/components/Host/Contacts.vue'
import NoSleep from 'nosleep.js'
import json_errorText from './../assets/JSON/errorText.json';
import Invites from "../components/Host/Invites.vue";
import EventBus from '../eventBus';

var noSleep = new NoSleep();

export default {
   name: "Host",
   props: {
      wss: Object,
      username: String,
      studiocaller: Object,
      isMobile: Boolean,
      mic: GainNode,
      ctx: AudioContext,
      date: Date,
      recAudio: { type: Function },
      uuid: String
   },
   data: function () {
      return  {
         search: '',
         sec: 0,
         canvas: this.studiocaller,
         app: this.studiocaller,
         peerUpdate: null,
         peers : Object,
         active_call : false,
         errorText: '',
         p_date: Object, 
         domain_name: '',
         disable_style : {},
         sent_invites: {},
         invitee_uuid: {},
         temppeer: {},
         pause_timer : false, 
         show_invitation : true,
         user_role : 'Presenter',
         refresh_sent_invite_timer: null, //timer used to refesh sent invitations list 
      }
   },
   components: {
      Navigation,
      Contacts,
      Invites
   },
   beforeMount() {
      this.domain_name = window.location.hostname;
      this.p_date = this.date;
      if(typeof this.wss == 'undefined') {
         return
      }

     if (typeof this.wss != "undefined" ) {
         this.wss.onalert = (t) => {
            console.log('wss: ' + t);
            this.print_debug(t) ;
            t  = t.replace(/ *\([^)]*\) */g, " ");
            t  = t.replace("+ act  +","");
            var replaced_key  = '' ; 
            this.errorText = '';
            for (var  key in json_errorText) {
                  var  value = json_errorText[key];
                  replaced_key  = key.replace("+ act  +","");
                  replaced_key = "wsSock: "+ replaced_key;
                  if(value=="The connection to the server has been lost") {
                  } else if(replaced_key  == t ) {
                     this.errorText = value;
                  }
            }
         }

         this.wss.onstate = (m) => {
            this.print_debug(m) ;
         }
      }

      var qaller =  {}  
      var uuids = {}

      this.wss.onaccept = (call, data) => {   
         console.log('this.wss.onaccept Hosts side');
         if(call == 'db' && data.cmd =="db_fetchsent_invites") {
            qaller = data.data;
            qaller = JSON.parse(qaller)
            this.sent_invites = qaller;
            var temp_sent_invites = {};

            for (var idx in this.sent_invites) {
               uuids[this.sent_invites[idx].uuid] = {
                  'uuid' : this.sent_invites[idx].uuid,
                  'email' : this.sent_invites[idx].email,
                  'cell_number' : this.sent_invites[idx].cell_number,
                  'presenter' : this.sent_invites[idx].presenter
               }

               this.sent_invites[idx].uuid;
               if(this.sent_invites[idx].sender) {
                  temp_sent_invites[idx] = this.sent_invites[idx]; 
               }
            }
            
            this.sent_invites = temp_sent_invites; 


            if (this.sent_invites && Object.keys(this.sent_invites).length) {
               // this.refresh_sent_invites;
               if (this.refresh_sent_invite_timer) {
                  clearTimeout(this.refresh_sent_invite_timer); 
               }
               
               var lengthSentInvite = Object.keys(this.sent_invites).length -1;
               var expiry_date = new Date(this.sent_invites[lengthSentInvite].expiry_date);
               var current_time = new Date();
               var timeDiffinvite = expiry_date-current_time;

               this.refresh_sent_invite_timer = setTimeout(function() {
                  // Call event bus to refresh sent invitations. 
                  EventBus.$emit('UPDATE_SENT_INVITES',{}); 
               }, timeDiffinvite);

               console.log('Out refresh_sent_invite_timer: ',this.refresh_sent_invite_timer);
            }


            this.invitee_uuid = { ...uuids};
            this.wss.onpeer = this._onpeer.bind(this);
            this._onpeer();
         }
      }

   return 
   },
   mounted:function() {
      this.checkName();  
      if(this.studiocaller) {
         var _this = this;
         this.wss.timeout_user = setTimeout(function(){
            if(!_this.pause_timer) {
               _this.remove_activity_listeners();
               _this.studiocaller.logout();
            }
         }, 1800000);
         document.addEventListener('keydown', this.user_activity);
         document.addEventListener('mousedown', this.user_activity);
         document.addEventListener('mousemove', this.user_activity);
         this.wss.onappmsg = this._onappmsg_peer.bind(this);
      } else {
         console.log('Studio Caller object not mounted');
      }
     
      if(this.isMobile) {
          noSleep.enable();
      }

      console.log('recAudio in hosts: ',this.recAudio);
   },
   methods: {
     print_debug(text) {
         this.p_date = new Date(); 
         this.count+=1 ;
         this.$refs['debug_state'].innerHTML +=  this.p_date +': ' + text +"<br/>" ;
         this.wss.send({cmd:'log', data: this.p_date +': ' + text}); 
         if(this.count > 100 ) {
            this.$refs['debug_state'].removeChild(this.$refs['debug_state'].childNodes[0]);
            this.$refs['debug_state'].removeChild(this.$refs['debug_state'].childNodes[0]);
         }
      },
      checkName() {
         if(!this.username) {
            this.$router.go(-1);
         }
      },
      check() {
         this.mic.gain.value = 0 ;
      },
      focus_incoming_call() {
         // this.$refs.container.scrollIntoView();
      },
      full_screen() {
      //   this.$refs['fullscreen'].toggle();
      },
      resetTimout() {
         var _this = this; 
         clearTimeout(this.wss.timeout_user);
         this.wss.timeout_user = setTimeout(function(){ 
            if(!_this.pause_timer) {
               _this.remove_activity_listeners();
               _this.studiocaller.logout();
            } 
         }, 1800000);
      },
      user_activity() {
         this.resetTimout();
      },
      remove_activity_listeners() {
         console.log('remove_activity_listeners');
         document.removeEventListener('keydown', this.user_activity);
         document.removeEventListener('mousedown', this.user_activity);
         document.removeEventListener('mousemove', this.user_activity);
      },
      _onpeer() {
         var elements = document.getElementsByClassName("meterElement");
         for (var i = 0; i < elements.length; i++) {
            elements[i].style.width=("127px");
            elements[i].parentElement.style.width=("127px");
         }
         
         this.active_call = false;
         this.pause_timer = false;
         var _this = this;
         var active_peers = []; 

         Object.keys(this.wss.peers).forEach((id,_) => {
            var p = this.wss.peers[id];
            var wsid = this.wss.wsid;
            var parent_host = this.wss.peers[id].parent_host;
            
            if(this.wss.wsid != parent_host) {
               if(this.wss.peers[id].active_with_host && !this.wss.peers[parent_host].active) {
                  this.wss.peers[id].active_with_host = false;
               }
            } else {
               // If parent of peer is logged in user.
               // Issue is when peers is active with a different peer. Not sure how to know if that host call has been removed.

            }

            if((p.active) || (p.calledbyus) || (p.callingus)) {
               this.pause_timer = true; 
            } 
            if(p.active || p.active_with_host) {
               active_peers.push({
                  'id' : id,
                  'name' : this.wss.peers[id].name,
                  'mic_muted' : (typeof this.wss.peers[id].mic_muted != 'undefined' ? this.wss.peers[id].mic_muted : ''),
                  'speaker_muted' : (typeof this.wss.peers[id].speaker_muted != 'undefined' ? this.wss.peers[id].speaker_muted : '')
               });
            } else {
               if(typeof p.raise_hand != 'undefined') {
                  p.raise_hand = 'false';
               }
            }
            //FIXME: this.invitee_uuid[id].uuid
            if(typeof this.invitee_uuid[id] != 'undefined' && this.invitee_uuid[id].uuid == id) {
               // this.wss.peers[id].parent_host = wsid;
               this.wss.peers[id].invited = true;
               this.wss.peers[id].email = this.invitee_uuid[id].email;
               this.wss.peers[id].cell_number =  this.invitee_uuid[id].cell_number;
               this.wss.peers[id].presenter = this.invitee_uuid[id].presenter;
            }

            if(p.updated) {
               if(this.wss.peers[id].updated) {
                  this.wss.peers[id].updated = false; 
               } else {
                  this.wss.peers[id].updated = true;
               }

               var current_date_time = new Date; 
               current_date_time = current_date_time.getTime();
               this.peerUpdate = current_date_time.toString();

               if(p.deleted) {
                  // console.log('Delete peerRec: ',this.wss.peers[id]);
                  if(typeof this.wss.peers[id].peerRec!= 'undefined') {
                     // EventBus.$emit('DELETE_RECORDING_IN_DOM',this.wss.peers[id].name);
                     // FIXME: might cause race condition with multi recording.
                     console.log('In delete function: ', this.wss.peers[id]);
                     // document.getElementById('audio_recording_'+this.wss.peers[id].name).remove();
                     this.wss.peers[id].peerRec.terminateWorker(true);
                  }

                  delete this.wss.peers[id];
               }
               p.updated = false; 
            }
            this.errorText = '' ;

         });
         if(active_peers) {
            Object.keys(this.wss.peers).forEach((id,_) => {
               if(this.wss.peers[id].invited) {
                  this.studiocaller.wss.action('app',id , {cmd:'active_peers', data:{active_peers: active_peers}});
               }
            });

            // Send mic and speaker mute status to peers. 
            Object.keys(this.wss.peers).forEach((id,_) => {
               if(typeof this.wss.peers[id].mic_muted != 'undefined' && this.wss.peers[id].mic_muted) {
                  this.studiocaller.wss.action('app', '00000000-0000-0000-0000-000000000000', 
                  {
                     cmd:'mute_mic', 
                     data: {
                        peer_uuid:id,
                        state:this.wss.peers[id].mic_muted
                     }
                  });
               }

               if(typeof this.wss.peers[id].speaker_muted != 'undefined' && this.wss.peers[id].speaker_muted) {
                  this.studiocaller.wss.action('app', '00000000-0000-0000-0000-000000000000', 
                  {
                     cmd:'mute', 
                     data: {
                        peer_uuid:id,
                        state:this.wss.peers[id].speaker_muted
                     }
                  });
               }
            });

            // Send producer mic status. 
            if (!this.mic.gain.value) {
               this.studiocaller.wss.action('app', '00000000-0000-0000-0000-000000000000', 
               {
                  cmd:'mute_mic', 
                  data: {
                     peer_uuid:this.studiocaller.uuid,
                     state:true
                  }
               });
            } 
         }

         if (this.pause_timer) {
            clearTimeout(this.wss.timeout_user);
         }
         
         this.disable_style = {
            "pointer-events": "none",
            // opacity: "0.5",
            // background: "#CCC",
         }
         var _this = this;
         setTimeout(function() { 
            _this.disable_style = {}
         }, 400);

         this.temppeer = this.wss.peers;  
         return this.wss.peers
      },
      _onappmsg_peer(id, cmd, data) {
         this.session_event_log({
            session: this.session,
            action: cmd.cmd,
            pool: this.studiocaller.pool, 
            uuid: this.studiocaller.uuid, 
            action_data: {'user': id} 
         });
          if(cmd.cmd == 'active_peers') {
            var _this = this; 
            var list_from_other_host = [];
            var active_peers = cmd.data.active_peers;
            if(this.wss.peers[id].active) {
               Object.keys(this.wss.peers).forEach((idx,_) => {
                  var parent_host = _this.wss.peers[idx].parent_host;
                  console.log('name ->'+ _this.wss.peers[idx].name+' parent_host '+parent_host);
                  // NOTE: if typeof _this.wss.peers[parent_host] is undefined. It might mean that the parent host is the logged in user.
                  console.log(typeof _this.wss.peers[parent_host]); 
                  if(parent_host != _this.wss.wsid) {
                     console.log('parent_host -> ' + parent_host); 
                     if ((typeof parent_host  != 'undefined' && !this.wss.peers[parent_host].active) || (parent_host == id)) {
                        _this.wss.peers[idx].active_with_host = false;
                     }
                  } else {
                     // FIXME : does not make sense.
                     //  _this.wss.peers[idx].active_with_host = false;
                  }
               });
               
               for(var idx in active_peers) {
                  var uuid = active_peers[idx].id;
                  if (typeof _this.wss.peers[uuid] != 'undefined' && !_this.wss.peers[uuid].invited) {
                     _this.wss.peers[uuid].active_with_host = true;
                     _this.wss.peers[uuid].parent_host = id;
                  } else if(typeof _this.wss.peers[uuid] != 'undefined' && _this.wss.peers[uuid].invited && !_this.wss.peers[uuid].active) {
                     _this.wss.peers[uuid].active_with_host = true;
                     _this.wss.peers[uuid].parent_host = id;
                  }
               }


// --- Transmit 
               var tx_active_peers = []; 
               
               Object.keys(this.wss.peers).forEach((idx,_) => {
                  var p = this.wss.peers[idx];
                  if(p.active || p.active_with_host) {
                     tx_active_peers.push({
                        'id' : idx,
                        'name' : this.wss.peers[idx].name
                     });
                  }
               });
               Object.keys(this.wss.peers).forEach((idx,_) => {
                  if(this.wss.peers[idx].invited && idx !=id ) {
                     this.studiocaller.wss.action('app',idx , {cmd:'active_peers', data:{active_peers: tx_active_peers}});
                  }
               });
            }
         }  else if (cmd.cmd == 'mute') {
            EventBus.$emit('RAISE_HAND',id);   
         } else if (cmd.cmd == 'raise_hand') {
            var payload = {
               'id' : id,
               'cmd' : cmd
            };
            EventBus.$emit('RAISE_HAND',payload); 
         } else if (cmd.cmd == 'isMobile' ) {
            if(typeof this.wss.peers[id] != "undefined") {
               this.wss.peers[id].isMobile =  cmd.data.isMobile;
            }
         } else if (cmd.cmd == "toggle_guests_mic" ) {
            EventBus.$emit('MUTE_MIC',{
               id: id,
               mic_mute_state: null 
            });
         }

         var current_date_time = new Date; 
         current_date_time = current_date_time.getTime();
         this.peerUpdate = current_date_time.toString();
      },
      startrec(e) {
         if(!this.ctx) {
            alert('connect before recording...');
            return;
         }
         this.recording_status = 'Recording...';
         this.recRec.start();
         this.rec = true;
      },
      stoprec(e) {
         if(!this.rec) {
         alert('start recording first!');
         }
         this.recRec.stop();
         this.recording_status = ' Ready to download';
      },
      pauserec(e) {
         if(this.recRec.state === "recording") {
            this.recRec.pause();
            this.pause_rec = true;
            this.recording_status = 'Paused';
            console.log("recording paused", this.recRec);
            // recording paused
         } else if(this.recRec.state === "paused") {
            this.pause_rec = false;
            this.recRec.resume();
            this.recording_status = 'Recording...';
            console.log("recording resume", this.recRec);
            // resume recording
         }
      },
      _onalert() {
      },
      Check1() {
 
      },
      downloadrec() {
         var audio_recording = document.getElementById("audio_recording");
         var cdate = new Date(); 
         var date = cdate.getDate()+''+(+cdate.getMonth()+1)+''+cdate.getFullYear();
         var time = cdate.getHours()+''+cdate.getMinutes()+''+cdate.getSeconds();
         if(this.recording_name) {
            audio_recording.download = this.recording_name;
         } else {
            audio_recording.download = 'radio_qaller_'+date+'_'+time;
         }
         audio_recording.click();
      },
      toggle_invitation(){
         if (this.show_invitation) {
            this.show_invitation = false; 
         } else {
            this.show_invitation = true; 
         }
      }
   },
  beforeDestroy () {
   // document.removeEventListener("backbutton", this.BackFunction);
  },
  computed : {
      get_onpeer: function() {
         console.log('get_onpeer ');
         this.temppeer
         return this.wss.peers;
      },
  },
   watch: {
      pause_timer: function (val) {
         // alert('active_call-> '+this.active_call);
         if (!this.pause_timer) {
            this.resetTimout();
         }else {
            clearTimeout(this.wss.timeout_user);
         }
      },
   },
};
</script>

<style scoped>

   .component_container.invites , .component_container.calls {
      padding: 10px;
      background-color: #23334f;
      margin: 40px;
      border-radius: 10px;
      color: #dfe4e8;
   }  


/*-------Old------------*/
   .home {
      height: 100%;
      flex-direction: column;
      min-width: 1356px;
      background-color: #417898;
      min-height: 127vh;
   }
   .row {
      margin-left: 0px;
      margin-right: 0px;
      height:100%;
   }
   .card-title {
      text-decoration: underline;
   }
   .input-group {
      margin-bottom: 10px;
   }
   .search_image {
      width:30px;
      height:30px;
   }
   #container {
      height: 80%;
   }
   .active_contacts .card {
      margin-bottom: 20px;
   }
   .fill {
      width:90%; /*set to 45 FIXME */ 
      display: inline-block;
   }
   .update_state {
      display: None;
   }
   .recording_container {
      display: inline-block;
      width: 100%;
      margin: 10px 10px 10px 0px;
   }
   .recordingBut{
      float: left;
      /* padding-left: 5px; */
   }
   .recording_name {
      float: left;
      padding-left: 5px;
      margin: auto;
   }
   @media (max-width: 900px) {
      .fill {
         display: contents;
/*         margin: auto ;
         margin-bottom: 10px ;
         display: contents;*/
      }
      .active_contacts{
/*          width: 100%; */
/*         margin: auto ;
         margin-bottom: 10px ;
         display: contents;*/
      }
   }
   .swipe {
      width: 100px;
      height: 30px;
      margin-top: auto;
      margin-bottom: auto;
      background-color: grey;
      color: white;
      font-family: Arial;
      text-align: center;
      line-height: 30px;
   }
   .test {
      position: absolute; 
      top: 0; 
      left: 0; 
      height: 100%; 
      width: 100%;
      background: #00800030;
   }
   .debug_state{
    float: left;
    text-align: left;
    margin: 20px;
    height: 550px;
    overflow: scroll;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
   }
   .section_header {
      display: inline;
   }
       
   .expand_button {
      float : right;
      background-image: url("../assets/images/expand_section.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width:50px;
      height:20px;
      margin: 4px;
   }
   .collapse_button {
      float : right;
      background-image: url("../assets/images/collapse_section.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      margin-right: 17px;
   }
   .expand_button:hover {
      background-color: #ddd;
      color: #343a40;
   }

</style>
