<template>
   <div class="Invites ">
      <div class="invites">
          <div class="search_fields">
            <div class="d-flex align-items-center alert alert-primary sent_invite_feedback" v-if="invite_succ_sent">
              An email and/or SMS invitation is being sent to {{sent_name}}
              <div class="spinner-border spinner_icon" role="status" aria-hidden="true"></div>
            </div>

            <div class="text-center send_invite" >
                  <div class="fitcontents" >
                      <textarea class="name" autocomplete="false" rows="1" cols="60" title="Enter guest's name" v-on:keyup="searchInvitee('name')"  v-on:click="searchInvitee('name')" type="text" id="name" name="name" v-model.trim="name" @change="searchInvitee('name')" placeholder="Name" @keydown="select_tr"></textarea>
                  </div>
                  <div class="fitcontents">
                      <textarea class="email_address" autocomplete="false" rows="1" cols="60" title="Enter guest's email address" v-on:keyup="searchInvitee('email_address')" v-on:click="searchInvitee('email_address')" type="text" id="email_address" name="email_address"  @keydown="select_tr" @change="searchInvitee('email_address')" v-model.trim="email_address"  placeholder="Email Address"></textarea>
                  </div>
                  <div class="fitcontents" >
                      <textarea class="cell_number" autocomplete="false" rows="1" cols="60" title="Enter guest's cellphone number" @keypress="isNumber($event)"  v-on:keyup="searchInvitee('cell_number')" v-on:click="searchInvitee('cell_number')" type="text" id="cell_number" name="cell_number" @keydown="select_tr" @change="searchInvitee('cell_number')" v-model.trim="cell_number" placeholder="Cell Number"></textarea>
                  </div>
                  <div class="fitcontents ">
                      <div  @click="clear_field"  title="Click to clear all invitation fields" class="btn_cancel_field red_hover circular_div">
                        <img class="log_action" data-action="invitation_clear_field" src="@/assets/images/cancel.svg"/>
                      </div>
                      <div title="Click to send invitation" @click="send_invite" class="btn_send_invite green_hover circular_div">
                        <img class="log_action" data-action="invitation_sent" src="@/assets/images/send_invite.svg"/>
                      </div>
                  </div>
            </div>
          </div>

          <div class="invites_sent" v-if="existing_inivites.length">
            <div class="invite_sent" >
              <div v-click-outside="clear_drop_down" style="color: black;" @mouseleave="invite_sent_mouseOut" @mouseover="invite_sent_mouseOver">
                <!-- Duplicate warning. Have to check query on server side -->
                <div ref="invite_dropdown" v-for="(invite,index) in existing_inivites" :key="invite.uuid" class="invite_dropdown" :class="{ focus: index === focus}">
                  <div @click="populate_field(invite.uuid)" title="Click to resend invitation" class="invitee_name log_action" data-action="invitation_drop_down_resend">
                      <!-- {{invite.uuid}} -->
                      {{invite.name}}
                  </div>
                  <div @click="populate_field(invite.uuid)" title="Click to resend invitation" class="invitee_email log_action" data-action="invitation_drop_down_resend">
                      {{invite.email}}
                  </div>
                  <div @click="populate_field(invite.uuid)" title="Click to resend invitation" class="invitee_cell_number log_action" data-action="invitation_drop_down_resend"> 
                      {{invite.cell_number}}
                  </div>
                  <div @click="resend_invite(invite.uuid)" title="Click to populate the above fields" class="populate_fields log_action" data-action="invitation_drop_down_populate">
                    <div class="circular_div">
                      <img src="@/assets/images/populate.svg"/>
                    </div>
                  </div>
                </div> 
              </div> 
            </div>
          </div>
          <div class="text_danger">
              <p  v-if="errorText" class="text-danger">{{errorText}}</p>
          </div>
      </div>
      <div style="text-align: left;">
          <!-- <div class="alert alert-primary"  v-if="invite_succ_sent" >   -->
          <!-- <div class="alert alert-primary"  v-if="true" >  
              An email and/or SMS invitation is being sent to <b>{{sent_name}}</b>
          </div> -->

          <ul class="invite_list">
            <li v-for="(value,propertyName) in render_sent_invite" :key=propertyName>
                <Invite  :p_sent_invite="value" :wss="wss" :uuid="value['uuid']" :name="value['name']" :sender="value['sender']" :pool="value['pool']" :cell_number="value['cell_number']" :email_address="value['email']" :radio_station="radio_station" :studiocaller="studiocaller"/>
            </li>
          </ul>
      </div>
   </div>
</template>

<script>
    import axios from 'axios';
    // import exportFile from './ajaxfile.php'
    import Invite from "./Invite.vue";
    import { v4 as uuidv4 } from 'uuid';
    import ClickOutside from 'vue-click-outside';
    import EventBus from '../../eventBus';

    export default {
        name: 'Invites',
        components: {
            Invite,
        }, 
        props: {
            wss: Object,
            studiocaller: Object,
            search: String,
            sent_invites: Object
        },
        data: function () {
            return {
                name: '',
                cell_number: '',
                email_address: '', 
                errorText : '', 
                sms: {}, 
                url : 'https://'+process.env.VUE_APP_BASEURL+'/invite/', 
                message: 'You have been invited to use ',
                uuid: '',
                sender : this.wss.id,
                email_subject: process.env.VUE_APP_INSTANCE_NAME+' Invitation',
                email_body: '',
                pool: this.studiocaller.pool,
                invite_succ_sent: false,
                radio_station: this.studiocaller.radio_station,
                existing_inivites:[],
                sent_name: '',
                focus: 0,
                mouseOver: false,
            }
        },
        mounted: function() {
            var qaller = {} ;
            var sms_queue = {} ; 
            var email_queue ={} ; 
            var sent = [];
            var email = [];
            this.update_sent_invites();
            var _this = this;
        
            EventBus.$on('SEND_INVITE_FEEDBACK', (name) => {
                this.sent_name = name; 
                this.send_invite_feedback();
            })
            
            EventBus.$on('UPDATE_SENT_INVITES', (payload) => {
                this.update_sent_invites();
            });  

        },
        methods: {
            send_invite() {
               this.validate_fields();
              //  return;
               if(this.validate_fields()) {
                  this.uuid = uuidv4();
                  if(this.email_address && this.cell_number ) {
                      this.send_sms_email();
                  }else {
                    
                    if (this.email_address) {
                      this.send_email();
                    } 
                    if(this.cell_number){
                      this.send_sms();
                    }

                  }
                  var tempname = this.name; 
                  this.sent_name = tempname;
                  this.send_invite_feedback();
              }
            },
            send_invite_feedback () {
               this.clearField();
               var _this = this; 
               this.invite_succ_sent = true;
               setTimeout(function(){
                  _this.update_sent_invites();
                  _this.invite_succ_sent = false;
                  _this.sent_name = '';
               }, 4000);
            },
            validate_fields() {
              if(!this.name){
                this.errorText = "Please enter a Name"
                return false ;
              }
              if(!this.cell_number && !this.email_address) {
                this.errorText = "Please provide a Cell number  or Email Address"
                return false ;
              } 
              if(this.cell_number) {
                if(!this.cell_number.match("[0-9]+")) {
                  this.errorText = "Cellphone number contains special characters"
                  return false ;
                }
                
                if(this.cell_number.length != 10) {
                  if(!(this.cell_number.length == 11 && this.cell_number[0] == '2' && this.cell_number[1] == '7')) {
                    this.errorText = "Invalid Cellphone number"   
                    return false ;
                  }
                }

                if(this.cell_number[0] == '0') {
                  var cell = this.cell_number.substr(1,9); 
                  this.cell_number  = '27'+cell; 
                }
              } 

              if(this.email_address) {
                if(!this.validateEmail(this.email_address)) {
                  this.errorText = "Invalid email addreass"
                  return false
                }
              }
              //remove any special charactors 
              this.name = this.remove_special_characters(this.name);
              return true ;
            },
            clear_drop_down() {
              this.focus = 0;
              this.existing_inivites = {};
            },
            clear_field() {
              this.name = '';
              this.cell_number = '';
              this.email_address = '';
              this.existing_inivites = {};
            },
            clearError() {
                this.errorText = "";
            },
            renderSentInvite() {
               return this.sent_invites
            },
            Check1(){
              console.log('Check1');
              // this.invite_succ_sent = true; 
            },
            remove_special_characters(string) {
              return string.replace(/[^\w\s]/gi, '') ;
            },
            validateEmail (email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },
            clearField() {
              this.name = "";
              this.cell_number = "";
              this.email_address = "";
            },
            send_sms() {
              var invite_message = this.message+this.url+this.uuid;
              this.wss.send({cmd:'db', data: {act: "setsms", data:{ msisdn: this.cell_number, message: invite_message,name: this.name, email:this.email_address, uuid:this.uuid, sender:this.sender,pool:this.pool}}});
            },
            send_email() {
              var link = this.url+this.uuid ;
              this.email_body = '<p>Dear '+this.name+'</p><p>You have been invited to a '+process.env.VUE_APP_INSTANCE_NAME+' interview by '+this.sender+' from '+this.radio_station+' <p>Your invitation will expire on <b>__EXPIRY_DATE__</b>.</p> Click the below link below when you are ready to initiate a call: </p><p><a href="'+link+'">'+link+'</a></p>__SIGNATURE__ ';
              this.wss.send({
                cmd:'db', 
                data: {
                  act: "setemail", 
                  data: {
                    email: this.email_address, 
                    subject:this.email_subject, 
                    HTML:this.email_body,
                    name: this.name,
                    uuid:this.uuid, 
                    sender:this.sender,
                    pool:this.pool
                  }
                }
              }); 
            },
            send_sms_email() {
              var invite_message = this.message+this.url+this.uuid;
              var link = this.url+this.uuid ;
              // this.email_body = '<p>Dear '+this.name+'</p><p>You have been invited to Qallertest by '+this.sender+'. <p>Your invitation will expire on <b>__EXPIRY_DATE__</b>.</p>  Click the link below to initiate a call</p><p><a href="'+link+'">'+link+'</a></p>__SIGNATURE__ ';
                 this.email_body = '<p>Dear '+this.name+'</p><p>You have been invited to a '+process.env.VUE_APP_INSTANCE_NAME+' interview by '+this.sender+' from '+this.radio_station+' <p>Your invitation will expire on <b>__EXPIRY_DATE__</b>.</p> Click the below link below when you are ready to initiate a call: </p><p><a href="'+link+'">'+link+'</a></p>__SIGNATURE__ ';
              // this.wss.send({cmd:'db', data: {act: "setsms", data:{ msisdn: this.cell_number, message: invite_message,name: this.name, email:this.email_address, uuid:this.uuid, sender:this.sender,pool:this.pool}}});
              this.wss.send({
                cmd:'db', 
                data: {
                  act: "setsms_email", 
                  data: {
                    msisdn: this.cell_number,
                    message: invite_message,
                    email: this.email_address, 
                    subject:this.email_subject, 
                    HTML:this.email_body,
                    name: this.name,
                    uuid:this.uuid, 
                    sender:this.sender,
                    pool:this.pool
                  }
                }
              });     
            },
            fetch_email() {
              this.wss.send({cmd:'db', data: {act: "fetchemail"}}); 
            },
            update_sent_invites() {
              this.wss.send({cmd:'db', data: {act: "fetchsent_invites", data: {sender: this.sender,pool:this.pool,radio_station: this.radio_station}}}); 
            },
            show_alert(temp){
              alert(temp);
            },
            searchInvitee(field) {
              var _this = this;

              var temp_number = this.cell_number;

              if(temp_number.match("^(0).*")) {
                temp_number = temp_number.replace(/^.{1}/g, '27');
              } 
              var search_valid = true; 
              if(this.name.length < 3 && this.email_address.length < 3 && temp_number.length < 3) {
                // this.existing_inivites = [];
                search_valid =false; 
              }  
              if(this.name.length == 0 && this.email_address.length == 0 && temp_number.length == 0) {
                this.$axios.get('https://'+process.env.VUE_APP_BASEURL+'/backend.php?action=db_search_recent_invitee&sender='+this.wss.id+'&pool='+this.pool)
                    .then(function (response) {
                      _this.existing_inivites = [];
                      _this.existing_inivites  = response.data.result;
                      console.log('response.data.result  -> ', response.data.result);
                      console.log('existing_inivites  -> ', _this.existing_inivites);
                  });

                  // return;
              } else if (search_valid) {
                this.$axios.get('https://'+process.env.VUE_APP_BASEURL+'/backend.php?action=db_search_invitee&name='+this.name+'&email_address='+this.email_address+'&cell_number='+temp_number+'&sender='+this.wss.id+'&pool='+this.pool)
                  .then(function (response) {
                    _this.existing_inivites  = response.data.result;
                    console.log('existing_inivites  -> ', _this.existing_inivites);

                });
              }


            },
            isNumber: function(evt) {
              evt = (evt) ? evt : window.event;
              var charCode = (evt.which) ? evt.which : evt.keyCode;
              if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
              } else {
                return true;
              }
            },
            resend_invite(uuid) {
              for(var idx in this.existing_inivites) {
                console.log('existing_inivites[idx] -> ' + this.existing_inivites[idx].uuid);
                if (this.existing_inivites[idx].uuid  == uuid) {
                    console.log('cell_number cell_number -> ' + this.cell_number);
                    if(this.existing_inivites[idx].cell_number) {
                      this.cell_number = this.existing_inivites[idx].cell_number;
                    } 

                    console.log('existing_inivites name -> ' + this.existing_inivites[idx].name);
                    if(this.existing_inivites[idx].name) {
                      this.name = this.existing_inivites[idx].name;
                    } 
                    
                    console.log('existing_inivites email -> ' + this.existing_inivites[idx].email);
                    if(this.existing_inivites[idx].email) {
                      this.email_address = this.existing_inivites[idx].email;
                    }
                }
              }
              // this.send_invite_feedback();
              this.existing_inivites = [];
            },
            populate_field : function (uuid) {
              console.log('Send invite to: '+uuid); 
              this.resend_invite(uuid);
              this.send_invite();
            },

            select_tr: function(event) {
              if (this.mouseOver == false ) {
                console.log('existing_inivites: ',this.existing_inivites);
                switch (event.keyCode) {
                  case 38:
                    
                    if (this.focus > 0) {
                      this.focus--;
                    }
                    break;
                  case 40:
                    
                    if (this.focus < this.existing_inivites.length - 1) {
                      this.focus++;
                    }
                    break;

                  case 13:

                    // This feels too expensive. 
                    console.log('this.existing_inivites: ', this.existing_inivites);
                    console.log('this.existing_inivites this.focus: ', this.focus);
                    console.log('uuid: ',this.existing_inivites[this.focus].uuid);
                    this.populate_field(this.existing_inivites[this.focus].uuid);
                    
                    this.focus = 0;
                    this.existing_inivites = {};
                    break;
                }

                console.log('focus: ',this.focus);
              }
            },
            invite_sent_mouseOver : function () {
              this.mouseOver = true;
              this.focus = null; 
            },
            invite_sent_mouseOut : function () {
              this.mouseOver = false;
            }
        },
        computed: {
          render_sent_invite : function() {
              console.log('render_sent_invite: '+JSON.stringify(this.sent_invites,{},4));
              var filter  = [] ; 
              
              // if(typeof sent_invites != 'undefined' ) {
                if(this.search) {
                    for (var idx in this.sent_invites) {
                      console.log("sent_invites",this.sent_invites[idx]);
                      console.log("search",this.search);
                      if(this.sent_invites[idx].name.toLowerCase().includes(this.search.replace(/\s/g,'').toLowerCase())) {
                          console.log("sent_invites_name",this.sent_invites[idx].name);
                          filter.push(this.sent_invites[idx])
                      }
                    }
                    return filter
                }
                return  this.sent_invites
              // } else {
              //   return  {}
              // }
          }
        },
         directives: {
            ClickOutside
         },
        // watch: {
        //   name: function (val) {
        //     console.log('aaaa' + val); 
        //     // this.fullName = val + ' ' + this.lastName
        //   },
        //   // lastName: function (val) {
        //   //   this.fullName = this.firstName + ' ' + val
        //   // }
        // }
    }
</script>


<style scoped>
  .invite_list {
    padding: 0px;
  }
  .invite_list  li {
    padding: 0px;
    background-color: #b7c5df;
    margin: 3px 3px 5px 3px;
    position: relative;
    display: block;
    border: 0;
    color: #3a3a3a;
    /* background-color: #a2c3a263; */
    border-radius: 4px;
  }

/* --old-- */

  .Invite {
    display: flex;
  }
  .fitcontents {
    display:contents;
  }
  .name, .cell_number , .email_address  {
    margin-top: 10px;
    margin-bottom : 10px;
    text-align: center;
  }
  .name {
    width : 30%;
    margin-left : 5px;
  }

  .cell_number , .email_address  {
    width : 30%;
    margin-left:5px;
  }
  .text_danger {
    margin-left: 20px;
  }

  .list-group-item {
    position: relative;
    display: block;
    padding: 0px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 0px solid rgba(0,0,0,.125);
    background: #dce1df;
    border: 2px solid;
  }
  .invites {
    width:100%;
  }
  .invites img {
    height:54px;
  }
  .send_invite{
    display: flex;
    float: right; 
    width: 747px;
  }
  .btn_send_invite {
    margin: 12px 5px;
    border: 0px solid;
    background: white; 
  }
  .btn_cancel_field {
    margin: 12px 5px;
    border: 0px solid;
    background: white; 
  }
  .btn_send_invite,.btn_cancel_field {
    cursor:pointer;
  }
  .send_invite textarea {
    line-height: 50px;
    text-align: center;
    resize: none;
    background-color: #bac1cc;
    border-radius: 5px;
    border: 2px solid #fff;
    height: 57px;
    overflow-y: hidden;
    overflow-x: hidden;
    white-space: nowrap;
  }
  /* .green_hover:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
  }  */
  .red_hover:hover {
    background-color:#fad7d7;
  }
  .circular_div {
    border-radius: 50%;
    width: 51px;
  }
  .green_hover:hover,.focus {
    background-color:#28a74545;
  }
  input:-internal-autofill-selected {
      -webkit-appearance: menulist-button;
      background-color: rgb(232, 240, 254) !important;
      background-image: none !important;
      color: -internal-light-dark-color(black, white) !important;
  }

  .search_fields {
    display: inline-block;
    width: 100%;
  }
  .invites_sent {
    float: right;
    text-align: left;
    position: absolute;
    z-index: 100;
    right:0px;
    margin-right: 44px;
    background: rgb(219 226 239);
    width: 747px;
    padding: 10px;
    border-radius:5px;
  }
  /* .invites_sent .invite_sent table td.invitee_name,
  .invites_sent .invite_sent table td.invitee_email,
  .invites_sent .invite_sent table td.invitee_cell_number {
    width: 30%;
    text-align: left;
    cursor: default;
    padding-left: 2px;
  } */
  .invite_dropdown {
    overflow:hidden ;
    border-radius: 5px;
    cursor: pointer;
  }
  .invites_sent .invite_dropdown > div {
    display:inline-block;
    width: 214px;
    padding: 2px;    
    line-height: 38px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .invites_sent .invite_dropdown > div:last-child {    
    width: auto;
    float:right;
  }
  /* .invites_sent .invite_sent table td.button1_invitee,
  .invites_sent .invite_sent table td.populate_fields {
    width: 20%;
  } */

  /* .invitee_name:hover, */
  /* .invitee_email:hover, */
  .invite_dropdown:hover
  {
      background: #28a74545;
  }

  .invite_dropdown:hover td.populate_fields
  {
    background: rgb(219 226 239);
  }

  .populate_fields > div {
    margin-right: auto;
    margin-left: auto;
    /* cursor: pointer; */
  }
  .populate_fields > div:hover{
    background-color: #28a74545;
  } 
  /* *, ::after, ::before {
    -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
            box-sizing: content-box;
  } */
  form .send_invite{
    width : 738px;
  }
  .sent_invite_feedback {
    float:left;
    margin-bottom:0px;
  }
  .spinner_icon {
    margin-left: 10px;
  }
</style>
