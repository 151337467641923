'use strict';

export class Ring {
  constructor(ctx) {
    this.ctx = ctx;
    this.sounds = {};
    this.onload = null;
    
    // load/generate buffer...
    var sampleRate = ctx.sampleRate;
    var duration = 2*sampleRate;
    var numChannels = 1;
    var frequency = 1500;
    this.defaultbuffer  = ctx.createBuffer(numChannels, duration, sampleRate);
    // fill the channel with the desired frequency's data
    var channelData = this.defaultbuffer.getChannelData(0);
    for (var i = 0; i < sampleRate; i++) {
      channelData[i]=Math.sin(2*Math.PI*frequency*i/(sampleRate));
    }
  }

  load(id, url) {
    console.log('load sound '+id+' from '+url);
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.responseType = "arraybuffer"; 
    if(url.endsWith('.u16')) {
      // u16?
      request.onload = () => {
        console.log('onload ' + url + ':' + request.response.byteLength);
        var u16 = new Uint16Array(request.response);
        console.log('u16 length: ' + u16.length);
        var buf = this.ctx.createBuffer(1, u16.length, 24000);
        var cd = buf.getChannelData(0);
        for(var i = 0; i < u16.length; i++) {
          cd[i] = u16[i];
          cd[i] -= 32768.0;
          cd[i] /= 32768.0;
        }
        this.sounds[id] = buf;
        if(this.onload) {
          this.onload(id);
        }
      }
    } else {
      request.onload = () => {
        // default to u8
        console.log('onload ' + url + ':' + request.response.byteLength);
        var u8 = new Uint8Array(request.response);
        console.log('u8 length: ' + u8.length);
        var buf = this.ctx.createBuffer(1, u8.length, 24000);
        var cd = buf.getChannelData(0);
        for(var i = 0; i < u8.length; i++) {
          cd[i] = u8[i];
          cd[i] -= 128.0;
          cd[i] /= 128.0;
        }
        this.sounds[id] = buf;
        if(this.onload) {
          this.onload(id);
        }
      }
    }
    request.onerror = (e) => {
      alert("Error loading sound '"+id+"': " + e);
    }
    request.send();
  }
  
  start(id = undefined) {
    if(this.src) { return; }
    this.src = this.ctx.createBufferSource();
    if(id && id in this.sounds) {
      this.src.buffer = this.sounds[id];
    } else {
      this.src.buffer = this.defaultbuffer;
    }
    this.src.loop = true;
    // this.src.connect(this.ctx.destination);
    if(window.mydestination) {
      console.error('connect ring to loopback');
      this.src.connect(window.mydestination);
    } else {
      this.src.connect(this.ctx.destination);
    }
    //
    this.src.start(0);
  }
  
  stop() {
    if(!this.src) { return; }
    this.src.stop(0);
    this.src = undefined;
  }
}


/*
  
  SDP class
  
  Manipulate audio parameters in SDP string
  
  SDP(string) - start a new SDP object from the provided string
  SDP.sdp - produce a string representation of the SDP object
  lookup(codec) - return the rtpmap id of a given codec, or null if it is not present in this SDP
  prefer(codec) - set the specified codec as preferred
  setfmtp(codec, fmtp) - modify/add fmtp string (codec parameters) for a specific codec

*/

class SDP {
  constructor(txt) {
    console.log("New SDP: ", txt);
    this._lastcodec = null;
    this._lastid = null;
    
    let l = txt.split("\n");
    if(!(l.length>1)) { throw "Input does not seem to be valid multi-line SDP?"; }
    this._sdp = [];
    for(let i = 0; i < l.length; i++) {
      let s = l[i].replace(/\r$/, '');
      if(s === "") { continue; }
      //console.log("foo: ", JSON.stringify(s));
      if(s.substring(1, 2) !== "=") { throw "Line " + i + " malformed (no '=')."; }
      let type = s.substring(0, 1);
      let value = s.substring(2);
      if(type == "a" || type == "m") {
        this._sdp.push({type: type, value: value.split(" ")});
      } else {
        this._sdp.push({type: type, value: [value]});
      }
    }
    console.log("SDP: " , this._sdp);
    // locate audio media section
    this._audioline = 0;
    this._audioend = this._sdp.length;
    for(let i = 0; i < this._sdp.length; i++) {
      if(this._sdp[i].type === "m") {
        if(this._sdp[i].value[0] === "audio") {
          if(this._audioline) { throw "SDP contains multiple audio sections!";}
          console.log("m:audio: ", this._sdp[i]);
          this._audioline = i;
        } else {
          if(sdp._audioline) {
            sdp._audioend = i;
            break;
          }
        }
      }
    }
    console.log("audio section: " + this._audioline + " ... " + this._audioend);
    if(!this._audioline) { throw "SDP does not contain an audio section?"; }
  }

  sdp() {
    let s = "";
    for(let i = 0; i < this._sdp.length; i++) {
      s = s + this._sdp[i].type + "=" + this._sdp[i].value[0];
      for(let j = 1; j < this._sdp[i].value.length; j++) {
        s = s + " " + this._sdp[i].value[j];
      }
      s = s + "\r\n";
    }
    console.log("mangled sdp: " + s);
    return s;
  }
  
  lookup(codec) {
    if(codec === this._lastcodec) {
      return this._lastid;
    }
    for(let i = this._audioline; i < this._audioend; i++) {
      if(this._sdp[i].type === "a" && this._sdp[i].value[0].indexOf("rtpmap:") === 0) {
        if(this._sdp[i].value[1] === codec || this._sdp[i].value[1].indexOf(codec + "/") === 0) {
          console.log("rtpmap: ", this._sdp[i]);
          this._lastcodec = codec;
          this._lastid = this._sdp[i].value[0].substring(7);
          return this._lastid;
        }
      }
    }
    return null;
  }
  
  prefer(codec) {
    let id = this.lookup(codec);
    if(id === null) {
      console.log("Preferred codec is not available: " + codec);
      return null;
    }
    let n = [];
    n.push(this._sdp[this._audioline].value.shift()); // media type (audio)
    n.push(this._sdp[this._audioline].value.shift()); // port
    n.push(this._sdp[this._audioline].value.shift()); // proto
    if(!(this._sdp[this._audioline].value.length > 0)) {
      console.log("Huh? SDP has malformed audio media entry.");
      return null;
    }
    n.push(id);
    while(this._sdp[this._audioline].value.length > 0) {
      let v = this._sdp[this._audioline].value.shift();
      if(v == id) { continue; }
      n.push(v);
    }
    this._sdp[this._audioline].value = n;
    return id;
  }
  
  setfmtp(codec, fmtp) {
    let id = this.lookup(codec);
    if(id === null) {
      console.log("Preferred codec is not available: " + codec);
      return null;
    }
    let n = ["fmtp:" + id, fmtp];
    for(let i = this._audioline; i < this._audioend; i++) {
      if(this._sdp[i].type === "a" && this._sdp[i].value[0] === n[0]) {
        console.log("fmtp: ", this._sdp[i]);
        this._sdp[i].value = n;
        return id;
      }
    }
    // if there was nothing to replace, then insert
    this._sdp.splice(this._audioend, 0, {type: "a", value: n});
    this._audioend++;
    return id;
  }
}

/* 
  rtpSig class (ABSTRACT - application must create an implementation of this object for use with rtpAudio)
  
  This class must provide a fully connected bi-directional comms channel between two peers.
  
  Implememntation must implement
    rtpSend(msg) - transmit native java object 'msg' to peer.
    received messgaes must be directed to super.rtpRx(resp) - where 'resp' is the decoded native java object.
  
  Two compulsory event handlers:
    onrtpmessage - reserved for use by rtpAudio class (receives messages from peer via super.rtpRx
    oncall - can be used by host application - gets called when a peer message arrives, but no repAudio 
             object has bound to onrtpmessage
  
*/

export class rtpSig {
  constructor() {
    this.onrtpmessage = null;
    this.oncall = null;
  }
  
  close() {
    // send localclose command to any attached rtpAudio...
    this.rtpRx({cmd: "localclose"}); 
    this.onrtpmessage = null;
    this.oncall = null;
  }
  
  rtpRx(msg) {
    if(this.onrtpmessage) {
      this.onrtpmessage(msg);
      return;
    }
    if(this.oncall) {
      this.oncall(msg);
      return;
    }
    this._rtpMessageDummy(msg);
  }
  
  _rtpMessageDummy(msg) {
    if("cmd" in msg && msg.cmd.startsWith("local")) {
      console.log("ignore local message while not bound to rtp: ", msg);
      return;
    }
    console.log("rtpMessage received while our RTP parent is not yet ready - send 'notready' response: ", msg);
    this.rtpSend({cmd: "notready"});
  }
  
  rtpSend(msg) {
    console.log("rtpSend _must_ be overridden in the implementing class: ", msg);
    alert("rtpSend _must_ be overridden in the implementing class!");
    throw("rtpSend _must_ be overridden in the implementing class!");
  }
}

// better not connect to this one...
// eewww - make start-up synchronous - user better be informed about clicking the pop-up
export class micSource extends GainNode {
  constructor(ctx) {
    super(ctx, {channelCount: 1});
    // super(ctx);
    this._ctx = ctx;
    this.gain.value = 1.0;
    this.onopen = null;

    const umOpts = {
      audio: true,
      video: false,
      chromeRenderToAssociatedSink: true,
      echoCancellation: true,
      echoCancellationType: 'browser',
      //autoGainControl: false,
      // channelCount: 1,
      //googAutoGainControl: false,
    };
    navigator.mediaDevices.getUserMedia(umOpts).then(
      (stream) => this._gotStream(stream)
    ).catch(
      function(e) {
        console.log("getUserMedia() error: ", e);
        // alert(`getUserMedia() error: ${e.name}\nMICROPHONE WILL NOT WORK!`);
      }
    )
  }
  // FIXME - suppress ops until _gotStream
  _gotStream(stream) {
    console.log("_gotStream: ", this);
    this._stream = stream;
    this._streamSrc = this._ctx.createMediaStreamSource(this._stream);
    this._streamSrc.connect(this);
    if(this.onopen) { this.onopen(); }
  }
  
  getgain() {
    return this.gain.value;
  }

  setgain(v) {
    this.gain.value = v;
  }

  dispose() {
    if(this._streamSrc) {
      this._streamSrc.disconnect();
      this._streamSrc = null;
    }
    if(this._stream) {
      this._stream = null;
    }
  }
  

}

export class micVis extends AnalyserNode {
  constructor(ctx, canvas) {
    //console.log("micVis: ", ctx);
    super(ctx, {fftSize: 32, channelCount: 1});
    this._inArray = new Uint8Array(this.frequencyBinCount);
    this._maxArray = new Uint8Array(this.frequencyBinCount);

    this._cvs = canvas;
    this._ctx = canvas.getContext("2d");
    this._width = canvas.width;
    this._height = canvas.height;
    this._barwidth = (this._width / this.frequencyBinCount) - 1;

    this._init = 0;
    this._timer = setInterval(this.update.bind(this), 100);
  }
  
  dispose() {
    if(this._timer) {
      clearInterval(this._timer);
    }
  }
  
  
  update() {
    super.getByteFrequencyData(this._inArray);
    if(!this._init) {
      for(let i = 0; i < this._inArray.length; i++) {
        this._maxArray[i] = this._inArray[i];
      }
      this._init = 1;
    }
    for(let i = 0; i < this._inArray.length; i++) {
      if(this._inArray[i] >= this._maxArray[i]) {
        this._maxArray[i] = this._inArray[i];
      } else {
        this._maxArray[i] -= (this._maxArray[i] - this._inArray[i])/128;
      }
    }
    //console.log("update", this._inArray);
    this._ctx.clearRect(0, 0, this._width, this._height);
    let x = 0;
    for(let i = 0; i < this._inArray.length; i++) {
      let h = this._inArray[i];
      this._ctx.fillStyle = 'rgb(' + h + ',' + (255-h) + ',0)';
      h *= this._height;
      h /= 256;
      this._ctx.fillRect(x, this._height - h, this._barwidth, h);
      x += this._barwidth + 1;
    }
    x = 0;
    for(let i = 0; i < this._inArray.length; i++) {
      let h = this._maxArray[i];
      this._ctx.fillStyle = 'rgb(0,0,0)';
      h *= this._height;
      h /= 256;
      this._ctx.fillRect(x, this._height - h, this._barwidth, 1);
      x += this._barwidth + 1;
    }
  }

/*  __connectFrom(source, ...args) {
    console.log("connect");
    super.__connectFrom(source, ...args);
    //source.connect(this._delay, ...args);
  }

  __disconnectFrom(source, ...args) {
    console.log("disconnect");
    super.__disconnectFrom(source, ...args);
    //source.disconnect(this._delay, ...args);
  } */
}

var _lastRun = new Date().getTime();

export class vuVis extends AnalyserNode {
  constructor(ctx, canvas) {
    super(ctx, {fftSize: 1024, channelCount: 1});
    this._inArray = new Float32Array(this.fftSize);
    this._maxVU = 0.0;
    this._fastVU = 0.0;
    this._vu = 0.0;

    this._boxCount        = 0;
    this._boxCountRed     = 0;
    this._boxCountYellow  = 0;
    this._boxGapFraction  = 0;
    this._jitter          = 0;
    this._curVal = 0 ;
  
    this._cvs = canvas;
    this._ctx = canvas.getContext("2d");
    this._width = canvas.width + 5;
    this._height = canvas.height + 5;

    this._boxHeight = 0;
    this._boxGapY = 0;
    this._boxWidth = 0;
    this._boxGapX = 0;
    this._lastRun = new Date().getTime();
    
    this._fps = 100;  
    this._fpsInterval = 0; 
    this._startTime = 0 ;  
    this._now = 0;  
    this._then = 0 ;  
    this._elapsed = 0 ; 
    this._lastLoop = 0; 

    this._fpsInterval = 1000 / this._fps;
    this._then = Date.now();
    this._startTime = this._then;

    this.canvasloop();
  }
  
  dispose() {
    if(this._timer) {
      clearInterval(this._timer);
    }
  }
  

  update() {
    // console.log('Update');
    // FIXME: 
    super.getFloatTimeDomainData(this._inArray);
    
    this._vu = 0 ;
    var maxAmplitude = Math.max(...this._inArray);
    // console.log('maxAmplitude: '+maxAmplitude); 

    this._vu = Math.abs(maxAmplitude);
    this._vu = Math.log(this._vu);
    this._vu += 5.0;
    this._vu /= 5.0;

    // this._vu = Math.ceil(this._vu);

    this._vu  = this._vu/0.9;

    // console.log('_vu: '+this._vu);
    // console.log('_maxVU: '+this._maxVU);

    if(this._vu >= this._maxVU) {
      this._maxVU = this._vu;
    } else {
      this._maxVU -= (this._maxVU - this._vu) * 0.01 ;
    }

    if(this._vu >= this._fastVU) {
      this._fastVU = this._vu;
    } else {
      this._fastVU -= (this._fastVU - this._vu) * 0.01 ;
    }

    // console.log('_fastVU: '+this._fastVU);
    var config = []; 
    this._max             = this._fastVU || 100;
    // this._boxCount        = config.boxCount || 50;
    // this._boxCountRed     = config.boxCountRed || 10;
    // this._boxCountYellow  = config.boxCountYellow || 10;
    // this._boxGapFraction  = config.boxGapFraction || 0.2;
    // this._jitter          = config.jitter || 0.2 ;

    this._boxCount        = config.boxCount || 20;
    this._boxCountRed     = config.boxCountRed || 2;
    this._boxCountYellow  = config.boxCountYellow || 5;
    this._boxGapFraction  = config.boxGapFraction || 0.4;

    this._curVal = 0;


    // Gap between boxes and box height
    this._boxHeight = this._width / (this._boxCount + (this._boxCount+1)*this._boxGapFraction);
    this._boxGapY = this._boxHeight * this._boxGapFraction;
    this._boxWidth = this._height - (this._boxGapY*2);
    this._boxGapX = (this._height - this._boxWidth) / 2;
  }


  draw() {

    // time : less than 0.009999999747378752 milliseconds
    var  t0 = performance.now();
    var targetVal = this._vu;

    // Gradual approach
    if (this._curVal <= targetVal){
      this._curVal += (targetVal - this._curVal) / 1.13;
    } else {
      this._curVal -= (this._curVal - targetVal) / 1.13;
    }

    if (this._curVal < 0) {
      this._curVal = 0;
    }
    var  t1 = performance.now();
    // console.log(`Call to draw -> curl took ${t1 - t0} milliseconds.`);
    // this._curVal = 0;

    //  0.5300000011629891 milliseconds.
    var  t0 = performance.now();
    this.drawBoxes(this._ctx, this._curVal);
    var  t1 = performance.now();

  };

    // Draw the boxes
  drawBoxes(c, val) {
    c.clearRect(0, 0, this._height,this._width);
    c.beginPath();
    c.rect(0, 0, this._width, this._height);
    c.fillStyle = 'rgb(32,32,32)';
    c.fill();
    c.save(); 
    c.translate(this._boxGapX,this._boxGapY);

    var idxFastVU =  Math.ceil(this._fastVU*this._boxCount);
    if(idxFastVU < 0 ) {
      idxFastVU = 0 ;
    }

    // var local
    var  t0 = performance.now();
    for (var i = (this._boxCount-1); i > 0; i--) {
        var id = this.getId(i);

        c.beginPath();
        if (idxFastVU == id) {
          c.rect(0, 0,this._boxHeight,this._boxWidth);
          c.fillStyle = this.getBoxColor(idxFastVU, null, true);
          
        } else if(id < idxFastVU) {
          c.rect(0, 0,this._boxHeight,this._boxWidth);
          c.fillStyle = this.getBoxColor(id, val);
        }
        
        c.fill();
        c.translate(this._boxHeight+this._boxGapY, 0);
    }

    // Add top
    var  t1 = performance.now();
    // console.log(`Call to drawBoxes  boxcount loop  ${t1 - t0} milliseconds.`);
    c.restore();

  }

  // Get the color of a box given it's ID and the current value
  getBoxColor(id, val,fastVU=false){
    // Colours
    var redOn     = 'rgba(255,47,30,1)';
    var redOff    = 'rgba(64,12,8,1)';
    var yellowOn  = 'rgba(255,215,5,1)';
    var yellowOff = 'rgba(64,53,0,1)';
    var greenOn   = 'rgba(53,255,30,1)';
    var greenOff  = 'rgba(13,64,8,1)';

    // on colours

    if(fastVU == true) {

      if (id > this._boxCount - this._boxCountRed){
        return redOn;
      }
      if (id > this._boxCount - this._boxCountRed - this._boxCountYellow){
        return yellowOn;
      }
      return  greenOn;


    } else {
      
      if (id > this._boxCount - this._boxCountRed){
        return this.isOn(id, val)? redOn : redOff;
      }
      if (id > this._boxCount - this._boxCountRed - this._boxCountYellow){
        return this.isOn(id, val)? yellowOn : yellowOff;
      }
      return this.isOn(id, val)? greenOn : greenOff;

    }

  }

  getId(index){
      // The ids are flipped, so zero is at the top and
      // this._boxCount-1 is at the bottom. The values work
      // the other way around, so align them first to
      // make things easier to think about.
      return Math.abs(index - (this._boxCount - 1)) + 1;
  }

  isOn(id, val){
    // We need to scale the input value (0-max)
    // so that it fits into the number of boxes
    // var  t0 = performance.now();
    var maxOn = Math.ceil((val/this._max) * (this._boxCount+1));
    
    // var  t1 = performance.now();
    // console.log(`Call to isOn  ${t1 - t0} milliseconds.`);
    return (id <= maxOn);
  }

  canvasloop() {
    
    var thisLoop = new Date();
    var fps = 1000 / (thisLoop - this._lastLoop);
    this._lastLoop = thisLoop;
    //console.log('fps: ', fps);
    
    var  t0 = performance.now();
    this.update();
    var t1 = performance.now();
    // console.log(`Call to update took ${t1 - t0} milliseconds.`);
    // time : 0.03 


    var  t0 = performance.now();
    this.draw();
    var t1 = performance.now();
    // console.log(`Call to draw took ${t1 - t0} milliseconds.`);


    // helper.js:formatted:993 Call to draw -> curl took 0.004999998054699972 milliseconds.
    // helper.js:formatted:1031 Call to drawBoxes  boxcount loop  0.15500000154133886 milliseconds.
    // helper.js:formatted:1000 Call to drawBoxes pt2 took 1.1599999997997656 milliseconds.
    // helper.js:formatted:1095 Call to draw took 6.304999998974381 milliseconds.

    // helper.js:formatted:1089 Call to update took 0.014999997802078724 milliseconds.

    // helper.js:formatted:993 Call to draw -> curl took 0.005000001692678779 milliseconds.
    // helper.js:formatted:1031 Call to drawBoxes  boxcount loop  0.08499999967170879 milliseconds.
    // helper.js:formatted:1000 Call to drawBoxes pt2 took 0.2899999999499414 milliseconds.
    // helper.js:formatted:1095 Call to draw took 0.41000000055646524 milliseconds.

    // helper.js:formatted:1089 Call to update took 0.009999999747378752 milliseconds.

    // helper.js:formatted:993 Call to draw -> curl took 0.009999999747378752 milliseconds.
    // helper.js:formatted:1031 Call to drawBoxes  boxcount loop  0.17500000103609636 milliseconds.
    // helper.js:formatted:1000 Call to drawBoxes pt2 took 0.34500000037951395 milliseconds.
    // helper.js:formatted:1095 Call to draw took 0.5099999980302528 milliseconds.

    // helper.js?480f:527 Call to update took 0.019999999494757503 milliseconds.
    // helper.js?480f:527 Call to draw -> curl took 0.005000001692678779 milliseconds.
    // helper.js?480f:581 Call to drawBoxes  boxcount loop  0.14000000010128133 milliseconds.
    // helper.js?480f:534 Call to drawBoxes pt2 took 0.32500000088475645 milliseconds.
    // helper.js?480f:642 Call to draw took 0.43000000005122274 milliseconds.

    // helper.js?480f:635 Call to update took 0.035000000934815034 milliseconds.
    // helper.js?480f:527 Call to draw -> curl took 0.005000001692678779 milliseconds.
    // helper.js?480f:581 Call to drawBoxes  boxcount loop  0.8149999994202517 milliseconds.
    // helper.js?480f:534 Call to drawBoxes pt2 took 1.2849999984609894 milliseconds.
    // helper.js?480f:642 Call to draw took 1.649999998335261 milliseconds.

    // helper.js?480f:635 Call to update took 0.015000001440057531 milliseconds.
    // helper.js?480f:527 Call to draw -> curl took 0.005000001692678779 milliseconds.
    // helper.js?480f:581 Call to drawBoxes  boxcount loop  0.20000000222353265 milliseconds.
    // helper.js?480f:534 Call to drawBoxes pt2 took 0.40499999886378646 milliseconds.
    // helper.js?480f:642 Call to draw took 0.7949999999254942 milliseconds.

    // helper.js?480f:635 Call to update took 0.015000001440057531 milliseconds.
    // helper.js?480f:527 Call to draw -> curl took 0.005000001692678779 milliseconds.
    // helper.js?480f:581 Call to drawBoxes  boxcount loop  0.42500000199652277 milliseconds.
    // helper.js?480f:534 Call to drawBoxes pt2 took 0.6500000017695129 milliseconds.
    // helper.js?480f:642 Call to draw took 0.7699999987380579 milliseconds.

    // helper.js?480f:635 Call to update took 0.015000001440057531 milliseconds.
    // helper.js?480f:527 Call to draw -> curl took 0.004999998054699972 milliseconds.
    // helper.js?480f:581 Call to drawBoxes  boxcount loop  0.14000000010128133 milliseconds.
    // helper.js?480f:534 Call to drawBoxes pt2 took 0.2750000021478627 milliseconds.
    // helper.js?480f:642 Call to draw took 0.3499999984342139 milliseconds.

    // helper.js?480f:635 Call to update took 0.019999999494757503 milliseconds.
    // helper.js?480f:527 Call to draw -> curl took 0.004999998054699972 milliseconds.
    // helper.js?480f:581 Call to drawBoxes  boxcount loop  0.14000000010128133 milliseconds.
    // helper.js?480f:534 Call to drawBoxes pt2 took 0.2899999999499414 milliseconds.
    // helper.js?480f:642 Call to draw took 0.420000000303844 milliseconds.

    // helper.js?480f:635 Call to update took 0.014999997802078724 milliseconds.
    // helper.js?480f:527 Call to draw -> curl took 0 milliseconds.
    // helper.js?480f:581 Call to drawBoxes  boxcount loop  0.21500000002561137 milliseconds.
    // helper.js?480f:534 Call to drawBoxes pt2 took 0.3550000001268927 milliseconds.
    // helper.js?480f:642 Call to draw took 0.5149999997229315 milliseconds.

    // helper.js?480f:635 Call to update took 0.03999999898951501 milliseconds.
    // helper.js?480f:527 Call to draw -> curl took 0.009999999747378752 milliseconds.
    // helper.js?480f:581 Call to drawBoxes  boxcount loop  0.4399999997986015 milliseconds.
    // helper.js?480f:534 Call to drawBoxes pt2 took 0.7850000001781154 milliseconds.
    // helper.js?480f:642 Call to draw took 1.4350000019476283 milliseconds.


    setTimeout(this.canvasloop.bind(this), 100);
  }
}

export class rtpAudio extends GainNode {
  constructor(ctx, ws, msg = undefined, micstream = undefined) {
    super(ctx, {channelCount: 1});
    console.log('create rtpAudio: ', micstream);
    console.log('LITE: ', micstream ? '1':'0');
    this._ctx = ctx;
    this._ws = ws;
    this.onclose = null;
    this.onstate = null;

    // new rtp object
    if('turnpw' in this._ws.wss) {
        // console.error('use private stun');
        const iceServers = {
          iceServers: [
            //{ 'urls': 'stun:www.expertron.co.za:3478', username: 'expertron', credential: 'geelwortel' },
            //{ 'urls': 'turn:qaller.expertron.co.za:3478', username: 'expertron', credential: 'geelwortel' },
            { 'urls': 'turn:qaller.expertron.co.za:3478', username: this._ws.wss.wsid, credential: this._ws.wss.turnpw },
          ],
        };
        console.error('use private stun',iceServers);
        this._rtp = new RTCPeerConnection(iceServers);
      } else {
        console.error('use default stun');
        this._rtp = new RTCPeerConnection();
    }

    console.log('Created local peer connection object this._rtp');
    // before hooking up event handlers which may message, hook up 
    // _ws message handler
    this._ws.onrtpmessage = this._rxMessage.bind(this);
    // bind event handlers
    this._rtp.onicecandidate = this._onIce.bind(this);
    this._rtp.ontrack = this._onTrack.bind(this);
    this._rtp.onnegotiationneeded = this._onNegNeeded.bind(this);
    // state handlers
    this._rtp.oniceconnectionstatechange = this._onICStateChange.bind(this);
    this._rtp.onicegatheringstatechange = this._onIGStateChange.bind(this);
    this._rtp.onsignalingstatechange = this._onSigStateChange.bind(this);
    this._rtp.onconnectionstatechange = this._onConStateChange.bind(this);
    // bind incoming audio via super gain node
    if(micstream) {
      console.log('Received local micstream');
      micstream.getTracks().forEach(track => this._rtp.addTrack(track, micstream));
      console.log('Added Local micstream to peer connection');
      this._micstream = micstream;
      this._outgain = undefined;
    } else {
      // NB - STARTS MUTED!
      this._stream = ctx.createMediaStreamDestination();
      super.gain.value=0.0;
      super.connect(this._stream);
      console.log('Created local stream');
      this._stream.stream.getTracks().forEach(track => this._rtp.addTrack(track, this._stream.stream));
      console.log('Added Local Stream to peer connection');
      // add output gain node so others can connect to us...
      this._outgain = this._ctx.createGain();
      this._outgain.gain.value=0.0;
    }
    // trigger message processing, if required
    this._remotestart = 0;
    if(msg) {
      console.log("Starting with incoming message: ", msg);
      this._remotestart = Date.now();
      //this._rxMessage(msg);
    }
    this._directplay = false;
  }

  // gain management - only available when NOT in lite mode...
  getgain_i() {
    if(!this._outgain) { alert("Ignored attempt to user getgain_i in LITE mode."); return null;}
    return super.gain.value;
  }

  setgain_i(v) {
    if(!this._outgain) { alert("Ignored attempt to user setgain_i in LITE mode."); return;}
    super.gain.value = v;
  }

  getgain_o() {
    if(!this._outgain) { alert("Ignored attempt to user getgain_o in LITE mode."); return null;}
    return this._outgain.gain.value;
  }

  setgain_o(v) {
    if(!this._outgain) { alert("Ignored attempt to user setgain_o in LITE mode."); return;}
    this._outgain.gain.value = v;
  }

  directplay() {
    this._directplay = true;
    if(this._audio) {
      this._audio.muted = false;
    }
  }  
  // rtp event handlers
  
  // state handlers
  _onICStateChange(e) {
    console.log("IC STATE: " + this._rtp.iceConnectionState);
      // test internal hangup...
      //this.hangup();
      //return;
    if(this.onstate) {
      console.log("send state");
      this.onstate("ICE Connection: " + this._rtp.iceConnectionState);
    }
    if(this._rtp.iceConnectionState === "failed" || this._rtp.iceConnectionState === "closed") {
      console.log("IC FAIL STATE: " + this._rtp.iceConnectionState);
      if(this.onstate) {
        console.log("send state");
        this.onstate("ICE Connection FAILED");
      }
      this.hangup();
    }
  }
  
  _onIGStateChange(e) {
    console.log("IG STATE: " + this._rtp.iceGatheringState);
    if(this.onstate) {
      console.log("send state");
      this.onstate("ICE gathering: " + this._rtp.iceGatheringState);
    }
  }
  
  _onSigStateChange(e) {
    console.log("SIG STATE: " + this._rtp.signalingState);
    if(this.onstate) {
      console.log("send state");
      this.onstate("Signalling: " + this._rtp.signalingState);
    }
  }
  
  _onConStateChange(e) {
    console.log("CON STATE: " + this._rtp.connectionState);
    if(this.onstate) {
      console.log("send state");
      this.onstate("Connection: " + this._rtp.connectionState);
    }
    if(this._rtp.connectionState === "failed" || this._rtp.connectionState === "closed") {
      console.log("CONNECTION FAIL STATE: " + this._rtp.connectionState);
      if(this.onstate) {
        console.log("send state");
        this.onstate("Connection FAILED");
      }
      this.hangup();
    }
  }
  
  // message handlers
  _onNegNeeded(e) {
    console.log('NEGOTIATION NEEDED: ', e);
    console.log('THIS: ', this);
    if(this._remotestart) {
      if(Date.now() - this._remotestart < 5000) {
        console.log('started remotely - ignore first negotiation event!');
        this._remotestart = 0;
        return;
      } else {
        this._remotestart = 0;
      }
    }
    if(this._rtp.signalingState != "stable") {
      console.log("The connection isn't stable yet; postponing...")
      return;
    }
    this._rtp.createOffer().then(
      function(offer) {
        offer.sdp = this._mangleSDP(offer.sdp);
        console.log(`modified SDP: \n${offer.sdp}`);
        return this._rtp.setLocalDescription(offer);
      }.bind(this)
    ).then(
      function() {
        console.log("SEND OFFER");
        this._ws.rtpSend({cmd:'offer', data: this._rtp.localDescription});
      }.bind(this)
    ).catch(
      function(reason) {
        console.log(`Failed to create/send offer: ${reason.toString()}`);
      }
    );
  }

  _onTrack(e) {
    console.log("remote streams: ", e.streams);
    console.log("remote tracks: ", e.streams[0].getTracks());
    this._rtpstream = e.streams[0];
    
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1350777

    if(this._outgain) {
      this._outstream = this._ctx.createMediaStreamSource(this._rtpstream);
      this._outstream.connect(this._outgain);
    }
  
    this._audio = new Audio();
    this._audio.preload = "none";
    //this._audio.muted = (this._outgain && !this._directplay) ? true : false;
    // test - never mute, just turn volume down
    this._audio.muted = false;
    if(this._outgain && !this._directplay) {
      // where we would previously have muted, set the volume really low
      this._audio.volume = 0.0001;
    }
    this._audio.autoplay = true;
    this._audio.srcObject = this._rtpstream;
  }

  _onIce(event) {
    console.log(`SEND ICE candidate:\n${event.candidate ? event.candidate.candidate : '(null)'}`);
    this._ws.rtpSend({cmd:'ice', data: event.candidate});
  }

  // handle message received from the web service
  _rxMessage(msg) {
    console.log("msg: " + msg);
    console.log("cmd: ", msg.cmd);
    if(msg.cmd == 'offer') {
      console.log("offer: ", msg.data);
      this._rxOffer(msg.data);
    } else if(msg.cmd == 'answer') {
      console.log("answer: ", msg.data);
      this._rxAnswer(msg.data);
    } else if(msg.cmd == 'ice') {
      console.log("ice: ", msg.data);
      this._rxIce(msg.data);
    } else if(msg.cmd == 'localclose') {
      console.log("hangup: ");
      this.hangup();
    } else {
      console.log(msg.cmd + ": unhandled json message: ", msg);
    }
  }
  
  // rx message handlers
  _rxOffer(msg) {
    console.log("set received offer: ", msg);
    // no idea why the example does this - loses message type - maybe significant?
    let desc = new RTCSessionDescription(msg);
    // If the connection isn't stable yet, wait for it...
    // this was from an example, but many browsers do not support
    // submit anyway, and let browser deal with it...
    /*if(this._rtp.signalingState != "stable") {
      console.log("rxOffer, but connection not stable - roll back");
      this._rtp.setLocalDescription({type: "rollback"}).then(
        function() {
          this._rtp.setRemoteDescription(desc);
        }.bind(this)
      ).catch(
        function(reason) {
          console.log(`Failed to roll back: ${reason.toString()}`);
        }
      )
    }*/
    this._rtp.setRemoteDescription(desc).then(
      function() {
        return this._rtp.createAnswer();
      }.bind(this)
    ).then(
      function(answer) {
        answer.sdp = this._mangleSDP(answer.sdp);
        return this._rtp.setLocalDescription(answer);
      }.bind(this)
    ).then(
      function() {
        console.log("SENDING ANSWER");
        this._ws.rtpSend({cmd:'answer', data: this._rtp.localDescription});
      }.bind(this)
    ).catch(
      function(reason) {
        console.log(`Failed to set/answer offer: ${reason.toString()}`);
      }
    )
  }

  _rxAnswer(msg) {
    console.log("rx answer state: " + this._rtp.connectionState);
    let desc = new RTCSessionDescription(msg);
    this._rtp.setRemoteDescription(desc).then(
      function() {
        // nothing to do
      }.bind(this)
    ).catch(
      function(reason) {
        console.log(`Failed to set answer: ${reason.toString()}`);
      }
    )
  }

  _rxIce(c) {
    console.log(`RECEIVE ICE candidate:\n${c ? c.candidate : '(null)'}`);
    this._rtp.addIceCandidate(c).then(
      function () {
        console.log('AddIceCandidate success.');
      }.bind(this)
    ).catch(
      function (error) {
        console.log(`Failed to add ICE Candidate: ${error.toString()}`);
      }
    );
  }
  
  // connect/disconnect - redirect to outgain...
  connect(...args) {
    if(!this._outgain) { console.log("Ignored attempt to connect output in LITE mode."); return;}
    this._outgain.connect(...args);
  }
  
  disconnect(...args) {
    if(!this._outgain) { console.log("Ignored attempt to disconnect output in LITE mode."); return;}
    this._outgain.disconnect(...args);
  }


  // management functions
  hangup() {
    console.log('Ending call');
    // close rtp
    // unhook event handlers before closing...
    if(this._rtp) {
      this._rtp.ontrack = null;
      this._rtp.onicecandidate = null;
      this._rtp.onnegotiationneeded = null;
      this._rtp.oniceconnectionstatechange = null;
      this._rtp.onicegatheringstatechange = null;
      this._rtp.onsignalingstatechange = null;
      this._rtp.onconnectionstatechange = null;
      // Stop all transceivers on the connection
      //this._rtp.getTransceivers().forEach(transceiver => {
      //    transceiver.stop();
      //  }
      //);
      this._rtp.close();
      this._rtp = null;
    }
    // close inbound stream
    if(this._stream) {
      this._stream.stream.getTracks().forEach(track => track.stop());
      super.disconnect(this._stream);
      this._stream = null;
    }
    if(this._micstream) {
      // FIXME how do we disconnect it?
      this._micstream = null;
    }
    // close outbound stream
    if(this._audio) {
      this._audio.pause();
      this._audio.srcObject = null;
      this._audio = null;
    }
    if(this._outstream) {
      this._outstream.disconnect();
      this._outstream = null;
    }
    if(this._outgain) {
      this._outgain.disconnect();
      this._outgain = null;
    }
    // disconnect ws
    if(this._ws) {
      this._ws.onrtpmessage = null;
      this._ws = null;
    }
    // local cleanup
    console.log("trigger local cleanup? ", this.onclose?1:0);
    if(this.onclose) {
      this.onclose();
      this.onclose = null;
    }
  }
  
  _mangleSDP(sdp) {
    let s = new SDP(sdp);
    s.prefer('opus');
    //s.setfmtp('opus', 'rate=48000;maxplaybackrate=48000;sprop-maxcapturerate=48000;maxptime=20;ptime=20;maxaveragebitrate=128000;stereo=0;sprop-stereo=0;cbr=1;useinbandfec=1;usedtx=0');
    s.setfmtp('opus', 'rate=48000;maxplaybackrate=48000;sprop-maxcapturerate=48000;maxptime=20;ptime=20;maxaveragebitrate=256000;stereo=0;sprop-stereo=0;cbr=1;useinbandfec=1;usedtx=0');
    return s.sdp();
  }

}

export class recAudio extends GainNode {
  constructor(ctx,worker, Mp3MediaRecorder, user_name, start_recording_time, uuid=undefined) {
    super(ctx, {channelCount: 1});
    console.log('create recAudio ');

    this.cdate = new Date();
    this._ctx = ctx;
    this._chunks = [];
    this._combinationOfchunks = []; 
    this.onstop = null;
    this.ondownload = null;
    this.onstart = null;
    this.onpause = null;
    this.onresume= null;
    this._recording = false;
    this._worker = worker;
    this._user_name = user_name;
    this.uuid = uuid;
    this._Mp3MediaRecorder = Mp3MediaRecorder;
    this._paused = false;
    this._start_recording_time = this.cdate.getHours().toString().padStart(2, '0')+'_'+this.cdate.getMinutes().toString().padStart(2, '0')+'_'+this.cdate.getSeconds().toString().padStart(2, '0');
    this._blob_created = false;
    super.gain.value = 1.0; // default to 1:1 gain
    this._cm = ctx.createChannelMerger(2); // stereo to mono
    this._msd = ctx.createMediaStreamDestination(); // context to stream
    super.connect(this._cm); // connect input gain node to channel merger
    this._cm.connect(this._msd); // connect channel merger to media stream
    this._rec = new Mp3MediaRecorder(this._msd.stream,{worker: this._worker});
  }
  
  // parent methods
  start(clearblob=true,pause=false) {
    if(clearblob) {
      this._recording = false;
    }
    if(this._recording) { return; }
    this.recording_init(clearblob,pause);
    this._rec.start();
    this._recording = true;
    this._paused = false;
    this._blob_created = false;
  }

  stop() {
    console.log('recAudio stop');
    if(!this._recording) { return; }
    this._rec.stop();
    this._recording = false;
  }

  pause() {
    if(!this._recording) { return; }
    this._rec.pause();
  }

  resume() {
    console.log('resume rec: recording '+this._recording);
    if(!this._recording) { return; }
    this._rec.resume(); 
    //this._recording = true;
  }

  download() {
    console.log('download');

  }
  // connect/disconnect - redirect to super - can pass through this node, if required
  connect(...args) {
    super.connect(...args);
  }
  
  disconnect(...args) {
    super.disconnect(...args);
  }
  // 
  recording_init(clearblob=true,pause=false) {
    this._rec.ondataavailable = (evt) => {
      this._chunks.push(evt.data);
    };

    this._rec.onstop = (evt) => {
      var blob = new Blob(this._chunks, { type: 'audio/mpeg' }); // convert chunks to blob
      const mp3BlobUrl = URL.createObjectURL(blob);
      this.onstop(); // call parent onclose event with blob
      this.ondownload(mp3BlobUrl,this._user_name,this._start_recording_time,this.uuid); // Download recording. 
      this._recording = false; // reset recording flag
      this._blob_created = true;
    }
    
    this._rec.onstart = (e) => {
      if (clearblob) {
        this._chunks = [];
      }
      if (pause) {
         console.log('recRec: '+this._rec.state);
         var _this = this;
         setTimeout(() => {
            console.log('Pause after 1 sec');
            _this._rec.pause();   
         }, 0);                  
      }

      // FIXME: test if works
      //if (this.onstart) {
          this.onstart();
      //} 
      this._recording = true;
    };

    this._rec.onpause = (e) => {
      this.onpause();
      this._paused = true;
    };

    this._rec.onresume = (e) => {
       this.onresume();
       this._paused = false;
    };

    this._rec.onerror = (e) => {
      console.error('onerror', e);
    };

  }
  reset(clearblob) {
    if(this._rec) {
      delete this._rec;
      this._rec = new this._Mp3MediaRecorder(this._msd.stream,{worker: this._worker}); 
      //console.log('reset _start_recording_time: '+this._start_recording_time);
      if (clearblob) {
        this.cdate = new Date();
        this._start_recording_time = this.cdate.getHours().toString().padStart(2, '0')+'_'+this.cdate.getMinutes().toString().padStart(2, '0')+'_'+this.cdate.getSeconds().toString().padStart(2, '0');
      }
    }
  }
  getUserName() {
    return this._user_name;
  }
  getRecState() {
    if(this._rec) {
      return this._rec.state;
    }
  }
  getStartTime() {
    return this._start_recording_time
  }
  
  getUuid(){
    return this.uuid
  }

  terminateWorker(check_if_blob_exists=false) {
    if (!check_if_blob_exists) {
      this._worker.terminate();
    } else {
      // pole to check when the blob has been created.
      var _this = this;  
      var poll_blob = setInterval(
        function() {
          if (_this._blob_created) {
            _this._worker.terminate();
            clearInterval(poll_blob);
          }
      }, 1000);
    }
  }
}

// https://gist.github.com/alexciarlillo/4b9f75516f93c10d7b39282d10cd17bc
export class loAudio extends GainNode {
  constructor(ctx) {
    super(ctx, {channelCount: 1});
    console.log('create loAudio ');
    this._ctx = ctx;

    super.gain.value = 1.0; // default to 1:1 gain

    this._msd = ctx.createMediaStreamDestination(); // WebAudio to stream
    super.connect(this._msd); // connect input gain node to stream output
    // this._msd.stream is now a stream object outputting whatever is fed into this gain node
    
    // build loopback rtc connections
    this._rtcIn = new RTCPeerConnection();
    this._rtcOut = new RTCPeerConnection();
    
    // direct ice channel between the two
    this._rtcIn.onicecandidate = e => {
      e.candidate && this._rtcOut.addIceCandidate(new RTCIceCandidate(e.candidate));
    }
    this._rtcOut.onicecandidate = e => {
      e.candidate && this._rtcIn.addIceCandidate(new RTCIceCandidate(e.candidate));
    }
    
    // create audio output on _rtcOut
    this._rtcOut.ontrack = e => {
      this._audio = new Audio();
      this._audio.preload = "none";
      this._audio.muted = false;
      this._audio.volume = 1.0;
      this._audio.autoplay = true;
      this._audio.srcObject = e.streams[0];
    }
    
    // connect audio input to _rtcIn
    this._rtcIn.addStream(this._msd.stream);
    
    // fire up the loopback chain
    const offerOptions = {
      offerVideo: false,
      offerAudio: true,
      offerToReceiveAudio: false,
      offerToReceiveVideo: false,
    };
    async function rtpstart(t) {
      let offer = await t._rtcIn.createOffer(offerOptions);
      console.log('lo offer: ', offer);
      await t._rtcIn.setLocalDescription(offer);
      await t._rtcOut.setRemoteDescription(offer);
      let answer = await t._rtcOut.createAnswer();
      console.log('lo answer: ', answer);
      await t._rtcOut.setLocalDescription(answer);
      await t._rtcIn.setRemoteDescription(answer);
    }
    rtpstart(this).then(
      console.log('loopback chain complete')
    ).catch(
      (err) => console.log('loopback chain error: ', err)
    );
  }
  
  // connect/disconnect - redirect to super - can pass through this node, if required
  connect(...args) {
    super.connect(...args);
  }
  
  disconnect(...args) {
    super.disconnect(...args);
  }

}