<template>
   <div class="vertical_center" > 
      <div class=" alert about_message">
        <div>
            <img class="exit_logo" style="width: 30%;" src="../assets/images/qaller.png" />
        </div>
        <p>
            The free Trial Qaller account is provided without any guarantee or obligation.
            The purpose of the Trial Qaller account is to provide prospective users an opportunity
            to evaluate the Qaller service.
        </p> 
      </div>
   </div>
</template>

<script>
  
export default {
   name : 'about',
   props : {
      username: String,
      p_url: String 
   },
   mounted:function() {
   },
   data: function () {
      return {
         url : this.p_url,
      }
   },
   methods: {
      
   }
}
</script>

<style>
   .exit_logo { 
      margin: 10px auto 20px;
      display: block;
   }
   .about_message {
        font-size: larger;
        width: 50%;
        margin: 0 auto;
        max-width: 450px;
        background-color: white;
   }
   .vertical_center {
      min-height: 90vh;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
   }
</style>
