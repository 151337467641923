<template>
    <table style="width: 100%;">
        <tr class="invite " v-if=" typeof p_sent_invite['name'] == 'string'" >
            <td class="dname" title="Name">
                <span>{{p_sent_invite["name"]}}</span>
            </td>
            <td class="email" title="Email Address">
                {{(p_sent_invite["email"]) ? p_sent_invite["email"]: "Not provided"}}
            </td>
            <td class="cell_number" title="Cell Number">
                {{(p_sent_invite["cell_number"]) ? p_sent_invite["cell_number"]: "Not provided"}}
            </td>
            <td class="invite_detail" title="Invitation Details">
                <div class="cdate">
                    <table>
                        <tr>
                            <td>
                                Invite date:  
                            </td>
                            <td>
                                {{p_sent_invite["cdate"]}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Expiry date: 
                            </td>
                            <td>
                               {{p_sent_invite["expiry_date"]}}
                            </td>
                        </tr>

                    </table>
                </div>
            </td>
            <td class="resend_cancel_container">
                <div class="cancel" title="Click to cancel Invite"  @click="resend($event,'cancel')">
                    <img class="log_action" data-action="cancel_sent_invitation" src="@/assets/images/cancel_invite.svg"/>
                </div>
                <div class="resend" title="Click to resend Invite" @click="resend($event,'re_invite')">
                    <img class="log_action" data-action="resent_invitation" src="@/assets/images/resend.svg"/>
                </div>
            </td>
        </tr>
   </table>
</template>

<script>
    import EventBus from '../../eventBus';
    export default {
        name: 'Invite',
        props: {
            p_sent_invite: {},
            wss: {},
            uuid : String,
            name: String,
            sender: String,
            pool: String,
            cell_number: String,
            email_address: String,
            radio_station: String, 
            studiocaller: Object,

        },
        data: function () {
            return {
                url : 'https://'+process.env.VUE_APP_BASEURL+'/invite/', 
                message: 'Your '+process.env.VUE_APP_INSTANCE_NAME+' invitation has been extended. Click the link to intiate a call: ',
                email_subject: process.env.VUE_APP_INSTANCE_NAME+' Invitation',
                email_body: '',
                resent_show: false,
                info_message: ''
            }
        },
        mounted: function() {
            var _this = this; 
        },
        methods: {
            resend: function (e,action) {
                var _e = e;
                e.currentTarget.className += " active_glow";

                setTimeout(function(_e_currentTarget) {
                    _e_currentTarget.className = _e_currentTarget.className.replace("active_glow",'');
                }, 3000,_e.currentTarget);

                var eSubject =  this.email_subject;
                if(this.p_sent_invite["cell_number"]  &&  this.p_sent_invite["email"]) {
                    var link = this.url+this.uuid ;
                    var invite_message = '' ;
                    var act = 'resend_setsms_email';
                    if(action == 're_invite') {
                        this.email_body = '<p>Dear '+this.name+'</p><p>Your '+process.env.VUE_APP_INSTANCE_NAME+' invitation sent by '+this.sender+' from '+this.radio_station+' has been extended.<p>Your invitation will now expire on <b>__EXPIRY_DATE__</b>.</p>  Click the below link below when you are ready to initiate a call:</p><p><a href="'+link+'">'+link+'</a></p>__SIGNATURE__ ';
                        invite_message  = this.message+this.url+this.uuid;
                        this.info_message = "Resend Successfully";
                    } else if(action == 'cancel') {
                        eSubject = process.env.VUE_APP_INSTANCE_NAME+' invitation sent on '+this.p_sent_invite['cdate']+' has been cancelled ';
                        this.email_body = '<p>Dear '+this.name+'</p><p>Your '+process.env.VUE_APP_INSTANCE_NAME+' invitation with '+this.sender+' from '+this.radio_station+' has been cancelled</p>__SIGNATURE__ ';
                        invite_message = 'Your call with '+this.sender+' from '+this.radio_station+' which was scheduled on the <b>'+this.p_sent_invite['cdate']+'</b> has been cancelled';
                        this.info_message = "Cancelled Successfully";
                        act = 'cancel_setsms_email';
                    }
                    // this.wss.send({cmd:'db', data: {act: "setsms", data:{ msisdn: this.cell_number, message: invite_message,name: this.name, email:this.email_address, uuid:this.uuid, sender:this.sender,pool:this.pool}}});
                    this.wss.send({
                        cmd:'db', 
                        data: {
                        act: act, 
                        data: {
                           msisdn: this.cell_number,
                           message: invite_message,
                           email: this.email_address, 
                           subject:eSubject, 
                           HTML:this.email_body,
                           name: this.name,
                           uuid:this.uuid, 
                           sender:this.sender,
                           pool:this.pool
                        }
                        }
                    }); 
                } else {
                    if(this.p_sent_invite["cell_number"]) {
                        var invite_message = '' ; 
                        var act = 'resend_setsms';
                        if(action == 're_invite') {
                           invite_message  = this.message+this.url+this.uuid;
                           this.info_message = "Resend Successfully";
                        } else if(action == 'cancel') {
                           eSubject = process.env.VUE_APP_INSTANCE_NAME+' invitation cancelled';
                           invite_message = 'Your call with '+this.sender+' from '+this.radio_station+' which was scheduled on the <b>'+this.p_sent_invite['cdate']+'</b> has been cancelled';
                           this.info_message = "Cancelled Successfully";
                           act = 'cancel_setsms';
                        }

                        this.wss.send({cmd:'db', data: {
                           act: act, data:{ 
                           msisdn: this.p_sent_invite["cell_number"], 
                           message: invite_message,
                           name: this.name, 
                           email:'', 
                           uuid:this.p_sent_invite["uuid"], 
                           sender:this.p_sent_invite["sender"],
                           pool:this.p_sent_invite["pool"]
                        }}});

                    } else if(this.p_sent_invite["email"])  {
                        var link = this.url+this.p_sent_invite["uuid"];
                        var act = 'resend_setemail'; 
                        if(action == 're_invite') {
                            this.email_body = '<p>Dear '+this.name+'</p><p>Your '+process.env.VUE_APP_INSTANCE_NAME+' invitation sent by '+this.sender+' from '+this.radio_station+' has been extended.<p>Your invitation will now expire on <b>__EXPIRY_DATE__</b>.</p>  Click the below link below when you are ready to initiate a call:</p><p><a href="'+link+'">'+link+'</a></p>__SIGNATURE__ ';
                            this.info_message = "Resend Successfully";
                       } else if(action == 'cancel') {
                            eSubject = process.env.VUE_APP_INSTANCE_NAME+' invitation sent on '+this.p_sent_invite['cdate']+' has been cancelled ';
                            this.email_body = '<p>Dear '+this.name+'</p>'+
                            '<p>Your call with '+this.sender+' from '+this.radio_station+' which was scheduled on the <b>'+this.p_sent_invite['cdate']+'</b> has been cancelled</p>__SIGNATURE__ ';
                            this.info_message = "Cancelled Successfully";
                            act = 'cancel_setemail'; 
                        }
                        this.wss.send({
                            cmd:'db', 
                            data: {
                            act: act, 
                            data: {
                                email: this.p_sent_invite["email"], 
                                subject:eSubject, 
                                HTML:this.email_body,
                                name: this.name,
                                uuid:this.p_sent_invite["uuid"], 
                                sender:this.sender,
                                pool:this.pool
                            }
                            }
                        }); 
                    }
                } 
                // this.resent_show =true;
                // var _this = this; 
                // setTimeout(function(){
                //     _this.update_sent_invites();
                //     _this.resent_show=false;
                //     _this.info_message="";
                // }, 4000);
                
                EventBus.$emit('SEND_INVITE_FEEDBACK',this.name);

            },
            cancel: function () {
                
            },
            update_sent_invites() {
              this.wss.send({cmd:'db', data: {act: "fetchsent_invites", data: {sender: this.sender,pool:this.pool,radio_station: this.radio_station}}});
            },
            Check1(){
              if(this.resent_show == true) {
                  this.resent_show = false;
              } else {
                  this.resent_show = true;
              }
            },
        } 
    }
</script>


<style scoped>
.invite img {
    height:40px;
}
/*--old--*/
.dname,.cell_number,.email,.delivered,.invite_detail {
    /* float: left; */
    border-left: rgb(228, 230, 231);
    border-right: rgb(228, 230, 231);
    /* border-style: solid; */
    border-width: 0px 2px 0px 2px;
    margin-bottom: 0px;
    /* border: 1px solid rgba(0,0,0,.125); */
}

.dname {
    padding-left: 10px;
    width: auto; 
    position: inherit;
    /* padding: 1%; */
    /* font-weight: 550; */
}

.cell_number{
  position: inherit;
  width: 150px;
  /* padding: 1%; */
}

.email {
    width: 400px;
}
.delivered {
    width: 100px;
}

.invite_detail { 
    width: 270px;
}

.resend, .cancel {
    display: inline-block;
    margin-left : 5px;
    float: right;
}

 
.resend_cancel_container {
    width: 10%;
    white-space: nowrap;
}
 
.resend:hover,.cancel:hover {
    cursor: pointer;
}

 

.cdate {
    /* width: 75%; */
    float: left;
    display: block;
    margin: auto;
    white-space:nowrap;
}
 
*, ::after, ::before {
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.resent_invite_message{
    background-color: #00800063;
    /* color: honeydew; */
}

.circular_div {
    border-radius: 50%;
    width: 50px
}

.resend_cancel_container img {
    width: 54px;
    height: 54px;
}

.active_glow {
    border-radius: 26px;
    -webkit-animation: active_glow 1500ms infinite;
    -moz-animation: active_glow 1500ms infinite;
    -o-animation: active_glow 1500ms infinite;
    animation: active_glow 1500ms infinite;
}

@-webkit-keyframes active_glow {
    0% { background-color: white; -webkit-box-shadow: 0 0 3px white;   }
    50% { background-color: white; -webkit-box-shadow: 0 0 5px 4px white; }
    100% { background-color: white; -webkit-box-shadow: 0 0 3px white; }
}

@-moz-keyframes active_glow {
    0% { background-color: white; -webkit-box-shadow: 0 0 3px white;   }
    50% { background-color: white; -webkit-box-shadow: 0 0 5px 4px white; }
    100% { background-color: white; -webkit-box-shadow: 0 0 3px white; }
}

@-o-keyframes active_glow {
    0% { background-color: white; -webkit-box-shadow: 0 0 3px white;   }
    50% { background-color: white; -webkit-box-shadow: 0 0 5px 4px white; }
    100% { background-color: white; -webkit-box-shadow: 0 0 3px white; }
}

@keyframes active_glow{
    0% { background-color: white; -webkit-box-shadow: 0 0 3px white;   }
    50% { background-color: white; -webkit-box-shadow: 0 0 5px 4px white; }
    100% { background-color: white; -webkit-box-shadow: 0 0 3px white; }
}

</style>
