import Vue from "vue";
import VueRouter from "vue-router";
import VueHead from 'vue-head';
import Vuex from 'vuex';
import Vue2TouchEvents from 'vue2-touch-events';
// import Home from "../views/Home.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
// import Signup from "../views/Signup.vue";
import Host from  '../views/Host.vue';
import Logout from  '../views/Logout.vue';
import About from  '../views/About.vue';

import Contacts from  '../components/Contacts.vue';
import Messages from  '../components/Messages.vue';
// import Customer from  '../components/HelloWorld.vue';

// import BootstrapVue from 'bootstrap-vue'

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// import fullscreen from 'vue-fullscreen';
import VueCordova from 'vue-cordova' ; 

Vue.use(VueRouter);
Vue.use(VueHead);
// Vue.use(BootstrapVue);
Vue.use(Vuex);
Vue.use(Vue2TouchEvents);
// Vue.use(fullscreen);
Vue.use(VueCordova);

console.log(Vue.cordova);
// alert(Vue.cordova) ;

const routes = [
   {
      path: "/",
      name: "Login",
      component: Login,
      props: true,
   },
   {
      path: "/home",
      name: "Home",
      component: Home,
      props: true,
   },
   {
      path: "/contact",
      name: "Contacts",
      component: Contacts,
      props: true
   },
   {
      path: "/messages",
      name: "Messages",
      component: Messages
   },
   // {
   //    path: "/home",
   //    name: "Customer",
   //    component: Customer
   // },
   {
      path: "/host",
      name: "Host",
      component: Host,
      props:true
   },
   // {
   //    path: "/signup",
   //    name: "Signup",
   //    component: Signup
   // },
   {
      path: "/logout",
      name: "Logout",
      component: Logout,
      props: true
   },
   {
      path: "/about",
      name: "About",
      component: About,
      props: true
   },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
