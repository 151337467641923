<template>
   <div class="vertical-center " > 
      <div class="container ">
         <div class="card login" >
            <div class="card-body">
               <div class="name">
<!--                 <img  :src="image" /> -->
<!--                <h2>Qaller</h2> -->
<!--                 Quality Radio Caller App  -->
                <img class="logo" src="../assets/images/qaller.png" />
                
               <!-- <p class="talk">Talk</p> -->
                  <!-- Last Update : <br> 08-09-2020 08:35 AM -->
               </div>
                <div v-if="browser_message" class="browser_danger alert alert-danger">{{browser_message}}</div>
               <div v-if="!expired_invite">
                  <div class="form-group">
                     <p v-if="errorText" class="text-danger">{{errorText}}</p>
                  </div>

                  <div  class="alert alert-primary text-left" v-if="this.$route.query.uuid"  >
                     <div class="welcome_invitee">
                        <span>Welcome <b>{{invitee_name}}</b></span> <br>
                        <b>{{sender}}</b> has invited you to a discussion, 
                        Click below to join the discussion
                        "
                     </div>
                  </div>
                  <div class="alert alert-info text-left" v-if="this.$route.query.uuid">
                     <b>Prerequisite:</b>
                        <ul>
                           <li>Your browser will ask you to allow the use of your microphone. You MUST allow this.</li>
                        </ul>
                     <hr>
                     <!-- <div :class="[show_notification ? 'visible' : 'invisible']">
                        <b>Please note the guest login procedure has changed: </b> 
                        <ul>
                           <li>Previously clicking the button below initiated the call.
                           <li>Now clicking the button only logs you in after which the Presenter will call you.</li>
                        </ul>
                     </div> -->
                  </div>
                  <div class="alert alert-info text-left" v-else>
                      <b>Prerequisite:</b>
                        <ul>
                         <li>Your browser will ask you to allow the use of your microphone. You MUST allow this.</li>
                        </ul>
                     <hr>
                     <div :class="[show_notification ? 'visible' : 'invisible']">
                        <b>Join the Qaller Community!</b><br>
                        <p>
                           Set up live or recorded radio interviews
                           with a free Trial Qaller account.
                           Simply use your Google account to log into Qaller
                        </p> 
                        <!-- <b>Please note that the Guest login procedure has changed:</b>
                        <ul>
                           <li>Previously the Guest initiated the call.</li>
                           <li>Now the Guest only logs in. The Guest name will appear in the "Waiting" list.</li>
                           <li>Please call the Guest as soon as you are ready to set up the call.</li>
                        </ul> -->
                     </div>
                  </div>
                  <div class="alert alert-danger" v-if="error_incorrect_username_password">
                     Incorrect <strong>Username</strong> or <strong>Password</strong>.
                  </div>
                  <div v-if="!sign_in_using_username_password">
                     <button class="btn btn-primary login_google" v-show='!errorText' v-if="!this.$route.query.uuid" :disabled='!isInit' @click="google_sign_in" ref="submitBtn">
                        <div class="spinner-border spinner_border" role="status" v-if="!isInit">
                           <span class="sr-only">Loading...</span>
                        </div>
                        
                        Login using Google</button>
                     <button class="btn btn-primary login_username_password" v-show='!errorText' v-if="!this.$route.query.uuid" @click="sign_in_username_password" ref="submitBtn">Login with username and password</button>
                     <button 
                        class="btn btn-primary w-100 join_discussion" 
                        v-show='!errorText' 
                        v-if="this.$route.query.uuid" 
                        @click="initiate_call"
                        title="Click to log in, then wait to be called." 
                     >Join Discussion
                     </button>
                  </div>
                  <div style="text-align: justify;" v-else>
                     <div class="username_password_login">
                        <label style="display: block;" for="username">Username:</label>
                        <textarea autocomplete="false" rows="1" cols="20" v-on:keyup.enter="login_username_password" ref="username" id="username" name="username"></textarea>
                     </div>
                     <div>
                        <label for="pass">Password (6 characters minimum):</label>
                        <input v-on:keyup.enter="login_username_password" type="password" id="pass" ref="password" name="password" minlength="6" required>
                     </div>
                     <button class="btn btn-primary" v-show='!errorText' v-if="!this.$route.query.uuid" @click="login_username_password" >Login</button>
                     <button class="btn btn-warning" v-show='!errorText' v-if="!this.$route.query.uuid" @click="go_back" >Go back</button>
                  </div>
               </div>
               <div class="alert alert-danger" v-else>
                  Welcome <b>{{invitee_name}}</b>,
                  Your invitation from <b>{{sender}}</b> expired on the '{{expiry_date}}'
               </div>
               <div>
                  <router-link to="/about">Terms & Conditions</router-link>
               </div>
            </div>
         </div>
      </div>
      <div class="debug_state" style="position: absolute; bottom: -800px; display:none" ref="debug_state">
         Debugger
         <br> 
      </div>
      <!-- <button class="myBtn" data-action="test" @click="Check1">
         Test
      </button> -->
   </div>
</template>

<script>
   import image from "../assets/images/logo.png";
   import {isMobile} from 'mobile-device-detect';
   import {micSource, vuVis , Ring, recAudio, loAudio} from '../../public/js/helper.js';
   import {wsSock} from '../../public/js/comms.js';
   import json_errorText from './../assets/JSON/errorText.json';
   import {BroadcastChannel} from 'broadcast-channel';
   import { v4 as uuidv4 } from 'uuid';
   import {detect}  from 'detect-browser'
   //import axios from 'axios';
   import webAudioPeakMeter from '../../public/js/web-audio-peak-meter/src/index.js';
   import publicIp  from 'public-ip';

export default {
   name : 'Login',
   props : {
      errText: String,
   },
   data() {
      return {
         name: "" ,
         errorText:null,
         image : image,
         ctx : null, // Interface for representing an audio-processing graph built from audio modules linked together, each represented by an AudioNode
         mic: null , 
         got_mic : null , 
         micvis: null ,
         ring : null, 
         wss : null,
         peers: {},
         errorTranslation: json_errorText,
         date : null, 
         count : 0,
         channel :  new BroadcastChannel('foobar'),
         islogout : false,
         is_accepted: null,
         hav_invitee_detail: null,
         change_component: null,
         invitee_name : null, 
         sender : null,
         uuid: '',
         pool: '',
         email: '',
         user_info: '',
         url: '', 
         invitee_detail: '',
         call_tone: false,
         refresh: false, 
         logged_in_date_time: null,
         expired_invite: false,
         invitee_uuid: {},
         sent_invites: {},
         radio_station: {},
         isDisabled: true,
         lastupdate: null,
         sign_in_using_username_password: false,
         browser_message: '',
         error_incorrect_username_password: false,
         user_role: '',
         isInit: false,
         isSignIn: false,
         webAPM: webAudioPeakMeter,
         meterNode:null,
         session:null,
         browser:null,
         ip_address:null,
         show_notification: true,
         presenter_using_chrome_browser: false,
         presenter:false
      }
   },
   beforeUpdate() {
      // this.globalHelper();
   },
   beforeMount() {
      this.browser = detect();

      this.$axios.get('https://'+process.env.VUE_APP_BASEURL+'/backend.php?action=get_version')
      .then(function (response) {
         var server_version = response.data.result;
         var app_version = localStorage.getItem('localstorage');
         if(app_version != server_version) {
            localStorage.setItem('localstorage',server_version);
            window.location.reload();
         }
      });

      // document.addEventListener('click', this.resumeAudioContext);
      // console.log('Before Mount ctx: ',this.ctx);

      if(!this.$route.query.uuid) {
      } else {
         this.invitee_check();
      }
      this.date = new Date();

   },
   mounted:function() {
      var _this = this; 
      // Create session client side to aviod waiting for server to provide a session. 
      _this.session = uuidv4(); 
      //alert(_this.session);
      if(localStorage.errorText) {
         this.errorText= localStorage.errorText ;
         window.localStorage.removeItem('errorText');
      } 

      window.onpopstate = function(event) {
         location.reload();
      };

      this.browser = detect();
      var presenter = false;
      if (this.$route.query.uuid) {
         presenter = false; 
      } else {
         presenter = true;
      }

      this.presenter =  presenter;
      
      switch (this.browser && this.browser.name) {
      case 'chrome':
         if(this.browser.version > "68" && presenter) {
            // this.browser_message = 'Newer versions of Chrome seem to have an echo-cancellation bug. Please use Firefox when logging in as a Presenter if you experience any echo issues.';
            //window.mydestination = new loAudio(this.ctx);
            //console.log('window.mydestination: ',window.mydestination);
         }
         break;
      case 'firefox':
         break;
      case 'edge-chromium':
         if(presenter) {
            // this.browser_message = 'Newer versions of Microsoft Edge seem to have an echo-cancellation bug. Please use Firefox when logging in as a Presenter if you experience any echo issues.';
         } 
         break;
      case 'safari':
         // App.vue handeds 
         // if (presenter &&  this.browser.os == "Mac OS" && parseInt(browser.version) < 14) {
         //    this.browser_message = "Qaller uses the WebRTC (Web Real-Time Communication) protocol standard. Safari does not yet support WebRTC.  Please use Firefox on MacOS when logging in as a Presenter. ";
         // } else if (!presenter &&  this.browser.os == "Mac OS" && parseInt(browser.version) < 14) {
         //    this.browser_message = "Qaller uses the WebRTC (Web Real-Time Communication) protocol standard. Safari does not yet support the WebRTC. Please use Firefox or Chrome on MacOS when accepting this invitation."
         // }
         break;
      default:
         console.log('not supported');
      }

      if (this.browser.os == "iOS" && parseInt(this.browser.version) < 14) {
         this.browser_message = 'Qaller uses the WebRTC Real Time Communication protocol standard. iOS does not yet support WebRTC. Therefore Qaller does not yet work on iOS.';
      }

      if (presenter &&  this.browser.os == "Mac OS" && parseInt(this.browser.version) < 14) {
         this.browser_message = "Qaller uses the WebRTC (Web Real-Time Communication) protocol standard. Safari does not yet support WebRTC.  Please use Firefox on MacOS when logging in as a Presenter. ";
      } else if (!presenter &&  this.browser.os == "Mac OS" && parseInt(this.browser.version) < 14) {
         this.browser_message = "Qaller uses the WebRTC (Web Real-Time Communication) protocol standard. Safari does not yet support the WebRTC. Please use Firefox or Chrome on MacOS when accepting this invitation."
      }

      // if (this.browser.os == "iPadOS") {
      //    this.browser_message = 'Qaller uses the WebRTC Real Time Communication protocol standard. iOS does not yet support WebRTC. Therefore Qaller does not yet work on iOS.';
      //    return;
      // }

      console.log('this.browser_message: '+this.browser_message);
      console.log('Uuid: '+uuidv4());

      let that = this
      let checkGauthLoad = setInterval(function(){
         that.isInit = that.$gAuth.isInit
         that.isSignIn = that.$gAuth.isAuthorized
         if(that.isInit) clearInterval(checkGauthLoad)
      }, 1000);

      // Make the notification blink 
      var blink_number_of_times = 0 ;
      let blink_notification = setInterval(function() {
         blink_number_of_times++;
         _this.show_notification = !_this.show_notification;
         if(blink_number_of_times  ==  4 ) {
            //always show the notification text
            _this.show_notification = true;
            clearInterval(blink_notification) 
         }
      }, 1000);

   },
   methods: {
      print_debug(text) {
         this.p_date = new Date(); 
         this.count+=1 ;
         this.wss.send({cmd:'log', data: this.p_date +': ' + text}); 

         if(this.$refs['debug_state']) {
            this.$refs['debug_state'].innerHTML +=  this.p_date +': ' + text +"<br/>" ;
            if(this.count > 100 ) {
               this.$refs['debug_state'].removeChild(this.$refs['debug_state'].childNodes[0]);
               this.$refs['debug_state'].removeChild(this.$refs['debug_state'].childNodes[0]);
            }
         }
      },
      initialize_function () {
            this.wss.open();
            this.errorText = this.errText;
            this.wss.onreject = (call, id) => {
               if(call == 'setid') {
                  this.errorText = "The name '"+id+"' is currently in use. Please enter a different name" ;
                  this.feedbackfromWS = 'reject' ;
               }
            };
            this.wss.onalert = (t) => {
               this.print_debug(t) ;
               var err_text = 'wss : '+t; 
               if ('wsSock: Comms heartbeat fail...' == t) {
                  this.errorText = 'Oops!! Unable to connect to the Webserver. This could be a result of your internet connection strength or because of your firewall (if you behind one).'
               } 
            }

            this.wss.onstate = (m) => {
               this.print_debug(m);
            }
      },
      configure_wss: function(token = null,pool = null) {
         var _this = this; 
         _this.$axios.get('https://'+process.env.VUE_APP_BASEURL+'/backend.php', {
            params: {
               action: 'session',
               uuid: token,
               pool: pool,
               browser: _this.browser.name, 
               browser_version: _this.browser.version, 
               device: (isMobile ? 'Mobile' : 'Computer/Laptop'),
               session: _this.session  
            }
         }).then(function (response) {
            console.log('response: ',response);
            // FIXME: Might want to do something if there is an error. 
            if(!_this.session) {
               console.error('No Session returned by the server'); 
            }
         });

         if(pool){
            this.pool = pool;
         } else {
            this.pool =  uuidv4();
         }
         
         this.uuid = token;
         
         if(token) {
            this.wss  = new wsSock(this.pool,token);
         }else {
            this.wss  = new wsSock(this.pool);
         } 

         this.initialize_function();

         if(this.$route.query.uuid) {
            this.wss.invitee_detail = this.invitee_detail;
         } 

         this.wss.onrefresh = (id) => {
            this.refresh = true;
         };

         this.change_component = (id) => {
               console.log('this.is_accepted: ' + this.is_accepted);
               console.log('this.mic: ', this.got_mic);
               console.log('this.call_tone: ' + this.call_tone);
               console.log('query.uuid: ' + this.$route.query.uuid);
         }

         this.wss.onaccept = (call, data) => {
            if(call == 'setid') {

               // Wait for ring tone.
               var _this = this; 
               var pole_ring_tone_status = setTimeout(function() {
                  if(!_this.isDisabled && _this.got_mic) {
                     clearInterval(pole_ring_tone_status);
                     if(!_this.invitee_detail) {
                        _this.$router.push({
                           name : 'Host',
                           params : {
                              wss : _this.wss, 
                              username : _this.name, 
                              studiocaller : _this, 
                              isMobile : isMobile, 
                              mic : _this.mic, 
                              ctx : _this.ctx,
                              date : _this.date,
                              recAudio: recAudio,
                              uuid: _this.uuid
                           },
                        }).catch(err => {})
                     } else {
                        _this.$router.push({
                           name : 'Home',
                           params : {
                              wss : _this.wss, 
                              username : _this.name, 
                              studiocaller : _this, 
                              isMobile : isMobile, 
                              mic : _this.mic, 
                              ctx : _this.ctx,
                              date : _this.date
                           },
                        }).catch(err => {})
                     }
                  }
               },1000);
            }
         };
         this.wss.onclose = () => {
            //alert('Close');
            if(!this.islogout  && !this.wss.logout) { return;}
               
            this.ring.stop();
            if(typeof this.wss.invitee_detail != 'undefined') {
               var hostname = location.hostname;
               if (hostname == 'localhost') {
                  hostname = hostname+':8181'
               } else if (hostname == '192.168.1.16') {
                  hostname = hostname+':8181'
               }
               var url = 'https://'+hostname+'/?uuid='+this.uuid;

               // FIXME: Remove microphone connection on the about page.  
               // navigator.mediaDevices
               //       .getUserMedia({ audio: { echoCancellation: true, noiseSuppression: true, autoGainControl: true } })
               //       .then(
               //          (stream) => {
               //             stream.getTracks().forEach((track) => track.stop());
               //          }
               // )

               // this.webAPM =null;
               // this.mic = null;
               // this.meterNode = null; 
               // this.ctx= null;

               // alert('Here'); 
               this.$router.push({
                  name : 'Logout',
                  params : {
                     username : this.name, 
                     p_url: url
                  },
               }).catch((err)=>{
                  // Ignore the vuex err regarding  navigating to the page they are already on.
                  if (
                     err.name !== 'NavigationDuplicated' &&
                     !err.message.includes('Avoided redundant navigation to current location')
                  ) {
                     // But print any other errors to the console
                     logError(err);
                  }
               });
               return;
            } 

            this.wss.send({cmd:'log', data: this.date+' user: '+this.name+' -> : Login Comp. :  onclose callback function'});
            this.name = "" ;
            document.location.reload(true);

         };
         this.wss.onopen = () => {
            this.wss.send({cmd:'db', data: {act: "set_users", data: {uuid:this.uuid, pool:this.pool, name: this.name, email:this.email}}});
            // ; 
            // If Presenter/Producer
            if(!this.invitee_detail) {
               // alert('SEND');
               this.wss.send({cmd:'db', data: {act: "fetchsent_invites", data: {sender: this.name, pool:this.pool, radio_station:this.radio_station}}})
            }
         };

         this.wss.onrefresh = (id) => {
            if(!this.invitee_detail) {
               this.$router.push({
                  name : 'Host',
                  params : {
                     wss : this.wss, 
                     username : this.name, 
                     studiocaller : this, 
                     isMobile : isMobile, 
                     mic : this.mic, 
                     ctx : this.ctx,
                     date : this.date,
                     recAudio: recAudio,
                     uuid: this.uuid
                  },
               }).catch(err => {})
            } else {
               this.$router.push({
                  name : 'Home',
                  params : {
                     wss : this.wss, 
                     username : this.name, 
                     studiocaller : this, 
                     isMobile : isMobile, 
                     mic : this.mic, 
                     ctx : this.ctx,
                     date : this.date
                  },
               }).catch(err => {})
            }
         };

         this.wss.onaction = (act, id, data) => {
            console.log('act: '+act+' id '+id);
            console.log('onaction_data: '+data);

            if(act =='app' && typeof data == 'undefined') {
               return; 
            }

            this.session_event_log({
               session: this.session,
               action: (act =='app' && (data) && typeof data.cmd != 'undefined' ? data.cmd :act),
               pool: this.pool, 
               uuid: this.uuid, 
               action_data: {'user': id, 'data' :data} 
            });
         }
      },
      // loginTest: function () {
      //    // const { username, password } = this
      //    var usernametest = "Pierre";
      //    var passwordTest = "password";
      //    this.$store.dispatch(AUTH_REQUEST, { usernametest, passwordTest }).then(() => {
      //    this.$router.push('/')
      //    })
      // },
      loadRingTone : function () {
         if(!this.ring) {
            this.ring = new Ring(this.ctx);
            this.ring.onload = (e) => {
               if(e == "ring3" ) {
                  this.isDisabled = false;
                  // this.change_component('ring');
               }
               console.log('LOADED AUDIO: ', e);
            };
            this.ring.load('ring3', '../sounds/ring3.u16');
            this.ring.load('waiting_tone', '../sounds/waiting_tone.u16');
         }
      },
      login() {
         // this.initialize_function();
         this.name = this.name.trim();
         this.setID(this.name);
         if(!this.errorText) {
            console.log('this.errorText:');
            if(isMobile) {
               // alert('Lite'); 
               // this.wss.send({cmd:'log', data: 'user: '+this.name+' -> Mobile device detected (using lite mode)'});
               this.setid(1);
            } else {
               // alert('Full');
               // this.wss.send({cmd:'log',data:'user: '+this.name+' -> Non-mobile device detected'});
               this.setid(0);
            }
         } else {
            // this.errorText = "Please enter a name" 
         }
      },
      initiate_call() {
         this.intiate_ctx();
         this.createMic(false);
         this.login();
      },
      go_back() {
         this.sign_in_using_username_password = false;   
         this.error_incorrect_username_password = false;
      },
      login_username_password() {
         this.intiate_ctx(); 
         var username = this.$refs['username'].value.trim();
         var password = this.$refs['password'].value.trim();
         var _this = this;
         this.error_incorrect_username_password = false;
         if (username && password) {
            this.$axios.get('https://'+process.env.VUE_APP_BASEURL+'/backend.php?action=fetch_registered_users&username='+username+'&password='+password).then(function (response) {
               console.log('response: ',response);
                  var result = response.data.result;
                  console.log('result.length: ',Object.keys(result).length);
                  if (Object.keys(result).length) {
                     _this.name = result.name;
                     _this.uuid = result.uuid;
                     _this.pool = result.pool;
                     _this.radio_station = result.radio_station;
                     console.log('createMic:');
                     _this.createMic(); 
                     console.log('result: ',result);
                  } else {
                     _this.error_incorrect_username_password = true;
                  }
            });
         } else {
               _this.error_incorrect_username_password = true;
         }  
      },
      setID(name) {
         // return this.wss.setid(name);
      },
      open() {
         console.log('app open');
         this.wss.open();
      },
      logout() {
         console.log('logout...');
         this.islogout = true;
         localStorage.clear();
         this.wss.send({cmd:'logout'});
      },
      setid(lite) {
         console.log('app setid: ' + lite);
      },
      createMic(login_automatically=true) {

         if(!this.mic) {
            console.log('ctx->', this.ctx);
            this.mic = new micSource(this.ctx);

            console.log('mic->', this.mic);
            
            var _this = this; 

            this.errorText = "A Microphone Permission Dialog Requires Your Attention. Click 'Allow' to give this webpage access to your Microphone. If no dialog appears please change your browser's setting to allow the use of your microphone.";
 
            // alert('onopentest1');
            this.mic.onopen = () => {
               this.errorText = '';
               if(this.uuid && this.pool) {
                  this.configure_wss(this.uuid,this.pool);
               }
               //if(isMobile || this.user_role == 'invitee') {
               if(isMobile) {
                  this.wss.setaudio(this.ctx, this.mic._stream);
                  //alert('Yes'); 
               } else {
                  this.wss.setaudio(this.ctx);
               }
               this.got_mic = true;
               if(login_automatically) {
                  this.login();
               }
            }
         }
      },
      create_canvas(micCanvas) {
         if(!this.micvis) {

            this.meterNode = this.webAPM.createMeterNode(this.mic, this.ctx,);
            this.webAPM.createMeter(micCanvas, this.meterNode, {});
         }
      },
      clearError() {
         this.errorText = '';
      },
      invitee_check() {
         this.errorText = "";
         if(this.$route.query.uuid) {
            if(this.$route.query.uuid.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i)) {
               console.log('uuid'+ this.$route.query.uuid);
               var _this = this;
               _this.$axios.get('https://'+process.env.VUE_APP_BASEURL+'/backend.php?action=db_fetch&invitee_details='+this.$route.query.uuid)
                     .then(function (response) {
                     _this.user_role = 'invitee';
                     var user_details = response.data.result[0]
                     _this.invitee_detail = response.data.result[0];
                     if(user_details) {
                        _this.url = window.location.href;
                        _this.name = user_details.name;
                        _this.invitee_name = _this.name ; 
                        _this.sender = user_details.sender;
                        _this.email = user_details.email;
                        _this.expiry_date = user_details.expiry_date;
                        _this.logged_in_date_time = new Date();
                        _this.uuid = user_details.uuid;
                        _this.pool = user_details.pool;
                        _this.radio_station = user_details.radio_station;
                        if(user_details.expired_invite == '1') {
                           _this.errorText = "Your invitation expired on the '"+user_details.expiry_date+"'. "; 
                           _this.expired_invite = true;
                           return
                        }
                     }
               });
            }
         }
      },
      login_test() {
         // console.log('login_test');
         // console.log(localStorage.name);
         // this.setID(localStorage.name);
      },
      sign_in_username_password () {
         this.sign_in_using_username_password = true; 
      },
      intiate_ctx () {
         if(!this.ctx) {
            console.log('create ctx: ',this.ctx);
            this.ctx = new (window.AudioContext || window.webkitAudioContext);
         }

         //load ringtone
         this.loadRingTone();

         console.log('Browser name: '+this.browser.name);

         if(
            (
               this.browser.version > "68" && 
               this.presenter && 
               this.browser && 
               this.browser.name == 'chrome'
            ) || 
            (
               this.browser.name == 'edge-chromium'
            )
         ) {
            //this.browser_message = 'Newer versions of Chrome seem to have an echo-cancellation bug. Please use Firefox when logging in as a Presenter if you experience any echo issues.';
            window.mydestination = new loAudio(this.ctx);
         }
         document.addEventListener('click', this.resumeAudioContext);
         console.log('Before Mount ctx: ',this.ctx);
      },
      google_sign_in() {
         this.intiate_ctx();
         console.log('google_sign_in:');
         var _this = this;
         
         this.$gAuth.signIn(function (user) {
            var access_token = user[Object.keys(user)[1]].access_token;
            _this.$axios.get('https://www.googleapis.com/oauth2/v1/userinfo?access_token='+access_token)
                  .then(function (response_google) {
                     _this.user_info =  response_google;
                     _this.$axios.get('https://'+process.env.VUE_APP_BASEURL+'/backend.php',{
                        params: {
                           action            : 'db_fetch',
                           registered_users  : _this.user_info.data.email,
                           data              : _this.user_info.data,
                           pool              : uuidv4(), // used to create pool
                           uuid              : uuidv4(), // used as uuid
                        }
                     })
                     .then(function (response) {
                        _this.user_role = 'presenter';
                        // console.log('response.request: ',JSON.parse(response.request.response));
                        var result = JSON.parse(response.request.response);
                        _this.email = _this.user_info.data.email;
                        if (result.result[0]) {
                           console.log('email:',_this.email);
                           if(result.result[0].email == _this.email) {
                              _this.name = result.result[0].name;
                              _this.uuid = result.result[0].uuid;
                              _this.pool = result.result[0].pool;
                              _this.radio_station = result.result[0].radio_station;
                              console.log('createMic:');
                              _this.createMic();
                           } 
                        } else {
                           _this.errorText = "This google account is not registered on the system"
                        }
                     });
                     
                     // _this.$axios.get('https://'+process.env.VUE_APP_BASEURL+'/backend.php?action=db_fetch&registered_users='+_this.user_info.data.email)
                     // .then(function (response) {
                     //    _this.user_role = 'presenter';
                     //    var result = JSON.parse(response.request.response);
                     //    _this.email = _this.user_info.data.email;
                     //    if (result.result[0]) {
                     //       console.log('email:',_this.email);
                     //       if(result.result[0].email == _this.email) {
                     //          _this.name = result.result[0].name;
                     //          _this.uuid = result.result[0].uuid;
                     //          _this.pool = result.result[0].pool;
                     //          _this.radio_station = result.result[0].radio_station;
                     //          console.log('createMic:');
                     //          _this.createMic();
                     //       } 
                     //    } else {
                     //       _this.errorText = "This google account is not registered on the system"
                     //    }
                     // });

            }).catch(error => {
               console.log('error1: ',error);
            });
         }, function (error) {
            // console.log('googleerror',error)
            //on fail do something
            console.log('error2: ',error);
            return error;
         }).catch(function (err) {
            // This will fix your error since you are now handling the error thrown by your first catch block
            console.log('gAuth signIn error: ',err.message)
         });

         
         //  this.createMic();
      },
      google_signout(){
         this.$gAuth.signOut(function () {
             // things to do when sign-out succeeds
             console.log('Sign-out');
         }, function (error) {
            // things to do when sign-out fails
         })
      },
      Check1() {
         // console.log('Test Date',this.expiry_date);
      },
      submit: function() {
         console.log('SUBMIT');
      },
      resumeAudioContext: function () {
         console.log('ctx state: ',this.ctx.state);
         if(this.ctx && this.ctx.state !='running') {
            console.log('ctx state: ',this.ctx.state);
            this.ctx.resume();
            document.removeEventListener('click', this.resumeAudioContext);            
         } else if (this.ctx.state =='running') {
            document.removeEventListener('click', this.resumeAudioContext);
         }
         // console.log('resumeAudioContext this.ctx2 : ',this.ctx);
      }
   },
   updated: function () {
      this.$nextTick(function () {
      })
   },
}
</script>

<style>
   .login {
      max-width   : 450px; 
      margin      : 50px;
      display     : block; 
      margin-left : auto; 
      margin-right: auto ; 
   }
   .vertical-center {
      min-height: 100%; 
      min-height: 100vh;
      display: flex ;
      align-items: center;
   }
   .name {
      margin-bottom: 5px;
   }
   .logo {
      width : 120px;
      display: block;
      margin-left: auto;
      margin-right: auto;
   }
   .text-danger {
      text-align: justify;
      margin : 10px 0 10px 0;
   }
   .debug_state {
      float: left;
      text-align: left;
      margin: 20px;
      height: 550px;
      overflow: scroll;
      width: 600px;
   }
   button.login_username_password {
      font-size: 12pt;
      margin-bottom: 10px
   }
   button.join_discussion {
      margin-bottom: 10px
   }
   button.login_google {
      margin-bottom: 10px;
      font-size: 12pt;
      width: 277px;
   }
   .username_password_login textarea {
      line-height: inherit;
      text-align: left;
      resize: none;
      background-color: #bac1cc;
      border-radius: 5px;
      border: 2px solid #fff;
      width: 209px;
   }
   div.spinner_border {
      width: 1rem;
      height: 1rem;
   }
</style>
