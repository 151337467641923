<template>
   <div class="home" @click="fullscreenClick" v-if="username" >
      <fullscreen  @change="fullscreenChange" ref="fullscreen" background="#23334f">
         <Navigation :username='username' :studiocaller='studiocaller' :mic='mic' :isMobile='isMobile' :canvas='canvas' :user_role='user_role' ></Navigation>
            <div id="container" >
               <p v-if="errorText" class="text-danger">
                  {{errorText}}
               </p>
               <div class="component_container calls disable" :style="disable_style">
                  <Contacts @call:incoming="focus_incoming_call" :wss="wss" ref="Contacts" :muted_by="muted_by" :names="get_onpeer" :mic="mic" :ctx="ctx" :peerUpdate="peerUpdate" :studiocaller="app" :search="search" :parentRefs="$refs"  :isMobile="isMobile" :p_date="date" />
               </div>
            </div>
            <p class="update_state" > {{this.peerUpdate}} </p> 

      </fullscreen>
   </div>
</template>

<script>

import Navigation from '../components/Navigation'
import Contacts from '../components/Contacts.vue'
import NoSleep from 'nosleep.js'
import json_errorText from './../assets/JSON/errorText.json';
import fullscreen from 'vue-fullscreen';
import EventBus from '../eventBus';
import Vue from 'vue';

Vue.use(fullscreen);
var noSleep = new NoSleep();

export default {
   name: "home",
   props: {
      wss: Object,
      username: String,
      studiocaller: Object,
      isMobile: Boolean,
      mic: GainNode,
      ctx: AudioContext,
      date: {}
   },
   data: function () {
      return  {
         search: '',
         // get_onpeer : {},
         sec: 0,
         canvas: this.studiocaller,
         app: this.studiocaller,
         peerUpdate: null,
         peers : Object,
         active_call : false,
         errorText: '',
         p_date: Object, 
         count : 0,
         peer_test: '',
         disable_style : {},
         temppeer: {},
         pause_timer : false,
         fulls: null, 
         muted_by: {'id': '' ,'mute_state' : '', 'mute_mic_state':'', 'mute_by':''},
         peers_from_host : [],
         user_role : 'Invitee',
         height : 0, 
         width: 0,
         xPos: null,
         yPos: null,
         gfullscreen: false
      }
   },
   components: {
      Navigation,
      Contacts,
   },
   beforeMount() {
      console.log('studio Caller beforeMounted: '); 
      this.p_date = this.date;
      if (typeof this.wss != "undefined" ) {
         this.wss.onpeer = this._onpeer.bind(this);
         this._onpeer();
         this.wss.onalert = (t) => {
            console.log('wss: ' + t);
            this.print_debug(t) ;
            t  = t.replace(/ *\([^)]*\) */g, " ");
            t  = t.replace("+ act  +","");
            var replaced_key  = '' ; 
            this.errorText = '';
            for (var  key in json_errorText) {
                  var  value = json_errorText[key];
                  replaced_key  = key.replace("+ act  +","");
                  replaced_key = "wsSock: "+ replaced_key ;
                  if(value=="The connection to the server has been lost") {
                  } else if(replaced_key  == t ) {
                     this.errorText = value;
                  }
            }
         }
         this.wss.onstate = (m) => {
            console.log('onstateza: '+m);
            this.print_debug(m) ;
         }
      }
   },
   mounted:function() {
     
      this.checkName();  
      if(this.studiocaller) {
         var _this = this;
        
        this.wss.onappmsg = this._onappmsg_peer.bind(this);

         this.wss.timeout_user = setTimeout(function(){
            if(!_this.pause_timer) {
               _this.remove_activity_listeners();
               _this.studiocaller.logout();
            }
         }, 1800000);
      } else {
         console.log('Studio Caller object not mounted');
      }
      document.addEventListener('keydown', this.user_activity);
      document.addEventListener('mousedown', this.user_activity);
      document.addEventListener('mousemove', this.user_activity);
      if(this.isMobile) {
         // var fs = this.full_screen();
         noSleep.enable();
         document.addEventListener('click', this.fullscreenChange(false));

         window.onload = function() {
            // Wrap our method in IIFE to reduce clutter
            (function() {
               var pageDirectionX;

               document.addEventListener('touchstart', function(e){
                     pageDirectionX = e.changedTouches[0].pageX;
               });

               document.addEventListener('touchmove', function(e){
                     if (pageDirectionX !== e.changedTouches[0].pageX)
                        e.preventDefault();
               });
            }());
            
            /** remainder of onload body */
         };

         // window.addEventListener( "touchmove", function ( event ) {
         //    var touch = event.originalEvent.touches[ 0 ];
         //    oldX = this.xPos;
         //    oldY = this.yPos;
         //    xPos = touch.pageX;
         //    yPos = touch.pageY;
         //    if ( oldX == null && oldY == null ) {
         //       return false;
         //    }
         //    else {
         //       if ( Math.abs( oldX-this.xPos ) > Math.abs( oldY-this.yPos ) ) {
         //             event.preventDefault();
         //             return false;
         //       }
         //    }
         // } );
      }
      this.peer_test = this._onpeer.bind(this);
      this.peer_test = (e) => {
         alert(e);
      }
      EventBus.$on('RESET_MUTE_STATUES', (payload) => {
         this.muted_by = {'id': '' ,'mute_state' : '', 'mute_mic_state':'', 'mute_by':''}
      })

      this.height  = window.screen.height;
      this.width   = window.screen.width;

   },
   methods: {
      print_debug(text) {
         // this.p_date = new Date(); 
         // this.count+=1 ;
         // this.$refs['debug_state'].innerHTML +=  this.p_date +': ' + text +"<br/>" ;
         // this.wss.send({cmd:'log', data: this.p_date +': ' + text}); 
         // if(this.count > 100 ) {
         //    this.$refs['debug_state'].removeChild(this.$refs['debug_state'].childNodes[0]);
         //    this.$refs['debug_state'].removeChild(this.$refs['debug_state'].childNodes[0]);
         // }
      },
      checkName() {
         if(!this.username) {
             this.$router.push('/') ;
         }
         console.log('studiocaller: ' + this.studiocaller);
      },
      focus_incoming_call() {
         // this.$refs.search.scrollIntoView();
      },
      full_screen() {
         if (typeof this.$refs['fullscreen'] != 'undefined') {
            this.$refs['fullscreen'].toggle();
         }
      },
      resetTimout() {
         var _this = this; 

         if (typeof this.wss.timeout_user != "undefined") {
            clearTimeout(this.wss.timeout_user);
         }
         
         this.wss.timeout_user = setTimeout(function(){ 
            if(_this.pause_timer == false) {
               _this.remove_activity_listeners();
               _this.studiocaller.logout();
            } 
         }, 1800000);
      },
      user_activity() {
         this.resetTimout();
      },
      _onappmsg_peer(id, cmd, data) {
         this.session_event_log({
            session: this.session,
            action: cmd.cmd,
            pool: this.studiocaller.pool, 
            uuid: this.studiocaller.uuid, 
            action_data: {'user': id} 
         });

         if (cmd.cmd == 'mute') {

            if (typeof cmd.data.peer_uuid  != "undefined") {
               EventBus.$emit('TOGGLE_OTHER_PEERS_MICROPHONE_STATE', {
                  mute_state : cmd.data.state, //Mute state -> speaker. Because of privacy issue also microphone.
                  mute_mic_state : false,
                  id: cmd.data.peer_uuid      
               });
            } else {
               EventBus.$emit('TOGGLE_GUEST_MICROPHONE_STATE', {
                  mute_mic_state : false, //Mute state ->  speaker. Because of privacy issue also microphone.
                  mute_state : cmd.data.state,         
               });
            }
         } else if(cmd.cmd == 'mute_mic') {
            // Used to handle muting of the microphone.
            // The logged in guests microphone is found on the banner.
            // The other guests microphone is found in the guests section.  
            // NB: cmd.data.state returns a string and not a boolean 
            
            if (typeof cmd.data.peer_uuid  != "undefined") { // Meaning that there has been mute state with one of 
               // the other peers/guest.
               console.log('TOGGLE_OTHER_PEERS_MICROPHONE_STATE: ',cmd);  
               EventBus.$emit('TOGGLE_OTHER_PEERS_MICROPHONE_STATE', {
                  mute_state : false, //speaker. Because of privacy issue also microphone.
                  mute_mic_state : cmd.data.state,
                  id: cmd.data.peer_uuid      
               });
            } else {
               EventBus.$emit('TOGGLE_GUEST_MICROPHONE_STATE', {
                  mute_state : false,
                  mute_mic_state : cmd.data.state,     
               });
            }
         } else if(cmd.cmd == 'active_peers') {
            var active_peers = cmd.data.active_peers;
            Object.keys(this.wss.peers).forEach((id,_) => {
               this.wss.peers[id].active_with_host = false;
            });
            for(var idx in active_peers) {
               var uuid = active_peers[idx].id;
               if (typeof this.wss.peers[uuid] != 'undefined' ) {
                  this.wss.peers[uuid].active_with_host = true;
               }
               if (active_peers[idx].mic_muted != 'undefined') {
                  EventBus.$emit('TOGGLE_OTHER_PEERS_MICROPHONE_STATE', {
                     mute_state : false, //speaker. Because of privacy issue also microphone.
                     mute_mic_state : active_peers[idx].mic_muted,
                     id: active_peers[idx].id      
                  });
               }

               if(active_peers[idx].speaker_muted) {
                  EventBus.$emit('TOGGLE_OTHER_PEERS_MICROPHONE_STATE', {
                     mute_state : active_peers[idx].speaker_muted, //speaker. Because of privacy issue also microphone.
                     mute_mic_state : false,
                     id: active_peers[idx].id     
                  });
               }
            }
         } else if(cmd.cmd == 'unraise_hand') {
            if(cmd.data.state == 'true') {
               EventBus.$emit('UNRAISE_HAND', true);
            }
         } else if(cmd.cmd == 'pause_raise_hand') {
            var payload = '';
            EventBus.$emit('PAUSE_RAISE_HAND', payload)
         } else if (cmd.cmd == 'broadcast_message') {
            var payload = cmd.data;
            EventBus.$emit('BROADCAST_MESSAGE', payload);
         } else if (cmd.cmd == 'set_gain') {
            console.log('gain_value: '+cmd.data.gain_value);
            this.mic.gain.value = cmd.data.gain_value;
         } else if (cmd.cmd == 'recording_status') {
            console.log('cmd: ',cmd);
            var payload = {
               'id' : id,
               'status' : cmd.data.status
            };
            EventBus.$emit('RECORDING_STATUS',payload); 
         } else if (cmd.cmd == 'stopwatch_update') {
            
            var payload = {
               'action' : cmd.data.action,
               'stopwatch_interval' : cmd.data.stopwatch_interval,
               'current_time' : cmd.data.current_time
            };

            EventBus.$emit('STOPWATCH_UPDATE',payload); 
         }

         var current_date_time = new Date; 
         current_date_time = current_date_time.getTime();
         this.peerUpdate = current_date_time.toString();
      },
      remove_activity_listeners() {
         document.removeEventListener('keydown', this.user_activity);
         document.removeEventListener('mousedown', this.user_activity);
         document.removeEventListener('mousemove', this.user_activity);
      },
      check() {
         this.$refs['fullscreen'].toggle();
      },
      // get_onpeer() {
      //    return this.wss.peers;
      // },
      _onpeer() {
         this.active_call = false;
         this.pause_timer = false;

         var elements = document.getElementsByClassName("meterElement");
         for (var i = 0; i < elements.length; i++) {
            elements[i].style.width=("127px");
            elements[i].parentElement.style.width=("127px");
         }
         
         Object.keys(this.wss.peers).forEach((id,_) => {
            var p = this.wss.peers[id];
            this.wss.peers[id].active_with_host = false; 
            if((p.active) || (p.calledbyus) || (p.callingus)) {
               this.pause_timer = true; 
            } 
            if(p.updated) {
               if(this.wss.peers[id].updated) {
                  this.wss.peers[id].updated = false; 
               } else {
                  this.wss.peers[id].updated = true;
               }

               var current_date_time = new Date; 
               current_date_time = current_date_time.getTime();
               this.peerUpdate = current_date_time.toString();

               if(p.deleted) {
                  delete this.wss.peers[id];
               }
               p.updated = false; 
            }
            this.errorText = '' ;
         });

         if (this.pause_timer) {
            clearTimeout(this.wss.timeout_user);
         }

         this.disable_style = {
            "pointer-events": "none",
            /* for "disabled" effect */
            // opacity: "0.5",
            // background: "#CCC",
         }
         var _this = this;
         setTimeout(function(){ 
            _this.disable_style = {}
         }, 400);

         this.temppeer = this.wss.peers;  
         return this.wss.peers
      },
      fullscreenChange (fullscreen) {
         var _this = this;
         // alert(fullscreen);  
         this.gfullscreen = fullscreen;
         if(fullscreen == false) {
            this.$refs['fullscreen'].toggle();
         }
      },
      fullscreenClick() {
         if(this.isMobile && !this.gfullscreen) {
            this.fullscreenChange(false);
         }
      }
   },
  beforeDestroy () {
   // document.removeEventListener("backbutton", this.BackFunction);
  },
  computed : {
      get_onpeer: function() {
         this.temppeer
         return this.wss.peers;
      },
  },
   watch: {
      pause_timer: function (val) {
         if (!this.pause_timer) {
            this.resetTimout();
         }else {
            clearTimeout(this.wss.timeout_user);
         }
      },
   },
};
</script>

<style scoped>
   .component_container.calls {
      padding: 10px;
      background-color: #23334f;
      margin: 40px;
      border-radius: 10px;
      color: #dfe4e8;
   }  

/*--Old--*/
   .home {
      height: 100%;
      flex-direction: column;
      _background-color: #417898;
   }
   .row {
      margin-left: 0px;
      margin-right: 0px;
      height:100%;
   }
   .card-title {
      text-decoration: underline;
   }
   .input-group {
      margin-bottom: 10px;
   }
   .search_image {
      width:30px;
      height:30px;
   }
   #container {
      height: 80%;
   }
   .active_contacts .card {
      margin-bottom: 20px;
   }
   .fill {
      width:90%; /*set to 45 FIXME */ 
      display: inline-block;
   }
   .update_state {
      display: None;
   }
   @media (max-width: 900px) {
      .fill {
         display: contents;
/*         margin: auto ;
         margin-bottom: 10px ;
         display: contents;*/
      }
      .active_contacts{
/*          width: 100%; */
/*         margin: auto ;
         margin-bottom: 10px ;
         display: contents;*/
      }
      .component_container.calls {
          margin: 10px;
      }
   }
   .swipe {
      width: 100px;
      height: 30px;
      margin-top: auto;
      margin-bottom: auto;
      background-color: grey;
      color: white;
      font-family: Arial;
      text-align: center;
      line-height: 30px;
   }
   .test {
      position: absolute; 
      top: 0; 
      left: 0; 
      height: 100%; 
      width: 100%;
      background: #00800030;
   }
   .debug_state{
      float: left;
      text-align: left;
      margin: 20px;
      height: 550px;
      overflow: scroll;
      width: fit-content;
   }

</style>
