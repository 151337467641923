import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import GAuth from 'vue-google-oauth2';
import store from './store/store';
import Axios from 'axios';
import Modal from "@burhanahmeed/vue-modal-2";
import 'dseg/css/dseg.css';

Vue.config.productionTip = false;

const gauthOption = {
  clientId: '504408884957-vc6g07d8nstklp8u17klt5ffdh6sc4i9.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}

Vue.use(GAuth, gauthOption)
Vue.use(Modal)
Vue.mixin({
  data: function() {
    return {
      // get globalReadOnlyProperty() {
      //   return "Can't change me!";
      // },
    }
  }, 
  methods: {
    session_event_log : function (args) {
      var _this = this;

      // FIXME: some of the args not available on mount of a 
      // component. 

      if (!args.uuid) {
        console.log('No uuid provided for logging');
        return;
      }

      if (!args.session) {
        console.log('No session provided for logging');
        return;
      }

      if (!args.action) {
        console.log('No action provided for logging');
        return;
      }

      this.$axios.get('https://'+process.env.VUE_APP_BASEURL+'/backend.php', {
        params: {
          action: 'session_event_log',
          uuid: args.uuid,
          session:args.session, 
          event_action: args.action,
          action_data: args.action_data
        }
      }).then(function (response) {
          console.log('response',response);
      });
    },
  }
})

Vue.prototype.$axios = Axios;
//Vue.prototype.$UUID = null;
//Vue.prototype.$POOL = null;
//Vue.prototype.$SESSION = null;

Vue.prototype.$appName = null;

new Vue({
   router,
   store,
   render: h => h(App)
}).$mount("#app");

