<template> 
   <!-- <div class="_row"> -->
      <table style="width: 100%;"  v-bind:class="[peer.raise_hand == 'true' ? 'active_raise_hand' : '' ]" >
      <tr>
         <td class="call_icon" >
            <div>
               <div class="incoming_accept incoming_call" v-if="callstate.state =='ring_in'">Incoming call</div>
               <div class="outgoing_end outgoing_call" v-if="callstate.state =='hangup'">Outgoing call</div>
               <div v-if="peer.invited" class="button_region">
                  <!-- <div v-if="!isMobile" class="fitcontents"> -->
                     <div class=" telephone waiting_non_active_contact" :title="callstate.title ? callstate.title : ''" @click="check"  v-bind:class="[callstate.state, isMobile ? 'mobile' : 'non_mobile' , callstate.state == 'hangup' ? 'center_icon': '']" v-if=" callstate.state 
                     != 'ring_in' ">
                        <img :src="callstate.image"   v-bind:class="[callstate.state == 'hangup' ? 'img-fluid d-block incoming_decline_shake phone_icon':'img-fluid d-block  phone_icon']" >
                     </div>
                  <!-- </div> -->
                  <div v-else-if=" callstate.state !== 'ring_in'">
                     <div class="px-0 telephone qaller_button fitcontents" :title="callstate.title ? callstate.title : ''" v-on:dblclick="check"  @click="double_tap_message"  v-bind:class="callstate.state" v-if=" callstate.state == 'hangup' "  >
                        <p class="tap_message" v-if="double_tap_message_outgoing" >Double tap to end call</p>
                        <span><img :src="callstate.image" class="img-fluid d-block mx-auto phone_icon "></span>
                     </div>
                     <div class="px-0 telephone  qaller_button" :title="callstate.title ? callstate.title : ''" @click="check"  v-bind:class="[callstate.state, isMobile ? 'mobile' : 'non_mobile' ]" v-if=" callstate.state == 'call' ">
                        <span><img :src="callstate.image" class="img-fluid d-block mx-auto phone_icon "></span>
                     </div>
                     <div class="px-0 telephone qaller_button fitcontents" v-on:dblclick="check" :title="callstate.title ? callstate.title : ''" @click="double_tap_message" v-bind:class="callstate.state" v-if=" callstate.state == 'active_call' "  >
                        <p class="tap_message" v-if="double_tap_message_outgoing" >Double tap to end call</p>
                        <span><img :src="callstate.image" class="img-fluid d-block mx-auto phone_icon "></span>
                     </div>
                  </div>
                  <div style="width: 124px;margin: auto;">
                     <div class="qaller_button call" @click="accept_call"  v-bind:class="callstate.state"  v-if="callstate.state =='ring_in'" title="Click to accept incoming call">
                        <img src="@/assets/images/call.svg" class="img-fluid d-block mx-auto phone_icon incoming_accept_shake">
                        <div></div>
                     </div>
                     <div class="qaller_button" @click="decline_call"  v-bind:class="callstate.state"  v-if="callstate.state == 'ring_in'" title="Click to decline incoming call">
                        <img src="@/assets/images/hangup.svg" class="img-fluid d-block mx-auto phone_icon">
                           {{emit_incoming()}}
                     </div>
                  </div>
               </div>
            </div>
         </td>   
         
         <td class="contact_name_region"  v-bind:class="[callstate.state  == 'active_call' && ((is_muted_by.state == 'false' || typeof is_muted_by.state == 'undefined') && !mute ) ? 'active':  '', mute_colours()]">
            <div class="contact_details" >
               <span  class="name lead">{{peer.name}}</span>
            </div>
         </td>
         
         <td class="contact_detail_region" v-bind:class="[callstate.state  == 'active_call' && ((is_muted_by.state == 'false' || typeof is_muted_by.state == 'undefined') && !mute ) ? 'active':  '', mute_colours()]">
            <div class="email_number" >
               Email : {{peer.email}}<br>
               Phone Number: {{peer.cell_number}} 
            </div>
         </td>

         <td class="messages" v-if="peer.presenter != 1">
            <!-- <span v-if="callstate.state  == 'active_call'"> -->
               <table class="broadcast_message"  v-if="callstate.state  == 'active_call'">
                  <tr>
                     <td class="broadcast_message_field">
                        <input v-click-outside="clear_drop_down" title="Enter a message then click the send icon to send a message to a guest." class="broadcast_message" :class="[invitee_message ? 'invitee_message' :'', !show_bmessage ? 'field_transparent' : '']" ref="broadcast_message" v-on:keyup.enter="send_message" name="broadcast_message" @click="fetch_broadcast_message" @change="fetch_broadcast_message" style="width: 100%;height: 44px;" @keyup="select_tr" />
                        <div v-click-outside="clear_drop_down"  @mouseleave="message_sent_mouseOut" @mouseover="message_sent_mouseOver"  class="message_container" v-if="message_history.length">
                              <li class="messages_history list" style="list-style-type: none;">
                                 <ul style="padding: 0px;" v-for="(message,id) in message_history" :key=id @click="populate_message_field(message)" :class="{focus: id === focus}">
                                    {{message['message']}}
                                 </ul>
                              </li>
                        </div>
                     
                     </td>
                     <td class="broadcast_message_send log_action" data-action="broadcast_message_send" title="Click to send a direct message to this guest">
                        <img @click="send_message" src="@/assets/images/resend.svg"/>
                     </td>
                     <td class="broadcast_message_cancel log_action" data-action="broadcast_message_cancel" title="Click to remove direct message sent to guest">
                        <img @click="clear_message" src="@/assets/images/cancel.svg"/> 
                     </td>
                  </tr>
                  <tr>
                     <!-- <td class="broadcast_message_display" v-if="bmessage">
                        <div>
                           Last Message: {{bmessage}}
                        </div>
                     </td> -->
                  </tr>
               </table>
         </td>
         
         <td  style="width: 52px;">
            <div class="raise_hand" @click="unraise_hand" style="width: 51px;" v-bind:class="{'circular' :peer.raise_hand}">
               <img src="@/assets/images/raise_arm.svg" title="Click to unraise hand" class="img-fluid d-block mx-auto phone_icon move" v-if="typeof peer.raise_hand != 'undefined' && pause_raise_hand !=true && peer.raise_hand =='true'" v-bind:class="{'raise_hand_shake' :peer.raise_hand}">
            </div>
         </td>

         <td class="vuMeter_slider_container" style="height: 94px;" v-if="peer.presenter != 1">
            <div style="width: 154px;">
               <div class="vuMeter_slider" >
                  <div style="height: 40px; width: 120px;">
                     <div class="invitee_mic" v-bind:class="[callstate.state  == 'active_call' ? 'active_border':'']" ref="invitee_mic" style="height: 40px; width: 150px;">
                     </div>
                  </div>

                  <div  v-bind:class="[callstate.state  != 'active_call' ? 'hide_invitee_mic' : '']">
                     <div class="slideContainer">
                        <div class="range">
                           <input type="range"  title="Slide the bar to change the guest's gain" v-on:dblclick="sliderSetToDefault" min="-10" max="10" v-model="sliderGain"  id="myRange" style="width: 130px;" class="slider"> 
                           <div class="sliderticks">
                              <p>-10</p>
                              <p>-5</p>
                              <p>0</p>
                              <p>5</p>
                              <p>10</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div v-bind:class="[callstate.state  != 'active_call' ? 'hide_invitee_mic' : '']" class="db_scale">
                  dB
               </div>
            </div>
         </td>

         <td class="button_region" v-if="peer.presenter != 1">
            <table style="float: right;">
               <tr>
                  <td>
                     <canvas class="osciCanvas" v-bind:class="[callstate.state  == 'active_call' ? 'active_border':'']" ref="osci" width="170" height="60"></canvas>
                  </td>
                  <td v-if="mute != true">
                     <div class=" mic" title="Click to mute/unmute guest" ref="invitee_mic_mute" v-bind:class="[!peer.active? 'disable_mute' : 'active_mute' ]"  @click="mute_mic_toggle">
                        
                        <div class="mute_mic" title="Click to mute microphone of active call">
                           <img src="@/assets/images/mute_mic.svg" class="img-fluid d-block mx-auto phone_icon " v-if="mute_mic == false && (!isMobile && !mute)">
                        </div>

                        <div class="unmute_mic" title="Click to unmute microphone of active call">  
                           <img src="@/assets/images/unmute_mic.svg" class="img-fluid d-block mx-auto phone_icon " v-if="mute_mic == true && (!isMobile && !mute)">
                        </div>

                     </div>
                  </td>
                  <td>
                     <div v-bind:class="[!peer.active? 'disable_mute' : 'active_mute' ]" @click="mute_speaker_toggle"  v-if="!isMobile" ref="mute_speaker_toggle" >
                           <div class="mute " v-if="mute == false" title="Click to mute microphone and speaker of active call">
                              <img src="@/assets/images/speakers.svg"   class="img-fluid d-block mx-auto phone_icon " v-if="mute == false">
                           </div>
                           <div class="unmute" v-bind:class="[mute? 'unmute_min_width' : '' ]"  title="Click to unmute microphone and speaker of active call">
                              <img src="@/assets/images/mute_both_mic_speaker.svg"   class="img-fluid d-block mute_both_mic_speaker " v-if="mute == true">
                           </div>
                     </div>
                  </td>

               </tr>
            </table>
         </td>
         <!-- {{peer.parent_host}} -->
         <!-- <button v-on:click="Check1">
            Test
         </button>  -->
      </tr>
      </table>
   <!-- </div> -->
</template> 

<script>

   import EventBus from '../../eventBus';
   import ClickOutside from 'vue-click-outside';
   // import webAudioPeakMeter from 'web-audio-peak-meter';
   import webAudioPeakMeter from '../../../public/js/web-audio-peak-meter/src/index.js';
   import Oscilloscope from 'oscilloscope';
   
   // Used to record spereate channels;
   // import { Mp3MediaRecorder } from 'mp3-mediarecorder';
   // import Mp3RecorderWorker from "workerize-loader!./../../workers/worker.js";

   // let blobs = [];
   // let combinationOfBlobs = []; 
   // let mediaStream = null;
   
   export default {
      name: 'Contact',
      props: {
         peer: Object,
         studiocaller: Object,
         id: String,
         username: String,
         callstate: Object,
         isMobile: Boolean,
         is_muted_by: Object,
         sent_invites: Object,
         raise_hand_by: Object,
         ctx : AudioContext,
         section: String 
      },
      data () {
         return {
            mute : false,
            mute_mic : false,
            double_tap_message_outgoing: false,
            pause_raise_hand : false, 
            spkvis: null,
            bmessage: '',
            message_history: [],
            invitee_message: true,
            focus: null,
            mouseOver: true,
            show_bmessage: true,
            input_field_transparent: true,
            message_on_enter: '',
            sliderGain: 0,
            vuMeter_slider_container: {},
            invitee_mic: {}
         }
      },
      mounted: function () {
         var _this = this;
         if (typeof is_muted_by == 'undefined' ) {
            this.muted = false;
            this.mute_mic =false;
         }

         // if(!this.spkvis) {
            // this.spkvis = new vuVis(this.ctx, this.$refs['invitee_mic']);
         // }

         var invitee_mic_dom = this.$refs['invitee_mic']; 

         EventBus.$on('SPKVIS', (payload) => {
            if(payload.id == this.id) {
               //Dont connect to speaker
               // this.studiocaller.wss.peers[this.id].rtp.connect(this.ctx.destination);
               var meterNode = webAudioPeakMeter.createMeterNode(this.studiocaller.wss.peers[this.id].rtp, this.ctx);
               webAudioPeakMeter.createMeter(invitee_mic_dom, meterNode, {});
               var osci = this.$refs['osci'];
               var scope = new Oscilloscope(this.studiocaller.wss.peers[this.id].rtp);
               if(osci) {
                  scope.animate(osci.getContext("2d"));
               }
            }
         }); 

         EventBus.$on('MUTE_MIC', (payload) => {
            if (typeof payload.id != "undefined") {
               // A Guest has requested to be muted.
               if(payload.id == this.id && (this.peer.active == true)) {
                  this.mute_mic_toggle();
               }
               return;
            } 
            if ((this.peer.active == true) && (this.mute_mic !=payload.mic_mute_state)) {
               this.mute_mic_toggle('programmatically');
            }
         });

         EventBus.$on('MUTE_SPEAKER', (payload) => {
            if ((this.peer.active == true) && (this.mute !=payload.speaker_mute_state)) {
               this.mute_speaker_toggle('programmatically'); 
            }
         });

         // document.getElementsByClassName("log_action").forEach(function(btn,index,arr) {
         //    btn.addEventListener("click", function() {
         //       var action = btn.getAttribute("data-action");
         //       _this.session_event_log({
         //          session: _this.studiocaller.session,
         //          action: action,
         //          pool: _this.studiocaller.pool, 
         //          uuid: _this.studiocaller.uuid 
         //       }); 
         //    });
         // });
      },
      methods : {
         Check1: function () {
            // console.log('section: '+this.section);
            // this.vuMeter_slider_container = {
            //    transform
            // }

            this.invitee_mic = {
            
            };
         }, 

         check: function () {
            if(this.peer.deleted) {
                delete this.studiocaller.wss.peers[this.id] ;
            } else {
               if(this.peer.callingus) {
               } else if(this.peer.calledbyus) {
                  this.studiocaller.wss.action('hangup', this.id);
                  this.$emit('call:hangup',"true");
               } else if(this.peer.active) {
                  this.studiocaller.wss.action('hangup', this.id);
                  this.studiocaller.wss.action('app', this.id , {cmd:'mute', data:{state:false,mute_by: this.studiocaller.name}});
               } else if(!this.peer.active && !this.peer.calledbyus && !this.peer.callingus) {
                  this.studiocaller.wss.action('call', this.id);
                  this.is_muted_by.state = "false";
               }
            }
         },
         accept_call: function () {
            this.studiocaller.wss.action('accept', this.id);
         },
         decline_call: function () {
            this.studiocaller.wss.action('decline', this.id);
         },
         emit_incoming : function () {
            this.$emit('call:incoming',"true");
         },
         mute_speaker_toggle : function (event_type="user_clicked") {
             
            if(this.mute) {
               this.studiocaller.wss.action('app', this.id , {uuid:this.id, cmd:'mute', data:{state:false,mute_by: this.studiocaller.name}});
               this.studiocaller.wss.action('app', this.id , {uuid:this.id, cmd:'mute_mic', data:{state:false,mute_by: this.studiocaller.name}});
               this.studiocaller.wss.peers[this.id].rtp.setgain_i(1.0);
               this.studiocaller.wss.peers[this.id].micGain.gain.value =1.0;
               this.mute = false;
               this.studiocaller.wss.peers[this.id].speaker_muted = false;
               this.studiocaller.wss.peers[this.id].mic_muted = false;
            } else {
               this.studiocaller.wss.action('app', this.id , {uuid:this.id, cmd:'mute', data:{state:true,mute_by: this.studiocaller.name}  });
               this.studiocaller.wss.peers[this.id].rtp.setgain_i(0.0); //Disconnecting peers output from our input.
               this.studiocaller.wss.peers[this.id].micGain.gain.value= 0.0; //Disconnecting peers input from your output.
               this.mute = true ;
               //this.mute_mic = true;
               this.studiocaller.wss.peers[this.id].speaker_muted = true;
               this.studiocaller.wss.peers[this.id].mic_muted = false;
            }

            this.mute_mic = false;
            var payload = {
               unclick_mic_speaker : true, 
            };

            if(event_type != "programmatically" ) {
               EventBus.$emit('INVITEE_MUTE_MIC_SPEAKER_CLICKED',payload);
            }

            Object.keys(this.studiocaller.wss.peers).forEach((pid) => {
               if(this.studiocaller.wss.peers[pid].active &&  pid != this.id) { //Don't send to this current contact.  
                  this.studiocaller.wss.action('app', pid , 
                  {
                     cmd:'mute', 
                     data: {
                        peer_uuid: this.id,
                        state: this.studiocaller.wss.peers[this.id].speaker_muted
                     }
                  });
               }
            });

         },
         mute_mic_toggle : function (event_type="user_clicked") {

            if(this.mute_mic) {
               this.studiocaller.wss.action('app', this.id , {cmd:'mute_mic', data:{uuid:this.id,state:false,mute_by: this.studiocaller.name}});
               this.studiocaller.wss.peers[this.id].micGain.gain.value= 1.0;
               this.mute_mic = false;
               this.studiocaller.wss.peers[this.id].mic_muted = false;
               this.studiocaller.wss.peers[this.id].speaker_muted = false; 
            } else {
               this.studiocaller.wss.action('app', this.id , {cmd:'mute_mic', data:{uuid:this.id,state:true,mute_by: this.studiocaller.name}});
               this.studiocaller.wss.peers[this.id].micGain.gain.value= 0.0; //Disconnecting peers input from your output.
               this.mute_mic = true ;
               this.studiocaller.wss.peers;
               this.studiocaller.wss.peers[this.id].mic_muted = true;
               this.studiocaller.wss.peers[this.id].speaker_muted = false;
            }

            this.studiocaller.wss.peers[this.id].rtp.setgain_i(1.0);
            var payload = {
               unclick_mic_speaker : true, 
            };

            if(event_type !="programmatically") {
               EventBus.$emit('INVITEE_MUTE_MIC_SPEAKER_CLICKED',payload);
            }  
            this.mute = false ;

            this.studiocaller.wss.action('app', '00000000-0000-0000-0000-000000000000', 
            {
               cmd:'mute_mic', 
               data: {
                  peer_uuid:this.id,
                  state:this.studiocaller.wss.peers[this.id].mic_muted
               }
            });
            
         },
         mute_print : function () {
            return this.mute
         },
         mute_colours : function () {
            if(this.is_muted_by.state == 'true' && this.mute) {
               return 'muted_both'
            } else if(this.is_muted_by.state == 'true') {
               return 'muted_by'
            } else if(this.mute) {
                  return 'muted_by_us'  
            } else {
               return '';
            }
         },
         double_tap_message : function () {
            this.double_tap_message_outgoing=true;
            var _this = this;
            setTimeout(function() { 
               _this.double_tap_message_outgoing = false; 
            }, 3000 );            
         },
         unraise_hand : function () {
            if(this.peer.raise_hand == 'true') {
               this.pause_raise_hand = false;
               this.peer.unraise_hand = false;
               this.studiocaller.wss.action('app', this.id , {cmd:'unraise_hand', data:{state:"true",by: this.studiocaller.name}});
            }  

            this.session_event_log({
               'uuid': this.studiocaller.uuid, 
               'session': this.studiocaller.session,
               'pool': this.studiocaller.pool, 
               'action': 'unraise_hand',
               'action_data': {user: this.id}
            });
            
         },
         broadcast_message : function () {
            this.bmessage = this.$refs['broadcast_message'].value;
            this.$refs['broadcast_message'].value ='';
            this.studiocaller.wss.action('app', this.id , {cmd:'broadcast_message', data:{message:this.bmessage, by: this.studiocaller.uuid}});
         },
         send_message: function () {
               var _this = this;
               this.invitee_message = true;
               this.bmessage = this.$refs['broadcast_message'].value;
               if (this.message_on_enter) {
                  this.bmessage = this.message_on_enter;
               }

               this.studiocaller.wss.action('app', this.id , {cmd:'broadcast_message', data:{message:this.bmessage, by: this.studiocaller.uuid}});
               if (this.bmessage) {
                  this.$axios.get('https://'+process.env.VUE_APP_BASEURL+'.expertron.co.za/backend.php?action=message_insert&uuid='+this.studiocaller.uuid+'&new_message='+this.bmessage)
                  .then(function (response) {
                     console.log('response1: ',response);
                     if(typeof response.result != 'undefined' && typeof response.result.history != 'undefined') {
                        _this.message_history = response.result.history;
                     } else {
                        _this.message_history = [];
                     }
                     console.log('response2: ',response);
                  });
               }
               this.message_history = [];
               this.message_on_enter = '';

               var _this = this;
               var timeout; 
               var count =0;

               timeout = setInterval(function() {
                  count++; 
                  if(_this.show_bmessage) {
                     _this.show_bmessage = false;
                  } else {
                     _this.show_bmessage = true;
                  }
                  if(count > 3) {
                     _this.show_bmessage = true;
                     clearTimeout(timeout); 
                  }
               }, 500);

            // }
         },
         clear_message: function () {
            this.bmessage = ''
            this.$refs['broadcast_message'].value ='';
            this.studiocaller.wss.action('app', this.id , {cmd:'broadcast_message', data:{message:this.bmessage, by: this.studiocaller.uuid}});
         },
         fetch_broadcast_message: function (event) {
            
            var _this = this;
            var text  = _this.$refs['broadcast_message'].value;
            if (typeof event != 'undefined' && event.type == 'click') {
               text ='';
            } 

            // if(text) {
               this.$axios.get('https://'+process.env.VUE_APP_BASEURL+'/backend.php?action=message_fetch&uuid='+this.studiocaller.uuid+'&search_message='+text)
               .then(function (response) {
                  _this.message_history = response.data.result;
                  console.log('response-> ',response.data.result);
               });
            this.invitee_message = false;
         },
         populate_message_field: function (message) {
            console.log('message: '+message);
            this.$refs['broadcast_message'].value = message['message'];
            var msg = message['message']; 

            this.message_on_enter = msg;
            this.send_message();
         },
         clear_drop_down() {
            if (this.$refs['broadcast_message'].value == this.bmessage) {
               this.invitee_message = true;
            } else if (!this.$refs['broadcast_message'].value) {
               this.invitee_message = true;
               this.$refs['broadcast_message'].value = this.bmessage;
            } else {
               this.invitee_message = false;
            }
            this.message_history = [];
         },

         select_tr: function(event) {
            // console.log('mouseOver: '+this.mouseOver);
            // this.fetch_broadcast_message();
            // if (this.mouseOver == false ) {
            //    console.log('message_history: ',this.message_history);
            //    switch (event.keyCode) {
            //    case 38:
                  
            //       if (this.focus > 0) {
            //          this.focus--;
            //       }
            //       break;
            //    case 40:
                  
            //       if (this.focus < this.message_history.length - 1) {
            //          this.focus++;
            //       }
            //       break;
            //    case 13:
                  
            //       console.log('message history: ',this.message_history);

            //       if (this.message_history.length) {
            //          console.log('this.message_history: ', this.message_history);
            //          console.log('this.message_history this.focus: ', this.focus);
            //          console.log('uuid: ',this.message_history[this.focus].message);
            //          this.populate_message_field(this.message_history[this.focus]);
            //       } else if (this.$refs['broadcast_message'].value) {
            //          var message = {
            //             message :  this.$refs['broadcast_message'].value
            //          }
            //          this.populate_message_field(message);
            //       }  
                  
            //       this.focus = 0;
            //       this.message_history = [];
            //       break;
            //    }
            //    console.log('focus: ',this.focus);
            // }
         },
         message_sent_mouseOver : function () {
            this.mouseOver = true;
            this.focus = null; 
         },
         message_sent_mouseOut : function () {
            this.mouseOver = false;
         },
         micGainSlider : function () {
            // console.log('sliderGain: '+this.sliderGain); 
         },
         sliderSetToDefault : function () {
            var micGainValue   = 1;
            console.log('Slider Default'+micGainValue); 
            // this.studiocaller.wss.peers[this.id].rtp.setgain_o(micGainValue);
            this.studiocaller.wss.action('app', this.id , {cmd:'set_gain', data:{gain_value:micGainValue,}});
         },
      },
      watch: {
         sliderGain: function(val) {
            var micGainValue   = Math.pow(10, (val/20));
            // console.log('micGainValue: '+micGainValue);
            // console.log('isMobile: '+this.isMobile);

            if (typeof this.studiocaller.wss.peers[this.id].isMobile !="undefined" && this.studiocaller.wss.peers[this.id].isMobile ) {
               console.log('Mobile');
               this.studiocaller.wss.peers[this.id].rtp.setgain_o(micGainValue);
            } else {
               console.log('not Mobile');
               this.studiocaller.wss.action('app', this.id , {cmd:'set_gain', data:{gain_value:micGainValue,}});
            }
            //this.studiocaller.wss.peers[this.id].rtp.setgain_o(micGainValue);
            // this.studiocaller.wss.action('app', this.id , {cmd:'set_gain', data:{gain_value:micGainValue,}});
         }  
      },
      directives: {
         ClickOutside
      },
   }
</script>

<style scoped>
   .incoming_call,
   .outgoing_call {
      color: white;
      border-radius: .25rem;
      text-align: center;
      width: 120px;
      margin-bottom: 6px;
   }
   td.call_icon div.button_region div.center_icon {
      margin: auto;
      width: 54px;
      display: block;
   }
   .osciCanvas {
      padding: 3px;
      display: block;
      margin-right: 5px;
   }
   
   .slideContainer {
      /* height: 0; */
      padding-top: 4px;
   }
   .vuMeter_slider_container {
      width: 165px;
   }
   .vuMeter_slider_container .vuMeter_slider {
      width: 136px;
   }
   .mute_mic, .mute {
      width: 54px;
   }
   .mute_both_mic_speaker {
      height: 54px;
      float: right;
   }
   .invitee_message {
      background: #2c5aa0ff;
      color: white;
   }
   .field_transparent {
      color: transparent;
      background: transparent;
      /* border: none; */
   }
   .message_history {
      float: right;
      text-align: left;
      position: absolute;
      z-index: 100;
      right: 0px;
      margin-right: 44px;
      background: rgb(219 226 239);
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      top: 45px;
   }
   .list.message_history {
      list-style-type: none;
   }
   .messages_history ul:hover {
      background-color:#28a74545;
      cursor:pointer;
   }
   .broadcast_message_display  div {
      width: 207px;
   }
   .call_icon > .button_region {
      display: inline-block;
   }
   .qaller_button {
      margin-left: 5px;
   }
   .button_region img {
      /* width : 38px; */
      cursor : pointer;
   }
   td.button_region {
      width: 287px;
   }
   table.broadcast_message {
      width:100%;
      float : right;
   }
   input.broadcast_message {
      width: 100%;
      margin: 5px 0;
      min-width: 200px
   }
   .broadcast_message td {
      /* float:right; */
   }
   .broadcast_message td img {
      width: 54px;
   }
   .messages div {
      position: absolute;
      /* float: right; */
      text-align: left;
      /* position: absolute; */
      z-index: 100;
      /* right: 0px; */
      margin-right: 19px;
      background: rgb(219 226 239);
      width: 77%;
      padding: 10px;
      border-radius: 5px;
      /* top: 54px; */
   }
   .messages {
      position: relative;
   }
   .green_hover:hover,.focus {
      background-color:#28a74545;
   }
/*--old--*/ 
   .incoming_accept {
      /* margin: 8px 0px; */
      /* float:left; */
      -webkit-animation: accpet_glow 1500ms infinite;
      -moz-animation: accpet_glow 1500ms infinite;
      -o-animation: accpet_glow 1500ms infinite;
      animation: accpet_glow 1500ms infinite;
/*       width:50%; */
   }
   .incoming_accept_shake {
       animation: incoming_accept_shake 0.5s infinite ;
   }
   .outgoing_end{
      /* float:left; */
      /* margin: 8px; */
      -webkit-animation: outgoing_end 1500ms infinite;
      -moz-animation: outgoing_end 1500ms infinite;
      -o-animation: outgoing_end 1500ms infinite;
      animation: outgoing_end 1500ms infinite;
   }

   .incoming_decline_shake {
       animation: incoming_decline_shake 0.5s infinite ;
   }
   .active_raise_hand {
      -webkit-animation: raise_hand_glow 550ms infinite;
      -moz-animation: raise_hand_glow 550ms infinite;
      -o-animation: raise_hand_glow 550ms infinite;
      animation: raise_hand_glow 550ms infinite;
   }
   .raise_hand {
      float:right;
      -webkit-animation: raise_hand_glow 550ms infinite;
      -moz-animation: raise_hand_glow 550ms infinite;
      -o-animation: raise_hand_glow 550ms infinite;
      animation: raise_hand_glow 550ms infinite;
   }

   .raise_hand_shake {
      animation: raise_hand_shake 0.45s infinite ;
   }

   .active_mute{
    margin: auto ; 
   }
   .disable {
      background: lightgrey;  
    }
   .muted_by {
      background: #aa0a0a36;
   }
   .muted_by_us {
      /* background: #ff840038; */
   }
   .muted_both {
      background: #ff840038;
   }
   .disable_mute {
      display: none;
   }
   .incoming_call,
   .outgoing_end
   {
      width: 100%;
   }
   .mute, .speaker {
      float:left
   }
   .unmute {
      float:right;
      /* min-width: 70px; */
   }
   .unmute_min_width{
      min-width: 116px;
      float: right;
   }
   .speaker {
      margin-left: 10px;
   }
   .call:hover  {
      /* background-color:#28a74545; */
   }
   .call .mobile {
      width: 38%;
   }
   .call .non_mobile {
      width: 38%;
   }
   .ring_in{
      /* width:12.5%; */
      display: inline-block;
      vertical-align: middle;
   }
   .contact_name_region {
      width: 13%; 
   }
   td.call_icon {
      min-width: 100px;
      text-align: left;
   }
   .hangup:hover {
      /* background-color:#fad7d7; */
   }
   .active_call:hover {
      /* background-color:#fad7d7; */
   }
   
   .active {
      /* background-color:#f0fff4; */
   }
   .phone_icon {
      width: 54px;
   }
   .contact_details {
      padding-left: 9px;
      text-align:center;
/*       width: 40px; */
      /* border-left: rgb(228, 230, 231); */
      /* border-right: rgb(228, 230, 231); */
      /* border-style: solid; */
      /* border-width: 0px 2px 0px 2px; */
   }

   /* .message {
      width: 25%
   } */
   
   .message_icon{
      width: 25px;
   }
   
   .message:hover {
      background-color: #ffbc002e; 
   }
   
   .row {
      padding: 0px;
      margin:0px;
   }
   
   img {
      /* padding: 3px; */
   }
   .fitcontents {
      /* display:contents; */
   }
   .telephone {
      /* margin-left: 10px ; */

      display: inline-block;
      vertical-align: middle;
   }
   /* .circular_div {
      border-radius: 50%;
      width: 38px;
   } */
   .circular {
      border-radius: 50%;
   }
   .circular_div.mute_mic {
      margin-right: 10px;
   }
   .call_icon {
      width: 10%;
   }
   .contact_detail_region {
      width: 15%;
   }
   td {
      vertical-align: middle;
      padding: 4px;
   }
   .email_number {
      text-align: left;
   }
   .mic {
      float:left;
   }
   .raise_hand {
      display: inline-block;
   } 
   .active_border {
      border: 1px solid black;
   }
   .hide_invitee_mic {
      display: none;
   }
   .broadcast_message_field {
      width: auto
   }
   .broadcast_message_send,.broadcast_message_cancel {
      width: 54px
   }


   /* Slider */
   .slider {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      height: 1px;
      background: #D3D3D3;
      outline: none;
      opacity: 0.7;
      -webkit-transition: .2s;
      transition: opacity .2s;
   }

   .slider:hover {
      opacity: 1;
   }

   .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 5px;
      height: 20px;
      background: #FF0000;
      cursor: pointer;
   }

   .slider::-moz-range-thumb {
      width: 25px;
      height: 25px;
      background: #FF0000;
      cursor: pointer;
   }

   .sliderticks {
      display: flex;
      justify-content: space-between;
      padding: 0 4px;
   }

   .sliderticks p {
      position: relative;
      display: flex;
      justify-content: center;
      text-align: center;
      width: 1px;
      background: #D3D3D3;
      height: 6px;
      line-height: 40px;
      margin: 0 0 20px 0;
      font-size: 10px;
   }
   .db_scale, .slideContainer {
      float: left;
      margin-left: 4px;
   }
   .slideContainer {
      margin-left: 0px;
   }
   div.broadcast_message_send img {
      width: 54px;
      height: 54px
   }
   /* .invitee_mic_level > div {
      float: left;
   } */
   @media (max-width: 767px) {
      .col-sm-6 {
/*       -ms-flex: 0 0 50%; */
      }
      .telephone {
/*          border-top:  1px solid #dfdfdf; */
         /* margin: auto ; */
      }
      .contact_details {
         /* border-bottom: 1px solid #dfdfdf; */
      }
/*      .message {
         margin-bottom: 30px; 
         border-bottom: 2px solid #dedbdb; 
         border-bottom: 1px solid blue; 
      }*/
      .tap_message{
         margin:0px;
         padding:0px;
      }
   }
   
   @-webkit-keyframes accpet_glow {
      0% { background-color: #85db5947; -webkit-box-shadow: 0 0 3px #85db5947;   }
      50% { background-color: #85db5970; -webkit-box-shadow: 0 0 40px #85db5970; }
      100% { background-color: #008000bf; -webkit-box-shadow: 0 0 3px #008000bf; }
   }

   @-moz-keyframes accpet_glow {
      0% { background-color: #85db5947; -webkit-box-shadow: 0 0 3px #85db5947;   }
      50% { background-color: #85db5970; -webkit-box-shadow: 0 0 40px #85db5970; }
      100% { background-color: #008000bf; -webkit-box-shadow: 0 0 3px #008000bf; }
   }

   @-o-keyframes accpet_glow {
      0% { background-color: #85db5947; -webkit-box-shadow: 0 0 3px #85db5947;   }
      50% { background-color: #85db5970; -webkit-box-shadow: 0 0 40px #85db5970; }
      100% { background-color: #008000bf; -webkit-box-shadow: 0 0 3px #008000bf; }
   }

   @keyframes accpet_glow{
      0% { background-color: #85db5947; -webkit-box-shadow: 0 0 3px #85db5947;   }
      50% { background-color: #85db5970; -webkit-box-shadow: 0 0 40px #85db5970; }
      100% { background-color: #008000bf; -webkit-box-shadow: 0 0 3px #008000bf; }
   }
   
   @-webkit-keyframes outgoing_end {
      0% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 3px #ca7c7cc7;   }
      50% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 40px #ca5f5fc7; }
      100% { background-color: #c83737c7; -webkit-box-shadow: 0 0 3px #c83737c7; }
   }

   @-moz-keyframes outgoing_end {
      0% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 3px #ca7c7cc7;   }
      50% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 40px #ca5f5fc7; }
      100% { background-color: #c83737c7; -webkit-box-shadow: 0 0 3px #c83737c7; }
   }

   @-o-keyframes outgoing_end {
      0% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 3px #ca7c7cc7;   }
      50% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 40px #ca5f5fc7; }
      100% { background-color: #c83737c7; -webkit-box-shadow: 0 0 3px #c83737c7; }
   }

   @keyframes outgoing_end{
      0% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 3px #ca7c7cc7;   }
      50% { background-color: #ca5f5fc7; -webkit-box-shadow: 0 0 40px #ca5f5fc7; }
      100% { background-color: #c83737c7; -webkit-box-shadow: 0 0 3px #c83737c7; }
   }

   @-webkit-keyframes raise_hand_glow {
      0% { background-color: #7bae7bc7; -webkit-box-shadow: 0 0 20px #7bae7bc7;}
      50% { background-color: #a9eda9c7; -webkit-box-shadow: 0 0 20px #a9eda9c7}
      100% { background-color: #7bae7bc7; -webkit-box-shadow: 0 0 20px #7bae7bc7;}
   }

   @-moz-keyframes raise_hand_glow {
      0% { background-color: #7bae7bc7; -webkit-box-shadow: 0 0 20px #7bae7bc7;}
      50% { background-color: #a9eda9c7; -webkit-box-shadow: 0 0 20px #a9eda9c7;}
      100% { background-color: #7bae7bc7; -webkit-box-shadow: 0 0 20px #7bae7bc7;}
   }

   @-o-keyframes raise_hand_glow {
      0% { background-color: #7bae7bc7; -webkit-box-shadow: 0 0 20px #7bae7bc7;}
      50% { background-color: #a9eda9c7; -webkit-box-shadow: 0 0 20px #a9eda9c7;}
      100% { background-color: #7bae7bc7; -webkit-box-shadow: 0 0 20px #7bae7bc7;}
   }

   @keyframes raise_hand_glow {
      0% { background-color: #7bae7bc7; -webkit-box-shadow: 0 0 20px #7bae7bc7;  }
      50% { background-color: #a9eda9c7; -webkit-box-shadow: 0 0 20px #a9eda9c7; }
      100% { background-color: #7bae7bc7; -webkit-box-shadow: 0 0 20px #7bae7bc7; }
   }
   
   @-moz-keyframes incoming_accept_shake { 
      0% { -webkit-transform: rotate(0deg)}
      25% { -webkit-transform: rotate(25deg)}
   }
   @-webkit-keyframes incoming_accept_shake { 
      0% { -webkit-transform: rotate(0deg)}
      25% { -webkit-transform: rotate(25deg)}
   }
   @keyframes incoming_accept_shake { 
      0% { -webkit-transform: rotate(0deg)}
      25% { -webkit-transform: rotate(25deg)}
   }
   
   @-moz-keyframes incoming_decline_shake { 
         0% { -webkit-transform: rotate(0deg)}
         25% { -webkit-transform: rotate(-25deg)}
   }
   @-webkit-keyframes incoming_decline_shake { 
         0% { -webkit-transform: rotate(0deg)}
         25% { -webkit-transform: rotate(-25deg)}
   }
   @keyframes incoming_decline_shake { 
      0% { -webkit-transform: rotate(0deg)}
      25% { -webkit-transform: rotate(-25deg)}
   }
   
   @keyframes raise_hand_shake { 
      0% { -webkit-transform: rotate(0deg)}
      25% { -webkit-transform: rotate(25deg)}
   }
   
</style>
